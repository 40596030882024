import React, { useEffect, useState } from 'react';
import { FaRegCalendarAlt, FaTools } from 'react-icons/fa';
import Rating from './Rating';
import axiosInstance from '../utils/axiosInstance';

const MiniVisitDetail = ({ visitId, isLegacy }) => {
  const [visitDetails, setVisitDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const apiURL = process.env.REACT_APP_API_BASE_URL || '';
  const baseURL = process.env.REACT_APP_MAIN_URL || '';

  useEffect(() => {
    const fetchVisitDetails = async () => {
      if (!visitId) {
        setLoading(false);
        return;
      }

      setLoading(true);
      try {
        const endpoint = isLegacy === '1' ? `/api/legacy-visits/${visitId}` : `/api/visits/${visitId}`;
        const response = await axiosInstance.get(endpoint);
        if (response.status !== 200) throw new Error('Failed to fetch visit details');
        setVisitDetails(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchVisitDetails();
  }, [visitId, isLegacy]);

  const visitDate = isLegacy === '1' ? visitDetails?.visit_date : visitDetails?.visitDetails?.visit_date;
  const description = isLegacy === '1' ? visitDetails?.description : visitDetails?.visitDetails?.description;
  const afterPicture = isLegacy === '1' ? visitDetails?.after_pictures : visitDetails?.visitDetails?.after_picture_url;
  const activities = isLegacy === '1' ? visitDetails?.activities : visitDetails?.selectedActivities?.map((activity) => activity.name);
  const workerName = visitDetails?.worker_name;

  return (
    <div className="flex items-start w-96 absolute z-10 bg-white border border-gray-300 rounded p-4 shadow-lg">
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      {!loading && !error && !visitDetails && <p>No visit details found.</p>}
      {!loading && !error && visitDetails && (
        <>
          <div className="w-1/3 pr-4">
            <img
              src={(isLegacy === '1' ? baseURL : apiURL) + (afterPicture || "/images/default_after.jpg")}
              alt="After the visit"
              className="object-cover rounded-lg shadow-lg"
              style={{ maxHeight: "150px" }}
            />
          </div>
          <div className="w-2/3">
            <h3 className="text-lg font-medium text-gray-900 mb-2">Besøksdetaljer</h3>
            <div className="text-sm text-gray-900 mb-2">
              <FaRegCalendarAlt className="inline mr-2 text-blue-500" />
              {new Date(visitDate).toLocaleDateString("nb-NO")}
            </div>
            <div className="text-sm text-gray-900 mb-2">
              <FaTools className="inline mr-2 text-green-600" />
              {activities?.join(', ') || 'Ingen aktiviteter registrert'}
            </div>
            <div className="text-sm text-gray-900 mb-2">
              <strong>Beskrivelse:</strong> {description || 'Ingen beskrivelse tilgjengelig'}
            </div>
            {isLegacy === '1' && workerName && (
              <div className="text-sm text-gray-900 mb-2">
                <strong>Utført av:</strong> {workerName || 'Ingen arbeider tilgjengelig'}
              </div>
            )}
            <div className="flex justify-start text-sm text-gray-900">
              <strong className='pr-2'>Vurdering:</strong>
              <Rating
                visit_type={isLegacy === '1' ? 'visits' : 'visits_new'}
                visit_id={visitDetails.visit_id}
                gravestone_id={visitDetails.gravestone_id}
                customer_id={visitDetails.customer_id}
                readOnly={true}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MiniVisitDetail;