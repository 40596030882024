import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNotifications } from '../../context/NotificationContext';
import { AiOutlineCamera } from 'react-icons/ai';
import BackButton from '../../components/BackButton';
import { useLogs } from '../../hooks/useLogs';  // Import useLogs hook
import { useUser } from '../../context/UserContext';

const requestGeolocation = () => {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        reject(new Error('Geolocation is not supported by your browser'));
        return;
      }
  
      navigator.geolocation.getCurrentPosition(resolve, reject, {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      });
    });
};

const VisitEditPage = () => {
  const { visitId } = useParams();
  const [visitDetails, setVisitDetails] = useState({
    visitDetails: {
      before_pictures: null,
      after_pictures: null,
    },
  });
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [location, setLocation] = useState(null);
  const [beforeImagePreview, setBeforeImagePreview] = useState(null);
  const [afterImagePreview, setAfterImagePreview] = useState(null);
  const [beforeImageId, setBeforeImageId] = useState(null);
  const [afterImageId, setAfterImageId] = useState(null);
  
  const { addNotification } = useNotifications();
  const { createLog } = useLogs();  // Destructure createLog from useLogs
  const { user } = useUser();

  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  useEffect(() => {
    fetch(`${apiURL}/api/visits/${visitId}`)
      .then(response => response.json())
      .then(data => {
        setVisitDetails(data);
        setComment(data.visitDetails.description);
        setSelectedActivities(data.selectedActivities.map(a => a.id));
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching visit details:', error);
        setError('Failed to load visit details');
        setLoading(false);
      });
  }, [visitId]);

  const toggleActivitySelection = (activityId) => {
    setSelectedActivities(current =>
      current.includes(activityId)
        ? current.filter(id => id !== activityId)
        : [...current, activityId]
    );
  };

  const handleCapture = async (event, type) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
        const position = await requestGeolocation();
        setLocation(position.coords);

        const formData = new FormData();
        formData.append('image', file);
        formData.append('latitude', position.coords.latitude);
        formData.append('longitude', position.coords.longitude);
        formData.append('gravestoneId', visitDetails.visitDetails.gravestone_id);
        formData.append('type', type);
        formData.append('visitId', visitId);

        const response = await fetch(`${apiURL}/api/pictures/upload`, {
            method: 'POST',
            body: formData,
        });

        const result = await response.json();
        if (!response.ok) throw new Error(result.message || "Failed to upload image");

        if (type === 'before') {
            setBeforeImagePreview(URL.createObjectURL(file));
            setBeforeImageId(result.id);
            createLog({ type: 'action', message: `Før-bilde lastet opp for besøk ${visitId}`, userId: user.id });
        } else if (type === 'after') {
            setAfterImagePreview(URL.createObjectURL(file));
            setAfterImageId(result.id);
            createLog({ type: 'action', message: `Etter-bilde lastet opp for besøk ${visitId}`, userId: user.id });
        }

    } catch (error) {
        console.error('Error capturing the image or fetching location:', error);
    }
};

const handleSave = async () => {
    const requestBody = {
        visitId,
        selectedActivities,
        comment,
        beforeImageId,
        afterImageId
    };

    try {
        const response = await fetch(`${apiURL}/api/visit/update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const responseData = await response.json();
        addNotification({ message: 'Besøk oppdatert', type: 'success' });

        if (afterImageId) {
            createLog({ type: 'action', message: `Besøk ${visitId} er ferdigstilt`, userId: user.id });
        }

        console.log('Visit updated successfully:', responseData);
    } catch (error) {
        addNotification({ message: 'Kunne ikke oppdatere besøket.', type: 'error' });
        createLog({ type: 'error', message: `Kunne ikke oppdatere besøk ${visitId}. Error: ${error}`, userId: user.id });
        console.error('Error saving visit details:', error);
    }
};

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!visitDetails) return <div>No visit details found</div>;

  return (
    <div className="container mx-auto p-4 mt-8">
      <BackButton />
      <h1 className="text-2xl font-bold mb-4">Besøk</h1>
      
      <div className="mb-4">
        <h2 className="text-lg font-semibold">Aktiviteter:</h2>
        <div className="flex flex-wrap gap-2">
          {visitDetails.allActivities.map(activity => (
            <button
              key={activity.id}
              className={`px-4 py-2 rounded-full text-sm ${selectedActivities.includes(activity.id) ? 'bg-green-500 text-white' : 'bg-gray-200 text-gray-800'} ${visitDetails.plannedActivities.find(a => a.id === activity.id) ? 'ring-2 ring-green-300' : ''}`}
              onClick={() => toggleActivitySelection(activity.id)}
            >
              {activity.name}
            </button>
          ))}
        </div>
      </div>

      <div className="mb-4">
        <label htmlFor="comment" className="block mb-2 text-sm font-medium text-gray-900">Kommentar:</label>
        <textarea
          id="comment"
          rows="4"
          className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
          placeholder="Legg til kommentar.."
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        ></textarea>
      </div>

      <div className="grid grid-cols-2 gap-4 mb-4">
        {['before', 'after'].map((type) => (
          <div key={type} className="border p-4 flex justify-center items-center relative">
            <label htmlFor={`capture${type}`} className="cursor-pointer w-full h-full flex justify-center items-center">
              {(
                (type === 'before' && (beforeImagePreview || visitDetails.visitDetails.before_picture_url)) ||
                (type === 'after' && (afterImagePreview || visitDetails.visitDetails.after_picture_url))
              ) ? (
                <img 
                  src={type === 'before' ? beforeImagePreview || apiURL + visitDetails.visitDetails.before_picture_url : afterImagePreview || apiURL + visitDetails.visitDetails.after_picture_url} 
                  alt={type} 
                  className="max-w-full max-h-40 object-cover w-full h-full"
                />
              ) : (
                <AiOutlineCamera size={48} />
              )}
              <input
                type="file"
                id={`capture${type}`}
                accept="image/*"
                capture="environment"
                onChange={(e) => handleCapture(e, type)}
                className="hidden"
              />
            </label>
          </div>
        ))}
      </div>

      <button
        className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700"
        onClick={handleSave}
      >
        Lagre
      </button>
    </div>
  );
};

export default VisitEditPage;