import React, { useEffect } from 'react';
import { useNotifications } from '../context/NotificationContext';
import { AiOutlineCheckCircle, AiOutlineInfoCircle, AiOutlineCloseCircle } from 'react-icons/ai';
import axios from 'axios';

const Notification = () => {
  const { notifications, removeNotification } = useNotifications();
  const [settings, setSettings] = React.useState({ duration: 5000, position: 'bottom-right' });
  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  const fetchSettings = async () => {
    try {
      const response = await axios.get(`${apiURL}/api/site_settings/notification_settings`);
      const data = response.data.setting_value;
      setSettings(data);
    } catch (error) {
      console.error('Error fetching notification settings:', error);
    }
  };

  useEffect(() => {
    fetchSettings();
    const interval = setInterval(fetchSettings, 60000); // Fetch settings every 60 seconds
    return () => clearInterval(interval); // Clear interval on component unmount
  }, []);

  useEffect(() => {
    notifications.forEach((notif) => {
      setTimeout(() => removeNotification(notif.id), settings.duration);
    });
  }, [notifications, removeNotification, settings.duration]);

  const getNotificationIcon = (type) => {
    switch (type) {
      case 'success':
        return <AiOutlineCheckCircle className="text-green-400 mr-2" size={24} />;
      case 'error':
        return <AiOutlineCloseCircle className="text-red-400 mr-2" size={24} />;
      case 'info':
      default:
        return <AiOutlineInfoCircle className="text-blue-400 mr-2" size={24} />;
    }
  };

  const getNotificationPosition = () => {
    switch (settings.position) {
      case 'top-left':
        return 'fixed top-0 left-0 m-8';
      case 'top-center':
        return 'fixed top-0 left-1/2 transform -translate-x-1/2 m-8';
      case 'top-right':
        return 'fixed top-0 right-0 m-8';
      case 'center-left':
        return 'fixed top-1/2 left-0 transform -translate-y-1/2 m-8';
      case 'center':
        return 'fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 m-8';
      case 'center-right':
        return 'fixed top-1/2 right-0 transform -translate-y-1/2 m-8';
      case 'bottom-left':
        return 'fixed bottom-0 left-0 m-8';
      case 'bottom-center':
        return 'fixed bottom-0 left-1/2 transform -translate-x-1/2 m-8';
      case 'bottom-right':
      default:
        return 'fixed bottom-0 right-0 m-8';
    }
  };

  return (
    <div className={`${getNotificationPosition()} space-y-2 z-50`}>
      {notifications.map((notif) => (
        <div
          key={notif.id}
          className={`flex items-center p-4 border-l-4 shadow-md rounded ${notif.type === 'success' ? 'border-green-400 bg-green-50' : notif.type === 'error' ? 'border-red-400 bg-red-50' : 'border-blue-400 bg-blue-50'}`}
        >
          {getNotificationIcon(notif.type)}
          <span className="text-sm font-medium">{notif.message}</span>
        </div>
      ))}
    </div>
  );
};

export default Notification;