/**
 * Removes ' (worker)' or ' (admin)' from the end of a string if present.
 * 
 * @param {string} str - The input string to check and modify.
 * @returns {string} - The modified string without ' (worker)' or ' (admin)', or the original string if neither is present.
 */
export function removeRoleSuffix(str) {
    if (str.endsWith(' (worker)')) {
        return str.slice(0, -9); // Remove ' (worker)' which is 9 characters long
    } else if (str.endsWith(' (admin)')) {
        return str.slice(0, -8); // Remove ' (admin)' which is 8 characters long
    }
    return str; // Return the original string if no suffix is found
}