import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { IoCalendarNumberOutline } from 'react-icons/io5';
import axios from 'axios';
import { useActivities } from '../../hooks';
import LoadingScreen from '../../components/LoadingScreen';

const ViewActivity = () => {
    const { id } = useParams(); // Get the activityId from useParams
    const { fetchActivityById, activity } = useActivities();

    const apiURL = process.env.REACT_APP_API_BASE_URL || '';

    useEffect(() => {
        fetchActivityById(id);
    }, [id]); // Add activityId as a dependency to useEffect

    if (!activity) {
        return <LoadingScreen />;
    }

    return (
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-semibold text-center text-gray-800 my-6">Vis Aktivitet</h1>
            <div className="max-w-lg mx-auto bg-white p-6 shadow-lg rounded-lg">
                <h2 className="text-2xl font-semibold mb-4">{activity.name}</h2>
                <div className="mb-4">
                    <span className="block font-semibold mb-1 text-gray-700">Kode:</span>
                    <p className="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm p-2">{activity.code}</p>
                </div>
                <div className="mb-4">
                    <p className="flex items-center text-gray-600">
                        <IoCalendarNumberOutline className="text-gray-700 mr-2"/>
                        Opprettet: <span className="text-gray-800">{new Date(activity.created_at).toLocaleString('nb-NO')}</span>
                    </p>
                </div>
                <div className="mb-4">
                    <p className="flex items-center text-gray-600">
                        <IoCalendarNumberOutline className="text-gray-700 mr-2"/>
                        Sist Oppdatert: <span className="text-gray-800">{new Date(activity.updated_at).toLocaleString('nb-NO')}</span>
                    </p>
                </div>
                {/* Add more activity details here */}
                <Link to={`/aktiviteter/${id}/endre`} className="bg-green-grave hover:bg-orange-grave text-white font-bold py-2 px-4 rounded">Endre Aktivitet</Link>
            </div>
        </div>
    );
};

export default ViewActivity;
