// No need for the extra <a> tag inside the Link component
import React from 'react';
import { Link } from 'react-router-dom';

const StatBox = ({ href, bgColor, icon, count, label }) => (
  <Link to={href} className={`flex items-center justify-between shadow rounded-lg overflow-hidden ${bgColor} m-2 lg:m-4 p-4 hover:shadow-lg transition-shadow duration-300`}>
    <div className="flex items-center">
      {icon}
      <span className="ml-3 text-white font-medium">{label}</span>
    </div>
    <span className="text-white text-2xl font-bold mr-5">{count}</span>
  </Link>
);

export default StatBox;