import React, { useState, useEffect } from 'react';
import '../css/Slideshow.css';

const Slideshow = () => {
  const [images, setImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [validImages, setValidImages] = useState([]);
  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch(`${apiURL}/api/visits/after-pictures`);
        const data = await response.json();
        setImages(data.map(item => `https://cms.gravstellerne.no${item.picture}`));
      } catch (error) {
        console.error("Failed to load after pictures:", error);
      }
    };

    fetchImages();
  }, [apiURL]);

  useEffect(() => {
    if (validImages.length > 0) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % validImages.length);
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [validImages.length]);

  const handleImageError = (index) => {
    setValidImages((prev) => prev.filter((_, i) => i !== index));
  };

  const handleImageLoad = (image) => {
    setValidImages((prev) => [...prev, image]);
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % validImages.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + validImages.length) % validImages.length);
  };

  return (
    <div className="relative w-screen h-screen overflow-hidden">
      {images.map((image, index) => (
        <div
          key={index}
          className={`absolute inset-0 transition-transform duration-500 ease-in-out ${
            validImages[currentIndex] === image ? 'translate-x-0' : 'translate-x-full'
          }`}
        >
          <div
            className="absolute inset-0 bg-cover bg-center blur"
            style={{ backgroundImage: `url(${image})` }}
          />
          <div className="absolute inset-0 flex justify-center items-center">
            <img
              src={image}
              alt={`slide-${index}`}
              className="object-contain max-w-full max-h-full"
              onError={() => handleImageError(index)}
              onLoad={() => handleImageLoad(image)}
            />
          </div>
        </div>
      ))}
      <button
        className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-white bg-opacity-50 p-2 rounded"
        onClick={prevSlide}
      >
        &#10094;
      </button>
      <button
        className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-white bg-opacity-50 p-2 rounded"
        onClick={nextSlide}
      >
        &#10095;
      </button>
    </div>
  );
};

export default Slideshow;