import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { AiOutlineLike, AiOutlineDislike } from 'react-icons/ai';
import Rating from './Rating';

const VisitCommentsTable = ({ comments, onCommentClick }) => {
  const [expandedRows, setExpandedRows] = useState({});
  console.log(comments);

  const toggleRowExpansion = (visitId) => {
    setExpandedRows((prev) => ({
      ...prev,
      [visitId]: !prev[visitId],
    }));
  };

  // Group comments by visit_id and visit_type
  const groupedComments = comments.reduce((acc, comment) => {
    const key = `${comment.visit_id}-${comment.visit_type}`;
    if (!acc[key]) acc[key] = [];
    acc[key].push(comment);
    return acc;
  }, {});

  // Sort each group by created_at to ensure the original comment is first
  Object.keys(groupedComments).forEach((key) => {
    groupedComments[key].sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
  });

  return (
    <table className="fixed-table shadow rounded-lg">
      <thead className="bg-gray-200 text-gray-600">
        <tr>
          <th className="px-2 py-2 text-left" style={{ width: '10%' }}>Dato</th>
          <th className="px-2 py-2 text-left" style={{ width: '40%' }}>Kommentar</th>
          <th className="px-2 py-2 text-left" style={{ width: '10%' }}>Kunde</th>
          <th className="px-2 py-2 text-left" style={{ width: '15%' }}>Gravpersoner</th>
          <th className="px-2 py-2 text-left" style={{ width: '5%' }}>Besøk</th>
          <th className="px-2 py-2 text-left" style={{ width: '10%' }}>Vurdering</th>
          <th className="px-2 py-2 text-left" style={{ width: '5%' }}>Besøkt</th>
          <th className="px-2 py-2 text-left" style={{ width: '5%' }}>Sett</th>
          <th className="px-2 py-2 text-left" style={{ width: '5%' }}>Svar</th>
        </tr>
      </thead>
      <tbody className="bg-white">
        {Object.entries(groupedComments).map(([key, group]) => {
          const originalComment = group[0]; // The first comment in the sorted group is the original
          const replies = group.slice(1); // All subsequent comments are considered replies
          const isExpanded = expandedRows[originalComment.visit_id];

          return (
            <React.Fragment key={key}>
              <tr className="border-b hover:bg-gray-50" onClick={() => onCommentClick(originalComment)}> {/* Handle row click */}
                <td className="px-2 py-2">{new Date(originalComment.created_at).toLocaleString('nb-NO')}</td>
                <td className="px-2 py-2">
                  {originalComment.comment}
                  {replies.length > 0 && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering the modal on expand/collapse click
                        toggleRowExpansion(originalComment.visit_id);
                      }}
                      className="ml-2 text-green-grave hover:text-orange-grave"
                    >
                      {isExpanded ? <FaChevronUp /> : <FaChevronDown />}
                    </button>
                  )}
                </td>
                <td className="px-2 py-2">
                  <Link to={`/kunder/${originalComment.customer_id}`} className="text-green-grave hover:text-orange-grave underline">
                    {originalComment.customer_name}
                  </Link>
                </td>
                <td className="px-2 py-2">
                  {/* Display the gravepersons connected to the gravestone */}
                  {originalComment.gravepersons?.length > 0 ? (
                    <div className="space-y-2">
                      {originalComment.gravepersons.map((person) => (
                        <div key={person.id} className="text-sm">
                          <Link to={`/gravsteiner/${originalComment.gravestone_id}`} className="font-semibold text-green-grave hover:text-orange-grave underline">{person.name}</Link>
                          <div className="text-gray-600 text-xs">
                            {person.birthdate ? new Date(person.birthdate).toLocaleDateString('nb-NO') : '??'} - 
                            {person.deathdate ? new Date(person.deathdate).toLocaleDateString('nb-NO') : '??'}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <span className="text-gray-500">Ingen gravpersoner</span>
                  )}
                </td>
                <td className="px-2 py-2">
                  {originalComment.visit_type === 'visits' ? (
                    <Link to={`/besok/${originalComment.visit_id}/detaljer/1`} className="text-green-grave underline hover:text-orange-grave">
                      {originalComment.visit_id}
                    </Link>
                  ) : (
                    <Link to={`/besok/${originalComment.visit_id}/detaljer/0`} className="text-green-grave underline hover:text-orange-grave">
                      {originalComment.visit_id}
                    </Link>
                  )}
                </td>
                <td className="px-2 py-2">
                  <Rating
                    visit_type={originalComment.visit_type}
                    visit_id={originalComment.visit_id}
                    gravestone_id={originalComment.gravestone_id}
                    customer_id={originalComment.customer_id}
                    readOnly={true}
                  />
                </td>
                <td className="px-2 py-2">{new Date(originalComment.visit_date).toLocaleString('nb-NO')}</td>
                <td className="px-2 py-2">
                  {originalComment.seen_by_user ? <AiOutlineLike className="text-green-grave" /> : <AiOutlineDislike className="text-red-500" />}
                </td>
                <td className="px-2 py-2">
                  {replies.length > 0 && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering the modal on expand/collapse click
                        toggleRowExpansion(originalComment.visit_id);
                      }}
                      className="text-green-grave hover:text-orange-grave underline"
                    >
                      {isExpanded ? 'Skjul' : 'Vis svar'}
                    </button>
                  )}
                </td>
              </tr>
              {isExpanded &&
                replies.map((reply) => (
                  <tr key={reply.id} className="border-b hover:bg-gray-50 bg-gray-100"> {/* Different background for expanded rows */}
                    {/* Use only the necessary columns */}
                    <td className="px-2 py-2">{new Date(reply.created_at).toLocaleString('nb-NO')}</td>
                    <td className="px-2 py-2" colSpan="4">{reply.comment}</td>
                    <td className="px-2 py-2" colSpan="2">
                      {reply.user_name ? (
                        <Link to={`/brukere/${reply.user_id}`} className="text-green-grave hover:text-orange-grave underline">
                          {reply.user_name}
                        </Link>
                      ) : (
                        <Link to={`/kunder/${reply.customer_id}`} className="text-green-grave hover:text-orange-grave underline">
                          {reply.customer_name}
                        </Link>
                      )}
                    </td>
                    <td className="px-2 py-2">
                      {reply.user_name ? (
                        reply.seen_by_customer ? <AiOutlineLike className="text-green-grave" /> : <AiOutlineDislike className="text-red-500" />
                      ) : (
                        reply.seen_by_user ? <AiOutlineLike className="text-green-grave" /> : <AiOutlineDislike className="text-red-500" />
                      )}
                    </td>
                    <td className="px-2 py-2"></td>
                  </tr>
                ))}
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
};

export default VisitCommentsTable;