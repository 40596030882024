import React, { useState, useEffect } from 'react';

const ProductSelect = ({ value, onChange }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  useEffect(() => {
    setLoading(true);
    fetch(`${apiURL}/api/productsWorkorder`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setProducts(data);
        setLoading(false);
      })
      .catch(err => {
        setError(err.message);
        setLoading(false);
      });
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <select
        value={value}
        onChange={onChange}
        className="p-2 border border-gray-300 rounded"
      >
        <option value="">Velg produkt...</option>
        {products.map(product => (
          <option key={product.id} value={product.id}>
            {product.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default ProductSelect;