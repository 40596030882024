import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useUser } from '../context/UserContext';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const MenuItem = ({ icon, label, isOpen, to, userType, hasNotification }) => {
  const Icon = icon;
  const { user } = useUser();

  const labelVariants = {
    opened: {
      opacity: 1,
      x: 0,
      transition: {
        type: 'spring',
        stiffness: 260,
        damping: 20,
      },
    },
    closed: {
      opacity: 0,
      x: -20,
      transition: {
        type: 'spring',
        stiffness: 260,
        damping: 20,
      },
    },
  };

  const shouldRender = !userType || user?.type === userType;

  return shouldRender ? (
    <li className={`relative flex items-center px-4 py-2 text-white hover:text-black cursor-pointer hover:bg-green-grave transition-colors duration-200 ${isOpen ? 'justify-start' : 'justify-center'} w-full`}>
      <Link to={to} className={`flex w-full h-full items-center ${!isOpen && 'justify-center'}`} data-tooltip-id={label}>
        <Icon className={`text-xl flex-shrink-0 ${!isOpen ? 'mx-auto' : ''}`} />
        <motion.span
          variants={labelVariants}
          initial="closed"
          animate={isOpen ? 'opened' : 'closed'}
          className="ml-4"
          style={{ display: isOpen ? 'block' : 'none' }}
        >
          {label}
        </motion.span>
        {hasNotification && (
          <span className="absolute top-1 right-1 bg-red-600 h-2 w-2 rounded-full"></span>
        )}
      </Link>
      {!isOpen && (
        <ReactTooltip
          id={label}
          place="right"
          effect="solid"
          offset={{ left: 10 }}
          className="sidebar-tooltip"
        >
          {label}
        </ReactTooltip>
      )}
    </li>
  ) : null;
};

export default MenuItem;