import React, { useState, useEffect } from 'react';
import TopBar from '../components/customer-components/TopBar';
import { useCustomer } from '../context/CustomerContext';
import axios from 'axios';

const CustomerLayout = ({ children }) => {
  const { customer } = useCustomer();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  useEffect(() => {
    if (customer && customer.firstLogin) {
      setModalIsOpen(true);
    }
  }, [customer]);

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      await axios.post(`${apiURL}/api/customer/reset-password`, {
        customerId: customer.id,
        newPassword,
      });
      setModalIsOpen(false);
      setError('');
      // Optionally, add a notification for successful password change
    } catch (err) {
      setError('Failed to change password');
    }
  };

  return (
    <div className="flex min-h-screen bg-gradient-to-br from-green-100 to-gray-200">
      <div className="flex-grow">
        <TopBar />
        <main className="flex flex-col items-center justify-center p-4">
          {children}
        </main>
      </div>

      {modalIsOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md mx-auto">
            <h2 className="text-2xl font-bold mb-4">Bytt ditt passord</h2>
            <form onSubmit={handlePasswordChange} className="space-y-4">
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="newPassword">
                  Nytt Passord
                </label>
                <input
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="w-full px-4 py-2 border rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="confirmPassword">
                  Bekreft Passord
                </label>
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="w-full px-4 py-2 border rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
              {error && <p className="text-red-500">{error}</p>}
              <button
                type="submit"
                className="w-full bg-green-grave text-white px-4 py-2 rounded hover:bg-green-600 transition"
              >
                Bytt Passord
              </button>
              <button
                type="button"
                onClick={() => setModalIsOpen(false)}
                className="w-full bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition mt-2"
              >
                Avbryt
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerLayout;