import { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../utils/axiosInstance'; // Assuming axiosInstance is configured

export const useProducts = () => {
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch all products
  const fetchProducts = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get('/api/productsWorkorder');
      setProducts(response.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Fetch a single product by ID
  const fetchProductById = useCallback(async (id) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/api/products/${id}`);
      setProduct(response.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Add a new product
  const addProduct = async (newProduct, navigate, addNotification) => {
    try {
      const response = await axiosInstance.post('/api/products', newProduct);
      setProducts((prevProducts) => [...prevProducts, response.data]);
      addNotification({ message: 'Produkt lagt til suksessfullt', type: 'success' });
      navigate('/produkter');
    } catch (err) {
      setError(err.message);
      addNotification({ message: err.message || 'An error occurred', type: 'error' });
    }
  };

  // Update an existing product by ID
  const updateProduct = async (id, updatedProduct, setIsEditing, addNotification) => {
    try {
      const response = await axiosInstance.put(`/api/products/${id}`, updatedProduct);
      setProduct(response.data);
      setIsEditing(false);
      addNotification({ message: 'Produkt oppdatert suksessfullt', type: 'success' });
    } catch (err) {
      setError(err.message);
      addNotification({ message: err.message || 'En feil oppstod', type: 'error' });
    }
  };

  // Format products for react-select
  const getSelectOptions = useCallback(() => {
    return products.map((product) => ({
      value: product.id.toString(),
      label: product.name,
    }));
  }, [products]);

  return {
    products,
    product,
    setProduct,  // Expose setProduct here
    isLoading,
    error,
    fetchProducts,
    fetchProductById,
    addProduct,
    updateProduct,
    getSelectOptions,  // Expose the formatted options
  };
};