import React from 'react';
import { useAdminView } from '../context/AdminViewContext';
import AdminViewCustomerLayout from './AdminViewCustomerLayout';
import CustomerLayout from './CustomerLayout';

const LayoutWrapper = ({ element }) => {
    const { isAdminView } = useAdminView();
  
    return isAdminView ? (
      <AdminViewCustomerLayout>{element}</AdminViewCustomerLayout>
    ) : (
      <CustomerLayout>{element}</CustomerLayout>
    );
  };

export default LayoutWrapper;