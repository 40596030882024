import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSidebar } from "../context/SidebarContext";
import { useUser } from "../context/UserContext";
import requestGeolocation from "./Geolocation";
import MenuItem from "./MenuItem"; // Adjust the path as necessary
import MobileMenu from "./MobileMenu";
import NewFeaturesPopup from "./popups/NewFeaturesPopup";
import { FiMenu } from "react-icons/fi";
import {
  FaUserCircle,
  FaUserFriends,
  FaMapMarkedAlt,
  FaMonument,
  FaUsersCog,
  FaClipboardList,
  FaRegCalendarCheck,
  FaShoppingCart,
  FaTasks,
  FaSms,
  FaEnvelopeOpenText,
  FaPaintBrush,
  FaCog,
  FaUser,
  FaBuilding,
  FaThumbsDown,
} from "react-icons/fa";
import { FaComments } from "react-icons/fa6";
import axiosInstance from "../utils/axiosInstance";

const Sidebar = () => {
  const { isOpen, toggleSidebar } = useSidebar();
  const { user } = useUser();
  const [hasUnseenComments, setHasUnseenComments] = useState(false); // State to track unseen comments
  const logoPath = isOpen
    ? `${process.env.PUBLIC_URL}/images/logo.svg`
    : `${process.env.PUBLIC_URL}/logo192.png`;

  // Define all menu items
  const menuItems = [
    { icon: FaUserFriends, label: "Kunder", to: "/kunder", userType: "admin"},
    { icon: FaMapMarkedAlt, label: "Kirkegårder", to: "/kirkegarder" },
    { icon: FaMonument, label: "Gravsteiner", to: "/gravsteiner" },
    { icon: FaUsersCog, label: "Gravpersoner", to: "/gravpersoner" },
    { icon: FaClipboardList, label: "Arbeidslister", to: "/arbeidslister", userType: "admin"},
    { icon: FaRegCalendarCheck, label: "Besøk", to: "/besok", userType: "admin"},
    { icon: FaThumbsDown, label: "Besøk", to: "/besok/tommel-ned", userType: "admin"},
    { icon: FaComments, label: "Tilbakemeldinger", to: "/tilbakemeldinger", userType: "admin", hasNotification: hasUnseenComments },
    { icon: FaShoppingCart, label: "Produkter", to: "/produkter", userType: "admin"},
    { icon: FaTasks, label: "Aktiviteter", to: "/aktiviteter", userType: "admin"},
    { icon: FaBuilding, label: "Avdelinger", to: "/avdelinger", userType: "admin"},
    { icon: FaSms, label: "SMS Logger", to: "/sms-logger", userType: "admin"},
    { icon: FaEnvelopeOpenText, label: "E-post Logger", to: "/epost-logger", userType: "admin"},
    { icon: FaPaintBrush, label: "Maler", to: "/maler", userType: "admin"},
    { icon: FaUser, label: "Brukere", to: "/brukere", userType: "admin"},
    { icon: FaCog, label: "Innstillinger", to: "/innstillinger", userType: "admin"},
  ];

  useEffect(() => {
    if (user) {
      requestGeolocation();

      // Fetch unseen comments status
      const fetchUnseenComments = async () => {
        try {
          const response = await axiosInstance.get('/api/visit_comments/unseen_by_user');
          setHasUnseenComments(response.data.unseen);
        } catch (error) {
          console.error('Error fetching unseen comments status:', error);
        }
      };

      fetchUnseenComments();
    }
  }, [user]);

  const profilePath = "/brukere/" + user?.id;

  return (
    <>
      <div
        className={`hidden sm:block fixed left-0 top-0 bottom-0 z-30 transition-width duration-300 ease-in-out bg-gray-800 ${
          isOpen ? "w-52" : "w-20"
        }`}
      >
        <div className="flex flex-col justify-between h-full overflow-y-auto">
          <div>
            <div className="flex items-center justify-end p-4">
              <button
                onClick={toggleSidebar}
                className="absolute text-white top-3 text-2xl rounded-full hover:bg-green-grave focus:outline-none focus:ring-2 focus:ring-orange-grave focus:ring-opacity-50"
              >
                <FiMenu />
              </button>
            </div>
            <div className="flex items-center justify-center p-4">
              <Link to="/">
                <img
                  src={logoPath}
                  alt="Logo"
                  className={`transition-transform duration-300 ease-in-out ${
                    isOpen ? "scale-100" : "scale-75"
                  }`}
                />
              </Link>
            </div>
            <ul className="flex-grow">
              {menuItems.map((item, index) => (
                <MenuItem
                  key={index}
                  icon={item.icon}
                  label={item.label}
                  to={item.to}
                  userType={item.userType}
                  isOpen={isOpen}
                  hasNotification={item.hasNotification} // Pass the notification state to MenuItem
                />
              ))}
            </ul>
          </div>
          <div className="mt-auto p-2 text-white bg-gray-800">
  {user && (
    <div className={`p-2 ${isOpen ? 'bg-gray-700' : 'bg-gray-800'} rounded-lg shadow ${!isOpen && 'p-1'}`}>
      <div className="flex items-center">
        {/* Profile Icon */}
        <Link to={profilePath} className="text-white hover:text-green-grave transition duration-150 ease-in-out">
          <FaUserCircle className={`${isOpen ? "text-3xl" : "text-xl"}`} />
        </Link>

        {/* Profile Details */}
        {isOpen && (
          <Link to={profilePath} className="ml-2 flex-1 text-white hover:text-green-grave transition duration-150 ease-in-out">
            <div className="text-md font-medium truncate overflow-hidden whitespace-nowrap max-w-[150px]">
              {user?.name}
            </div>
            <div className="text-xs">{user?.type === 'worker' ? 'Arbeider' : 'Admin'}</div>
          </Link>
        )}
      </div>
    </div>
  )}
</div>
        </div>
      </div>

      {/* Mobile Bottom Navigation Bar: Visible on sm and smaller screens */}
      <div className="sm:hidden">
        <MobileMenu />
      </div>
    </>
  );
};

export default Sidebar;