import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import { FaMap } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import Pagination from '../Pagination'; // Assuming Pagination component is in the same directory

const GravestoneListCustomer = ({
  fetchId,
  queryParam,
  showAddNewGravestone = true,
  showSearch = false,
  showMapButton = false,
  showLimit = true
}) => {
  const [gravestones, setGravestones] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [error, setError] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const newGravestonePath = `/gravsteiner/ny/${fetchId}`;
  const apiURL = process.env.REACT_APP_API_BASE_URL || "";
  const mainURL = process.env.REACT_APP_MAIN_URL || "";

  const handleSearchChange = (e) => setSearch(e.target.value);
  const handleLimitChange = (e) => setLimit(e.target.value);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `${apiURL}/api/gravestones?${queryParam}=${fetchId}&limit=${limit}&search=${search}&page=${page}`
        );
        const data = await response.json();
        setIsLoading(false);
        if (data.gravestones) {
          setGravestones(data.gravestones);
          setTotalPages(data.totalPages);
        } else {
          setError("No gravestones found");
        }
      } catch (err) {
        setIsLoading(false);
        setError("Failed to fetch gravestones");
      }
    };

    fetchData();
  }, [fetchId, queryParam, search, limit, page]);

  if (error) return <div className="text-red-500 text-center py-4">{error}</div>;

  return (
    <div>
      <div className="flex items-center space-x-4 mb-4">
        {showSearch && (
          <div className="relative">
            <input
              type="text"
              value={search}
              onChange={handleSearchChange}
              className="px-4 py-2 pl-10 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-green-grave"
              placeholder="Search..."
            />
            <FiSearch className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-400" />
          </div>
        )}
        {showMapButton && (
          <Link
            to={`/mobil/gravsteiner/kirkegard/${fetchId}/kart`}
            className="flex items-center justify-center px-4 py-2 bg-green-grave text-white rounded-md hover:bg-green-600 transition-colors"
          >
            <FaMap className="mr-2" /> <span>Vis kart</span>
          </Link>
        )}
        {showLimit && (
          <select
            value={limit}
            onChange={handleLimitChange}
            className="px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-green-grave"
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
        )}
      </div>
      <div>
        {isLoading && <div className="text-center">Loading...</div>}
        <ul className="space-y-4">
          {gravestones.map((gravestone) => (
            <li key={gravestone.id} className="bg-white rounded-lg shadow-lg p-4 hover:bg-gray-100 transition-colors">
              <Link to={`/gravsteiner/${gravestone.id}`} className="flex items-center space-x-4">
                {gravestone.thumbnail ? (
                  <img
                    src={mainURL + gravestone.thumbnail || "https://via.placeholder.com/150"}
                    alt="Gravestone Thumbnail"
                    className="w-24 h-24 object-cover rounded-lg"
                  />
                ) : (
                  <img
                    src={mainURL + gravestone.picture || "https://via.placeholder.com/150"}
                    alt="Gravestone Thumbnail"
                    className="w-24 h-24 object-cover rounded-lg"
                  />
                )}
                <div className="flex-1">
                  <div className="font-semibold text-gray-800">{gravestone.gravepersons_names || "Ingen gravpersoner"}</div>
                  <div className="text-sm text-gray-500">Siste besøk: {new Date(gravestone.latest_visit_date).toLocaleDateString("nb-NO")}</div>
                </div>
              </Link>
            </li>
          ))}
          {showAddNewGravestone && (
            <li className="bg-white rounded-lg shadow-lg p-4 hover:bg-gray-100 transition-colors">
              <Link to={newGravestonePath} className="flex items-center space-x-4">
                <AiOutlinePlus size="2em" className="text-green-grave" />
                <div>
                  <div className="font-semibold text-xl text-green-grave">Legg til ny gravsten</div>
                  <div className="text-sm text-gray-500">Klikk her for å legge til en ny gravsten i systemet.</div>
                </div>
              </Link>
            </li>
          )}
        </ul>
        <Pagination totalPages={totalPages} currentPage={page} setPage={setPage} />
      </div>
    </div>
  );
};

export default GravestoneListCustomer;