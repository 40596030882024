import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const Modal = ({ isOpen, onClose, children }) => {
  const backdropVariants = {
    visible: { opacity: 1, transition: { duration: 0.3 } },
    hidden: { opacity: 0, transition: { duration: 0.3 } },
  };

  const modalVariants = {
    hidden: { x: '100%' },
    visible: {
      x: 0,
      transition: { delay: 0.3, duration: 0.3 }
    },
  };

  return (
    <AnimatePresence mode='wait'>
      {isOpen && (
        <motion.div 
          className="fixed inset-0 z-50 flex justify-end items-center bg-black bg-opacity-50"
          variants={backdropVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          onClick={onClose}
        >
          <motion.div 
  className="modal-content bg-white p-8 border shadow-lg w-1/3"
  style={{ maxHeight: '100vh', minHeight: '100vh', overflowY: 'auto' }} // Adjusted styles here
  variants={modalVariants}
  initial="hidden"
  animate="visible"
  exit="hidden"
  onClick={(e) => e.stopPropagation()} // Prevent click from closing modal
>
  <span className="close-button fixed top-6 right-8 text-3xl text-gray-600 cursor-pointer" onClick={onClose}>&times;</span>
  {children}
</motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Modal;