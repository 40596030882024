import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import ReusableSearch from '../../components/ReusableSearch';
import ReusableHeader from '../../components/ReusableHeader';
import BackButton from '../../components/BackButton';

function GraveyardsMobile() {
  const [graveyards, setGraveyards] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState('');
  const [limit] = useState(500);

  const apiURL = process.env.REACT_APP_API_BASE_URL || '';
  const baseURL = 'https://cms.gravstellerne.no';

  const handleSearchChange = (e) => setSearch(e.target.value);

  useEffect(() => {
    const fetchGraveyards = async () => {
      setIsLoading(true);
      try {
        const queryParams = new URLSearchParams({ search, limit }).toString();
        const response = await fetch(`${apiURL}/api/graveyards?${queryParams}`);
        if (!response.ok) {
          throw new Error('Something went wrong!');
        }
        const data = await response.json();
        setGraveyards(data.graveyards);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchGraveyards();
  }, [search]);

  if (error) return <div className="text-red-500 text-center">Error: {error}</div>;

  return (
    <div className="p-4 relative">
      <BackButton />
      <div className="flex justify-between items-center mb-4 mt-7">
        <h1 className="text-xl font-bold">Kirkegårder</h1>
        <ReusableSearch value={search} onChange={handleSearchChange} />
      </div>
      <div className="space-y-4">
  {isLoading && <Spinner />}
  {graveyards.map((graveyard) => (
    <Link to={`/mobil/kirkegarder/${graveyard.id}`} key={graveyard.id} className="flex items-center bg-white rounded-lg shadow hover:shadow-md overflow-hidden">
      <div className="flex-shrink-0">
        <img src={baseURL + graveyard.picture} alt={`${graveyard.name} thumbnail`} className="h-20 w-20 md:h-24 md:w-24 object-cover" />
        {/* If no image, show a placeholder */}
        {!graveyard.picture && (
          <div className="h-20 w-20 md:h-24 md:w-24 flex items-center justify-center bg-gray-200 text-gray-500">
            {/* Placeholder icon or text */}
          </div>
        )}
      </div>
      <div className="p-2 flex-1">
        <h5 className="text-md md:text-lg font-bold">{graveyard.name}</h5>
        <p className="text-xs md:text-sm text-gray-600">{graveyard.gravestone_count} gravplasser</p>
      </div>
    </Link>
  ))}
</div>
    </div>
  );
}

export default GraveyardsMobile;