import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import useIntersectionObserver from "../hooks/useIntersectionObserver";
import { Link } from "react-router-dom";
import { positiveFeedbackResponses, apologeticResponses, empatheticResponses } from '../constants/responseMessages';
import userNoPicture from '../images/user_no_picture.png';
import { removeRoleSuffix } from "../utils/stringUtils";
import MiniVisitDetail from './MiniVisitDetail';

const VisitComment = ({
  visit_id,
  visit_type,
  gravestone_id,
  customer_id,
  user_id,
  readOnly = false,
  showVisitLink = false,
  showAllFromCustomer = false, // New prop to show all comments from customer
  showAutoResponses = false, // New prop to show auto-responses
}) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [autoSubmit, setAutoSubmit] = useState(false); // New state to track auto-submit
  const apiURL = process.env.REACT_APP_API_BASE_URL || "";

  useEffect(() => {
    if (gravestone_id && visit_type && visit_id) {
      fetchComments();
    } else if (showAllFromCustomer && customer_id) {
      fetchCustomerComments();
    }
  }, [gravestone_id, visit_type, visit_id, customer_id, showAllFromCustomer]);

  const fetchComments = async () => {
    try {
      const response = await axios.get(
        `${apiURL}/api/visit_comments/customer`,
        {
          params: { gravestone_id, visit_type, visit_id },
        }
      );
      setComments(response.data);
      console.log("Comments:", response.data);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  const fetchCustomerComments = async () => {
    try {
      const response = await axios.get(
        `${apiURL}/api/visit_comments/customer_all`,
        {
          params: { customer_id },
        }
      );
      setComments(response.data);
      console.log("Customer Comments:", response.data);
    } catch (error) {
      console.error("Error fetching customer comments:", error);
    }
  };

  const handleAutoResponse = (responsesArray) => {
    const randomResponse =
      responsesArray[Math.floor(Math.random() * responsesArray.length)];
    setNewComment(randomResponse);
    setAutoSubmit(false); // Indicate that auto-submit should happen
  };

  useEffect(() => {
    if (autoSubmit) {
      handleAddComment();
      setAutoSubmit(false); // Reset auto-submit flag
    }
  }, [newComment, autoSubmit]); // Trigger effect when newComment or autoSubmit changes

  const handleAddComment = async () => {
    if (newComment.trim() === "") return;

    try {
      await axios.post(`${apiURL}/api/visit_comments`, {
        customer_id,
        user_id,
        gravestone_id,
        visit_type,
        visit_id,
        comment: newComment,
      });
      setNewComment("");
      fetchComments();
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  return (
    <div className="mt-4">
      {!readOnly && (
        <div className="mb-4">
          <textarea
            className="w-full p-2 border rounded-lg"
            rows="4"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Legg til en kommentar"
          ></textarea>
          <button
            onClick={handleAddComment}
            className="mt-2 px-4 py-2 bg-green-grave text-white rounded-full shadow hover:bg-green-800 transition duration-300"
          >
            Legg til kommentar
          </button>
          {showAutoResponses && (
            <div className="mt-8 p-6 bg-white rounded-xl shadow-lg border border-gray-200">
            <h3 className="text-xl font-bold text-gray-800 mb-6">
              Rask Respons
            </h3>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3">
              <button
                onClick={() => handleAutoResponse(positiveFeedbackResponses)}
                className="px-3 py-2 bg-gradient-to-r from-green-grave to-green-700 text-white text-sm rounded-xl shadow-md transform hover:scale-105 transition-transform duration-200"
              >
                Bra du er fornøyd
              </button>
              <button
                onClick={() => handleAutoResponse(apologeticResponses)}
                className="px-3 py-2 bg-gradient-to-r from-red-500 to-red-700 text-white text-sm rounded-xl shadow-md transform hover:scale-105 transition-transform duration-200"
              >
                Beklager at du ikke er fornøyd
              </button>
              <button
                onClick={() => handleAutoResponse(empatheticResponses)}
                className="px-3 py-2 bg-gradient-to-r from-blue-500 to-blue-700 text-white text-sm rounded-xl shadow-md transform hover:scale-105 transition-transform duration-200"  
              >
                Empatisk respons
              </button>
              {/* Add more category buttons as needed */}
            </div>
          </div>
          )}
        </div>
      )}
      <div className="space-y-4">
        {comments.map((comment) => (
          <CommentItem
            key={comment.id}
            comment={comment}
            customer_id={comment.customer_id}
            user_id={comment.user_id}
            gravestone_id={comment.gravestone_id}
            visit_type={comment.visit_type}
            visit_id={comment.visit_id}
            showVisitLink={showVisitLink} // Pass the new prop
          />
        ))}
      </div>
    </div>
  );
};

const CommentItem = ({
  comment,
  customer_id,
  user_id,
  gravestone_id,
  visit_type,
  visit_id,
  showVisitLink,
}) => {
  const commentRef = useRef();
  const isVisible = useIntersectionObserver(commentRef, { threshold: 0.1 });
  const apiURL = process.env.REACT_APP_API_BASE_URL || "";
  const devURL = process.env.REACT_APP_DEV_URL || "https://dev.gravstellerne.no/backend/";

  const [isTooltipOpen, setIsTooltipOpen] = useState(false); // State to manage tooltip visibility

  useEffect(() => {
    const updateSeenStatus = async () => {
      try {
        if (isVisible) {
          console.log(`Comment with ID ${comment.id} is in view.`);

          const payload = { gravestone_id, visit_type, visit_id };
          if (customer_id) {
            payload.customer_id = customer_id;
          } else if (user_id) {
            payload.user_id = user_id;
          }

          await axios.patch(`${apiURL}/api/visit_comments/seen`, payload);
          console.log("Seen status updated");
        }
      } catch (error) {
        console.error("Error updating seen status:", error);
      }
    };

    updateSeenStatus();
  }, [
    isVisible,
    comment.id,
    customer_id,
    user_id,
    gravestone_id,
    visit_type,
    visit_id,
    apiURL,
  ]);

  return (
    <div
      ref={commentRef}
      className="p-6 bg-white rounded-lg shadow-md border border-gray-300"
    >
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center">
          {comment.user_id ? (
            <img
              src={devURL + comment.user_picture || userNoPicture}
              alt={comment.user_name}
              className="h-10 w-10 rounded-full"
            />
          ) : (
            <div className="flex-shrink-0 h-10 w-10 rounded-full bg-green-grave flex items-center justify-center text-white font-semibold">
              {comment.name ? comment.name.charAt(0) : "C"}
            </div>
          )}
          <div className="ml-3">
            <p className="text-lg font-medium text-gray-800">
              {removeRoleSuffix(comment.name) || "Customer"}
            </p>
            <p className="text-sm text-gray-500">
              {new Date(comment.created_at).toLocaleString("nb-NO")}
            </p>
          </div>
        </div>
        {showVisitLink && (
          <div
            onMouseEnter={() => setIsTooltipOpen(true)}
            onMouseLeave={() => setIsTooltipOpen(false)}
            className="relative"
          >
            <Link
              to={`/besok/${visit_id}/detaljer/${
                visit_type === "visits" ? "1" : "0"
              }`}
              className="text-green-grave hover:text-orange-grave underline"
              data-tooltip-id={`tooltip-${visit_id}`}
            >
              Se Besøk
            </Link>
            {isTooltipOpen && (
                <MiniVisitDetail visitId={visit_id} isLegacy={visit_type === "visits" ? "1" : "0"} />
            )}
          </div>
        )}
      </div>
      <p className="text-gray-800">{comment.comment}</p>
    </div>
  );
};

export default VisitComment;
