import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useUser } from '../../context/UserContext';
import UnderConstruction from '../../components/UnderConstruction';

const MyVisits = () => {
  const { user } = useUser();
  const [visits, setVisits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [underConstruction, setUnderConstruction] = useState(true);
  const [error, setError] = useState('');

  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  useEffect(() => {
    const fetchVisits = async () => {
      try {
        const response = await axios.get(`${apiURL}/api/visits/user/${user.id}`);
        setVisits(response.data.visits);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch visits');
        setLoading(false);
      }
    };

    if (user && user.id) {
      fetchVisits();
    }
  }, [user]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="text-red-500">{error}</div>;
  if (underConstruction) return <UnderConstruction />;

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-xl font-bold mb-4">Mine Besøk</h1>
      {visits.length ? (
        <ul>
          {visits.map(visit => (
            <li key={visit.id} className="bg-white shadow-lg rounded-lg p-4 mb-4">
              <p>Date: {new Date(visit.visit_date).toLocaleDateString()}</p>
              <p>Before Picture: {visit.before_picture_url}</p>
              <p>After Picture: {visit.after_picture_url}</p>
              <p>Visit Type: {visit.is_legacy ? 'New' : 'Old'}</p>
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-gray-600">No visits found.</p>
      )}
    </div>
  );
};

export default MyVisits;