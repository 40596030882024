import React from 'react';
import { FaTools } from 'react-icons/fa';

const UnderConstruction = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen p-4 bg-gray-100">
      <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center text-center">
        <FaTools className="text-gray-500 text-5xl mb-4" />
        <h1 className="text-2xl font-bold mb-2 text-gray-800">Siden er under utvikling</h1>
        <p className="text-gray-600 mb-4">
          Vi jobber hardt med å få denne funksjonen klar for deg. Vennligst kom tilbake senere!
        </p>
        <p className="text-gray-500 text-sm">
          Takk for din tålmodighet.
        </p>
      </div>
    </div>
  );
};

export default UnderConstruction;