import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
import { useNotifications } from "../../context/NotificationContext";
import LoadingScreen from "../../components/LoadingScreen";
import axios from "axios";
import { AiOutlineUpload } from "react-icons/ai";

function AddGravestone() {
  const { customerId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const { addNotification } = useNotifications();
  const [graveyards, setGraveyards] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [products, setProducts] = useState([]);
  const [formData, setFormData] = useState({
    graveyard_id: "",
    picture: "",
    location: "",
    latitude: "",
    longitude: "",
    box: "",
    legat: "",
    description: "",
    field: "",
    row: "",
    church_number: "",
    place: "",
    products: [],
    warning: "",
    days_between_visits: "21",
    is_auto: false,
  });

  const apiURL = process.env.REACT_APP_API_BASE_URL || "";

  useEffect(() => {
    // Fetch graveyards when the component mounts
    fetch(`${apiURL}/api/graveyardsWorkorder`)
      .then((response) => response.json())
      .then((data) => {
        // Transform the data to fit the Select component
        const options = data.map((graveyard) => ({
          value: graveyard.id.toString(),
          label: graveyard.name,
        }));
        setGraveyards(options);
      })
      .catch((error) => console.error("Failed to fetch graveyards:", error));
  }, []);

  useEffect(() => {
    // Fetch products from the backend
    fetch(`${apiURL}/api/productsWorkorder`)
      .then((response) => response.json())
      .then((data) => {
        // Map products to format required by react-select
        const options = data.map((product) => ({
          value: product.id.toString(),
          label: product.name,
        }));
        setProducts(options);
      })
      .catch((error) => {
        console.error("Failed to fetch products", error);
      });
  }, []);

  useEffect(() => {
    const fetchCustomerDetails = async () => {
      setIsLoading(true);
      setError("");

      try {
        const response = await fetch(`${apiURL}/api/customers/${customerId}`);
        if (!response.ok) throw new Error("Failed to load customer details.");
        const data = await response.json();

        setCustomer(data); // Assuming API returns a single customer object
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchCustomerDetails();
  }, [customerId]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleChangeGraveyard = (selectedOption) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      graveyard_id: selectedOption ? selectedOption.value : "",
    }));
  };

  const handleFileChange = (e) => {
    // Assuming you want to store the file itself in state, not just the filename
    setFormData({ ...formData, picture: e.target.files[0] });
  };

  const handleProductChange = (products) => {
    // Assuming 'selectedOptions' is an array of { value, label } objects
    // and you want to store just the values in your formData
    const productIds = products.map((option) => option.value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      products: productIds, // Storing an array of selected product IDs in formData
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create a FormData object to programmatically build a set of key/value pairs representing form fields and their values
    const formDataToSend = new FormData();

    // Append all your form fields to the formDataToSend object
    // For normal text fields:
    formDataToSend.append('graveyard_id', formData.graveyard_id);
    formDataToSend.append('location', formData.location);
    formDataToSend.append('latitude', formData.latitude);
    formDataToSend.append('longitude', formData.longitude);
    formDataToSend.append('box', formData.box);
    formDataToSend.append('legat', formData.legat);
    formDataToSend.append('description', formData.description);
    formDataToSend.append('field', formData.field);
    formDataToSend.append('row', formData.row);
    formDataToSend.append('church_number', formData.church_number);
    formDataToSend.append('place', formData.place);
    formDataToSend.append('warning', formData.warning);
    formDataToSend.append('days_between_visits', formData.days_between_visits);
    formDataToSend.append('is_auto', formData.is_auto);
    formDataToSend.append('customer_id', customerId); // Assuming customerId is already defined

    // For the file, ensure you append the file object, not a file path
    if (formData.picture) {
        formDataToSend.append('picture', formData.picture);
    }

    // Convert the products array to JSON string and append
    formDataToSend.append('products', JSON.stringify(formData.products));

    try {
        // Note: You don't need to manually set the Content-Type header. 
        // When you use FormData, the browser sets it automatically, including the boundary parameter.
        const response = await axios.post(`${apiURL}/api/gravestones`, formDataToSend);

        addNotification({message: "Gravsten lagt til", type: "success"});
        navigate(`/gravpersoner/ny/${response.data.id}`);
    } catch (error) {
        console.error("Error adding gravestone:", error);
        addNotification({message: "Feil ved lagring av gravsten", type: "error"});
    }
};

if (isLoading) {
    return <LoadingScreen />;
}

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-xl font-bold mb-4">
        Legg Til Ny Gravsten for {customer?.first_name} {customer?.last_name}
      </h1>
      {/* Displaying some customer details */}
      <div className="mb-6 bg-gray-100 p-4 rounded-lg">
        <p>
          <strong>Email:</strong> {customer?.email}
        </p>
        <p>
          <strong>Telefonnummer:</strong> {customer?.phone_number}
        </p>
        <p>
          <strong>Adresse:</strong> {customer?.address}
        </p>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-2 gap-4">
          <div className="mb-4">
            <label
              htmlFor="picture"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Velg Bilde
            </label>
            <label
              htmlFor="picture"
              className="block mb-2 text-sm min-h-16 font-medium text-gray-900 flex items-center justify-center cursor-pointer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 hover:bg-gray-100 transition-colors"
            >
              <AiOutlineUpload className="mr-2 text-green-500" />
              {formData.picture ? formData.picture.name : "Last opp bilde"}
              <input
                type="file"
                id="picture"
                name="picture"
                onChange={handleFileChange}
                className="hidden" // Hide the actual input but make the label clickable
              />
            </label>
          </div>
          <div className="mb-4">
            <label
              htmlFor="graveyard_id"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Velg Kirkegård
            </label>
            <Select
              id="graveyard_id"
              options={graveyards}
              value={graveyards.find(
                (option) => option.value === formData.graveyard_id
              )}
              onChange={handleChangeGraveyard}
              className="bg-gray-50 border border-gray-300 min-h-16 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mb-4"
              classNamePrefix="select"
              placeholder="Velg en kirkegård"
              isClearable
            />
          </div>
        </div>

        {/* Latitude and Longitude Fields */}
        <div className="grid grid-cols-4 gap-4">
          <div className="mb-4">
            <label
              htmlFor="latitude"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Latitude
            </label>
            <input
              type="text"
              id="latitude"
              name="latitude"
              value={formData.latitude}
              onChange={handleChange}
              placeholder="Eks. 59.933222308599"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="longitude"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Longitude
            </label>
            <input
              type="text"
              id="longitude"
              name="longitude"
              value={formData.longitude}
              onChange={handleChange}
              placeholder="Eks. 10.855310700478"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="box"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Boks
            </label>
            <input
              type="number"
              id="box"
              name="box"
              value={formData.box}
              onChange={handleChange}
              placeholder="Eks. 90"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="days_between_visits"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Dager Mellom Besøk
            </label>
            <input
              type="number"
              id="days_between_visits"
              name="days_between_visits"
              value={formData.days_between_visits}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            />
          </div>
        </div>

        <div className="grid grid-cols-5 gap-4">
          <div className="mb-4">
            <label
              htmlFor="location"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Lokasjon
            </label>
            <input
              type="text"
              id="location"
              name="location"
              value={formData.location}
              onChange={handleChange}
              placeholder="Eks. 049,04,025,3"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="field"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Felt
            </label>
            <input
              type="text"
              id="field"
              name="field"
              value={formData.field}
              onChange={handleChange}
              placeholder="Eks. 04"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="row"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Rad
            </label>
            <input
              type="text"
              id="row"
              name="row"
              value={formData.row}
              onChange={handleChange}
              placeholder="Eks. 025"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="church_number"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Kirkenummer
            </label>
            <input
              type="text"
              id="church_number"
              name="church_number"
              value={formData.church_number}
              onChange={handleChange}
              placeholder="Eks. 049"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="place"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Plass
            </label>
            <input
              type="text"
              id="place"
              name="place"
              value={formData.place}
              onChange={handleChange}
              placeholder="Eks. 3"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            />
          </div>
        </div>

        {/* Description Field */}
        <div className="mb-4">
          <label
            htmlFor="description"
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            Beskrivelse
          </label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            rows="3"
          ></textarea>
        </div>
        <label
          htmlFor="products"
          className="block mb-2 text-sm font-medium text-gray-900"
        >
          Produkter
        </label>
        <Select
          isMulti
          options={products} // Use state for product options
          value={products.filter((product) =>
            formData.products.includes(product.value)
          )}
          onChange={handleProductChange}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mb-4"
          placeholder="Velg produkter.."
        />

        <button
          type="submit"
          className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
        >
          Legg til gravsten
        </button>
      </form>
    </div>
  );
}

export default AddGravestone;
