import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    FaTimes,
    FaBars,
    FaClipboardList,
    FaRegCalendarCheck,
    FaTachometerAlt,
    FaUser,
    FaChurch,
    FaMonument,
  } from "react-icons/fa";
  import { useUser } from "../context/UserContext";
  import { useSwipeable } from 'react-swipeable';

const MobileMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { user } = useUser();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const workerMenuItems = [
    { icon: FaTachometerAlt, label: "Dashboard", to: "/" },
    { icon: FaChurch, label: "Kirkegårder", to: "/kirkegarder" },
  { icon: FaMonument, label: "Gravsteiner", to: "/gravsteiner" }, 
    {
      icon: FaClipboardList,
      label: "Mine Arbeidslister",
      to: "/mine-arbeidslister",
    },
    { icon: FaRegCalendarCheck, label: "Mine Besøk", to: "/mine-besok" },
    { icon: FaUser, label: "Din Profil", to: `/brukere/${user?.id}` },
    // Add additional items as needed
  ];

  const handlers = useSwipeable({
    onSwipedRight: () => setIsMenuOpen(false), // Assuming you want to close the menu on swipe left
    // You can add more event handlers as needed
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  return (
    <>
      {/* Hamburger Menu Button */}
      <button
        onClick={toggleMenu}
        className="fixed top-4 right-4 z-50 sm:hidden"
      >
        {isMenuOpen ? <FaTimes size={24} className="text-white" /> : <FaBars size={24} />}
      </button>

      {/* Fullscreen Menu */}
      <div
        {...handlers}
        className={`fixed inset-0 z-40 flex flex-col bg-gray-800 text-white transform ${
          isMenuOpen ? 'translate-x-0' : 'translate-x-full'
        } transition-transform duration-300 ease-in-out`}
      >
        {/* Menu Close Button */}
        <button onClick={toggleMenu} className="self-end p-4 text-white">
          <FaTimes size={24} className="text-white" />
        </button>

        {/* Menu Items */}
<div className="flex-grow flex flex-col items-center justify-center">
  {workerMenuItems.map((item, index) => (
    <Link
      key={index}
      to={item.to}
      className="flex items-center text-xl font-medium my-4 hover:text-gray-300 transition duration-150"
      onClick={() => setIsMenuOpen(false)} // Close menu on item click
    >
      <item.icon size={24} className="mr-2" /> {/* Icon next to text */}
      <span>{item.label}</span>
    </Link>
  ))}
</div>
      </div>

      {/* The rest of your component */}
    </>
  );
};

export default MobileMenu;