import React, { useState } from 'react';
import { FaShoppingCart, FaCalendarAlt, FaEdit, FaPlus } from 'react-icons/fa';
import picture from '../../images/friske_blomster.webp';
import axiosInstance from '../../utils/axiosInstance';
import { useNotifications } from '../../context/NotificationContext';

const specialDayOptions = [
  'Fødselsdag',
  'Dødsdag',
  'Bryllupsdag',
  'Personlige milepæler',
  'Familiegjenforeningsdatoer',
  'Annet'
];

const specialDayIcons = {
  'Fødselsdag': FaCalendarAlt,
  'Dødsdag': FaEdit,
  'Bryllupsdag': FaEdit,
  'Personlige milepæler': FaEdit,
  'Familiegjenforeningsdatoer': FaEdit,
  'Annet': FaEdit
};

const FreshFlowersAd = ({ gravestone, customer }) => {
  const [isCustomDescription, setIsCustomDescription] = useState(false);
  const [newSpecialDay, setNewSpecialDay] = useState({ date: '', description: '', customDescription: '', active: false });
  const { addNotification } = useNotifications();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewSpecialDay({ ...newSpecialDay, [name]: value });
  };

  const handleOrderConfirmation = async (e) => {
    e.preventDefault();
    try {
      const finalDescription = isCustomDescription ? newSpecialDay.customDescription : newSpecialDay.description;
      const emailData = {
        to: 'post@gravstellerne.no',
        subject: 'Bestilling av Friske Blomster',
        text: `
          Kunde: ${customer.name} (${customer.email})
          Gravstein ID: ${gravestone.id}
          Gravstein Plassering: Felt ${gravestone.field}, Rad ${gravestone.row}, Plass ${gravestone.place}

          Bestilling: Friske Blomster - 549kr

          Spesiell Dag:
          Dato: ${newSpecialDay.date}
          Beskrivelse: ${finalDescription}
        `,
        html: `
          <h3>Bestilling av Friske Blomster</h3>
          <p><strong>Kunde:</strong> ${customer.name} (${customer.email})</p>
          <p><strong>Gravstein ID:</strong> ${gravestone.id}</p>
          <p><strong>Gravstein Plassering:</strong> Felt ${gravestone.field}, Rad ${gravestone.row}, Plass ${gravestone.place}</p>
          <p><strong>Bestilling:</strong> Friske Blomster - 549kr</p>
          <h3>Spesiell Dag:</h3>
          <p><strong>Dato:</strong> ${newSpecialDay.date}</p>
          <p><strong>Beskrivelse:</strong> ${finalDescription}</p>
        `,
        email_type: 'order',
        related_id: gravestone.id,
        from: 'kunde@gravstellerne.no',
        isAdHoc: true,
      };

      await axiosInstance.post('/api/send-email', emailData);

      const response = await axiosInstance.post(`/api/special-days`, {
        date: newSpecialDay.date,
        description: finalDescription,
        gravestone_id: gravestone.id,
      });

      addNotification({ type: 'success', message: 'Bestillingen er sendt og spesiell dag lagt til!' });
      setNewSpecialDay({ date: '', description: '', customDescription: '', active: false });
    } catch (error) {
      console.error('Error during order confirmation:', error);
      addNotification({ type: 'error', message: 'Det oppsto en feil under bestillingen. Vennligst prøv igjen.' });
    }
  };

  return (
    <div className="bg-white shadow-lg rounded-xl p-4 w-full mx-auto border-t-4 border-green-grave flex flex-col md:flex-row items-center">
      <img src={picture} alt="Friske blomster" className="w-1/3 h-auto rounded-lg shadow-md mr-4 mb-4 md:mb-0" />
      <div className="flex-grow">
        <h2 className="text-lg font-bold text-left mb-2">Friske blomster <span className='text-green-grave ml-3'>549kr</span></h2>
        <p className="text-left text-gray-600 mb-2 text-sm">
        Gravstellerne tilbyr å sette ut friske blomster på merkedager. Blomstene må bestilles senest 3 dager i forkant.
        </p>
        <form onSubmit={handleOrderConfirmation} className="space-y-4">
          <div>
            <h3 className="text-md font-bold mb-4">Hvilken dag ønskes dette på?</h3>
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="date">
                <span className="flex items-center"><FaCalendarAlt className="mr-2" /> Dato</span>
              </label>
              <input
                id="date"
                name="date"
                type="date"
                value={newSpecialDay.date}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
            <div className='mt-2'>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
                <span className="flex items-center"><FaEdit className="mr-2" /> Anledning</span>
              </label>
              <select
                id="description"
                name="description"
                value={newSpecialDay.description}
                onChange={(e) => {
                  handleChange(e);
                  setIsCustomDescription(e.target.value === 'Annet');
                }}
                className="w-full px-4 py-2 border rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              >
                <option value="Ingen spesiell anledning">Ingen spesiell anledning</option>
                {specialDayOptions.map((option) => {
                  const Icon = specialDayIcons[option];
                  return (
                    <option key={option} value={option}>
                      {Icon && <Icon className="inline-block mr-2" />} {option}
                    </option>
                  );
                })}
              </select>
            </div>
            {isCustomDescription && (
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="customDescription">
                  <span className="flex items-center"><FaEdit className="mr-2" /> Spesifiser Beskrivelse</span>
                </label>
                <input
                  id="customDescription"
                  name="customDescription"
                  type="text"
                  value={newSpecialDay.customDescription}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
            )}
          </div>
          <button 
            type="submit"
            className="bg-green-grave hover:bg-green-600 text-white font-bold py-2 px-3 rounded inline-flex items-center transition duration-200 ease-in-out text-sm">
            <FaShoppingCart className="mr-1" />
            Bestill friske blomster
          </button>
        </form>
      </div>
    </div>
  );
};

export default FreshFlowersAd;