import React, { useState, useEffect } from 'react';
import { MdEmail, MdPhone } from 'react-icons/md';
import { AiOutlineClockCircle, AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai';

const LogViewer = ({ type, identifier }) => {
  const [logs, setLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  useEffect(() => {
    const apiUrl = `${apiURL}/api/logs/${type}?${type === 'sms' ? 'phone_number' : 'to_email'}=${encodeURIComponent(identifier)}`;

    const fetchLogs = async () => {
      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error('Failed to fetch logs');
        }
        const data = await response.json();
        if (data.status === 'success') {
          setLogs(data.data);
        } else {
          setError(data.message || 'No logs found');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLogs();
  }, [type, identifier]);

  return (
    <div className="max-w-4xl mx-auto p-4 space-y-4">
      {isLoading ? (
        <div className="flex justify-center items-center space-x-2">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-green-500"></div>
          <span className="text-lg font-medium">Laster inn...</span>
        </div>
      ) : error ? (
        <div className="text-red-600 bg-red-100 p-4 rounded-md shadow">{error}</div>
      ) : logs.length > 0 ? (
        logs.map((log) => (
          <div key={log.id} className="bg-white shadow rounded-lg overflow-hidden">
            <div className="px-4 py-5 sm:px-6 flex items-center space-x-4">
              {type === 'sms' ? <MdPhone className="h-6 w-6 text-green-500" /> : <MdEmail className="h-6 w-6 text-green-500" />}
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                {type === 'sms' ? `Telefonnummer: ${log.phone_number}` : `Til: ${log.to_email}`}
              </h3>
              <span className="ml-auto flex items-center">
                {log.success ? <AiOutlineCheckCircle className="h-5 w-5 text-green-500" /> : <AiOutlineCloseCircle className="h-5 w-5 text-red-500" />}
                <span className="ml-1 text-sm text-gray-500">{log.success ? 'Suksess' : 'Feil'}</span>
              </span>
            </div>
            <div className="border-t border-gray-200">
              <dl>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Melding</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2" dangerouslySetInnerHTML={{ __html: log.content || log.message }} />
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Sendt</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center">
                    <AiOutlineClockCircle className="h-5 w-5 text-gray-400 mr-2" />
                    {new Date(log.created_at).toLocaleString('nb-NO')}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        ))
      ) : (
        <div className="text-center py-10">
          <p className="text-gray-500 text-lg">Ingen logger funnet.</p>
        </div>
      )}
    </div>
  );
};

export default LogViewer;