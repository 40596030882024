import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import ReactQuill from 'react-quill';
import { FaEdit, FaSave, FaTimes } from 'react-icons/fa';
import { useNotifications } from '../../context/NotificationContext';

const EditEmailTemplate = () => {
  const { id } = useParams();
  const [template, setTemplate] = useState({ name: '', content: '', is_default: '0' });
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const { addNotification } = useNotifications();
  const quillRef = useRef(null);
  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  useEffect(() => {
    const fetchTemplate = async () => {
      try {
        const response = await axios.get(`${apiURL}/api/templates/email/${id}`);
        setTemplate(response.data);
      } catch (error) {
        console.error('Error fetching template:', error);
        addNotification({ message: 'Error fetching template', type: 'error' });
      }
    };

    fetchTemplate();
  }, [id, apiURL, addNotification]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTemplate((prevTemplate) => ({ ...prevTemplate, [name]: value }));
  };

  const handleContentChange = (content) => {
    setTemplate((prevTemplate) => ({ ...prevTemplate, content }));
  };

  const handleSave = async (e) => {
    e.preventDefault();

    try {
      await axios.put(`${apiURL}/api/templates/email/${id}`, template);
      addNotification({ message: 'Template updated successfully', type: 'success' });
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating template:', error);
      addNotification({ message: 'Error updating template', type: 'error' });
    }
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const insertCode = (code) => {
    const editor = quillRef.current.getEditor();
    const cursorPosition = editor.getSelection();
    if (cursorPosition) {
      editor.insertText(cursorPosition.index, code);
      editor.setSelection(cursorPosition.index + code.length);
    } else {
      // If no selection (editor is not focused), insert at the end
      editor.insertText(editor.getLength() - 1, code);
    }
  };

return (
    <div className="flex max-w-5xl mx-auto p-8 bg-white rounded-lg shadow-lg">
  <div className="w-4/6 pr-8">
    <div className="flex justify-between items-center mb-6">
      {isEditing ? (
        <h1 className="text-3xl font-bold text-gray-800">Rediger E-postmal</h1>
      ) : (
        <h1 className="text-3xl font-bold text-gray-800">Vis E-postmal</h1>
      )}
      {isEditing ? (
        <div>
          <button
            onClick={handleSave}
            className="bg-green-grave text-white p-2 rounded-lg shadow-md hover:bg-orange-grave transition duration-150 ease-in-out mr-2"
          >
            <FaSave />
          </button>
          <button
            onClick={handleEditToggle}
            className="bg-red-500 text-white p-2 rounded-lg shadow-md hover:bg-red-600 transition duration-150 ease-in-out"
          >
            <FaTimes />
          </button>
        </div>
      ) : (
        <button
          onClick={handleEditToggle}
          className="bg-green-grave text-white p-2 rounded-lg shadow-md hover:bg-orange-grave transition duration-150 ease-in-out"
        >
          <FaEdit />
        </button>
      )}
    </div>
    {isEditing ? (
      <form className="space-y-6" onSubmit={handleSave}>
        <div>
          <label htmlFor="name" className="block text-lg font-medium text-gray-700">Navn:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={template.name}
            onChange={handleChange}
            className="mt-2 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 transition duration-150 ease-in-out"
            placeholder="Skriv inn malnavn"
          />
        </div>
        <div>
          <label htmlFor="content" className="block text-lg font-medium text-gray-700">Innhold:</label>
          <ReactQuill
            theme="snow"
            value={template.content}
            onChange={handleContentChange}
            className="mt-2"
            placeholder="Skriv inn malinnhold"
          />
        </div>
        <div>
          <label htmlFor="isDefault" className="block text-lg font-medium text-gray-700">Standard?</label>
          <select
            id="isDefault"
            name="is_default"
            value={template.is_default}
            onChange={handleChange}
            className="mt-2 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 transition duration-150 ease-in-out"
          >
            <option value="0">Nei</option>
            <option value="1">Ja</option>
          </select>
        </div>
        <button
          type="submit"
          className="w-full bg-indigo-600 text-white py-3 px-4 rounded-lg shadow-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-150 ease-in-out"
        >
          Lagre Mal
        </button>
      </form>
    ) : (
      <div className="space-y-6 bg-white p-8 rounded-lg shadow-md">
        <div>
          <h2 className="text-3xl font-bold text-gray-800">{template.name}</h2>
          <div dangerouslySetInnerHTML={{ __html: template.content }} className="mt-4"></div>
        </div>
        <div className="mt-6 p-4 bg-gray-100 rounded-lg">
          <div className="grid grid-cols-2 gap-4">
            <div className="flex items-center space-x-2">
              <div>
                <p className="text-xs font-medium text-gray-600">Opprettet:</p>
                <p className="text-sm text-gray-700">{new Date(template.created_at).toLocaleString('nb-NO')}</p>
              </div>
            </div>
            <div className="flex items-center space-x-2">
              <div>
                <p className="text-xs font-medium text-gray-600">Oppdatert:</p>
                <p className="text-sm text-gray-700">{new Date(template.updated_at).toLocaleString('nb-NO')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
  </div>
  <div className="w-2/6 pl-8 bg-gray-50 p-4 rounded-lg shadow-inner">
    <h2 className="text-xl font-bold text-gray-800 mb-4">Tilgjengelige koder</h2>
    <ul className="space-y-4">
      <li>
        <label className="block text-sm font-medium text-gray-700 mb-1">Kunde ID:</label>
        <code
          className="bg-gray-200 px-2 py-1 rounded cursor-pointer"
          onClick={() => insertCode('{{customer_id}}')}
        >
          {'{{customer_id}}'}
        </code>
      </li>
      <li>
        <label className="block text-sm font-medium text-gray-700 mb-1">Kundens fornavn:</label>
        <code
          className="bg-gray-200 px-2 py-1 rounded cursor-pointer"
          onClick={() => insertCode('{{customer_firstname}}')}
        >
          {'{{customer_firstname}}'}
        </code>
      </li>
      <li>
        <label className="block text-sm font-medium text-gray-700 mb-1">Kundens etternavn:</label>
        <code
          className="bg-gray-200 px-2 py-1 rounded cursor-pointer"
          onClick={() => insertCode('{{customer_lastname}}')}
        >
          {'{{customer_lastname}}'}
        </code>
      </li>
      <li>
        <label className="block text-sm font-medium text-gray-700 mb-1">Kundens e-post:</label>
        <code
          className="bg-gray-200 px-2 py-1 rounded cursor-pointer"
          onClick={() => insertCode('{{customer_email}}')}
        >
          {'{{customer_email}}'}
        </code>
      </li>
      <li>
        <label className="block text-sm font-medium text-gray-700 mb-1">Kundens telefon:</label>
        <code
          className="bg-gray-200 px-2 py-1 rounded cursor-pointer"
          onClick={() => insertCode('{{customer_phone}}')}
        >
          {'{{customer_phone}}'}
        </code>
      </li>
      <li>
        <label className="block text-sm font-medium text-gray-700 mb-1">Besøks-URL:</label>
        <code
          className="bg-gray-200 px-2 py-1 rounded cursor-pointer"
          onClick={() => insertCode('{{visit_url}}')}
        >
          {'{{visit_url}}'}
        </code>
      </li>
    </ul>
  </div>
</div>
);
};

export default EditEmailTemplate;