import { useState, useEffect } from 'react';
import axiosInstance from '../utils/axiosInstance';

const useScheduler = () => {
  const [jobs, setJobs] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch jobs with filters and pagination
  const fetchJobs = async ({ search = '', status = '', type = '', page = 1, limit = 10, sort = 'id', order = 'ASC' } = {}) => {
    setLoading(true);
    try {
      const queryParams = new URLSearchParams({ search, status, type, page, limit, sort, order });
      const response = await axiosInstance.get(`/api/scheduled-jobs?${queryParams.toString()}`);
      setJobs(response.data.jobs);
      setTotalItems(response.data.totalItems);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      setError(error.message || 'Failed to fetch jobs');
    } finally {
      setLoading(false);
    }
  };

  // Create a new job
  const createJob = async (jobData) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post('/api/scheduled-jobs', jobData);
      fetchJobs(); // Optionally refetch jobs after creating one
      return response.data;
    } catch (error) {
      setError(error.message || 'Failed to create job');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  // Delete a job
  const deleteJob = async (jobId) => {
    setLoading(true);
    try {
      await axiosInstance.delete(`/api/scheduled-jobs/${jobId}`);
      fetchJobs(); // Optionally refetch jobs after deleting one
    } catch (error) {
      setError(error.message || 'Failed to delete job');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return {
    jobs,
    totalItems,
    totalPages,
    loading,
    error,
    fetchJobs,
    createJob,
    deleteJob,
  };
};

export default useScheduler;