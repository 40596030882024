// NotificationContext.js
import React, { createContext, useContext, useState, useCallback } from 'react';

const NotificationContext = createContext();

export const useNotifications = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const addNotification = useCallback(({ message, type = 'info' }) => {
    const id = new Date().getTime(); // Simple unique ID, consider using a more robust method
    setNotifications((prevNotifications) => [...prevNotifications, { id, message, type }]);
  }, []);

  const removeNotification = useCallback((id) => {
    setNotifications((prevNotifications) => prevNotifications.filter(notif => notif.id !== id));
  }, []);

  return (
    <NotificationContext.Provider value={{ notifications, addNotification, removeNotification }}>
      {children}
    </NotificationContext.Provider>
  );
};