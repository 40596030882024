import React, { useEffect } from 'react';

const ImageModal = ({ isOpen, onClose, imageSrc }) => {

  useEffect(() => {
    if (!isOpen) return;

    const handleOutsideClick = (e) => {
      if (e.target.id === "modalBackdrop") onClose();
    };

    const handleEscKey = (e) => {
      if (e.key === "Escape") onClose();
    };

    window.addEventListener('click', handleOutsideClick);
    window.addEventListener('keydown', handleEscKey);

    return () => {
      window.removeEventListener('click', handleOutsideClick);
      window.removeEventListener('keydown', handleEscKey);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div
      id="modalBackdrop"
      className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-50"
    >
      <div className="flex flex-col items-center bg-white rounded-lg overflow-hidden max-w-[100vw] sm:max-w-[90vw] lg:max-w-[80vw] max-h-[90vh]">
        <img
          src={`${imageSrc}`}
          alt="Full size"
          className="object-contain"
        />
        {/* Extend the button across the bottom of the modal */}
        <div className="mt-auto w-full">
          <button 
            onClick={onClose}
            className="w-full text-lg text-white bg-red-500 hover:bg-red-700 focus:outline-none py-3 rounded-b-lg"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );  
};

export default ImageModal;