import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { useUser } from "../../context/UserContext";
import { FaBirthdayCake, FaCross, FaPlusCircle, FaMapMarkerAlt, FaGlobe, FaThList, FaBox, FaExclamationTriangle, FaCogs, FaCalendarAlt, FaChurch } from 'react-icons/fa';
import useInitiateVisit from "../../hooks/useInitiateVisit";
import LoadingScreen from "../../components/LoadingScreen";
import BackButton from "../../components/BackButton";
import ToggleSwitch from "../../components/ToggleSwitch";

const GravestoneProfile = () => {
  const { id } = useParams(); // ID of the gravestone
  const [visits, setVisits] = useState([]);
  const [gravestone, setGravestone] = useState(null);
  const [activeTab, setActiveTab] = useState("info"); // 'info' for gravestone information, 'visits' for visits timeline
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [width, setWidth] = useState(75);

  const initiateVisit = useInitiateVisit();

  const { user } = useUser();

  const apiURL = process.env.REACT_APP_API_BASE_URL || "";
  const mainURL = process.env.REACT_APP_MAIN_URL || "";

  const handleActiveToggle = async (newState) => {
    console.log('New state:', newState);
    try {
      const response = await axios.put(`${apiURL}/api/gravestones/${gravestone.id}/active`, { active: newState });
      if (response.data && response.data.message) {
        console.log(response.data.message);
      }
    } catch (error) {
      console.error('Failed to update active status:', error);
    }
  };

  useEffect(() => {
    // Fetch gravestone details from your API
    fetch(`${apiURL}/api/gravestones/${id}`)
      .then((response) => response.json())
      .then((data) => setGravestone(data))
      .catch((error) =>
        console.error("Failed to load gravestone details:", error)
      );

    const fetchVisits = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(`${apiURL}/api/visits/gravestone/${id}`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        console.log(data.visits);
        setVisits(data.visits);
      } catch (error) {
        console.error("Failed to fetch visits:", error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      fetchVisits();
    }
    console.log('Gravestone:', gravestone);
  }, [id]);

  // Function to render gravestone information
  const renderGravestoneInfo = () => (
    <div className="max-w-full mx-auto mt-10 px-0 lg:px-4">
      <div className="bg-white shadow-lg rounded-lg overflow-hidden grid grid-cols-1 md:grid-cols-3 gap-6 p-6">
        {/* Image Section */}
        <div className="relative flex flex-col items-center">
          <img
            src={gravestone?.picture ? `${mainURL}${gravestone.picture}` : "/images/default_grave.jpg"}
            alt="Gravsteinsbilde"
            className="object-cover w-full rounded-lg"
          />
          <button
            onClick={() => initiateVisit(gravestone?.id)}
            className="absolute bottom-4 right-4 bg-green-grave text-white px-4 py-2 rounded-full text-sm shadow-md hover:bg-green-600 transition flex items-center justify-center"
            style={{ zIndex: 10 }} // Ensure the button is above the image
          >
            <svg className="h-5 w-5 mr-1" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4"></path>
            </svg>
            Nytt Besøk
          </button>
        </div>
  
        {/* Details Section */}
        <div className="p-6 bg-white flex flex-col justify-between">
          <div className="flex justify-between items-center mb-4">
            <h2 className="font-semibold text-2xl text-gray-800">Gravstein Info</h2>
            <Link
  to={`/gravsteiner/${gravestone?.id}/endre`}
  className="bg-green-grave text-white px-4 py-2 rounded-full text-sm shadow-md hover:bg-green-600 transition"
>
  Rediger
</Link>
            {gravestone && 
            <ToggleSwitch initialState={gravestone?.active} onToggle={handleActiveToggle} />}
          </div>
          <div className="space-y-4 text-lg">
            <div className="bg-gray-50 p-4 rounded-lg shadow-sm flex items-center">
              <span className="font-bold text-gray-700 flex items-center">
                <FaMapMarkerAlt className="mr-2 text-green-grave" />
                Plassering:
              </span>
              <span className="ml-2 text-gray-600">{gravestone?.location || "Ukjent"}</span>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg shadow-sm flex items-center">
              <span className="font-bold text-gray-700 flex items-center">
                <FaGlobe className="mr-2 text-green-grave" />
                Koordinater:
              </span>
              <span className="ml-2 text-gray-600">{gravestone?.latitude}, {gravestone?.longitude}</span>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg shadow-sm flex items-center">
              <span className="font-bold text-gray-700 flex items-center">
                <FaThList className="text-green-grave" />
              </span>
              <span className="ml-2 text-gray-600">
                <span className="font-bold">Felt: </span>{gravestone?.field || "Ukjent"}{' '}
                <span className="font-bold">Rad: </span>{gravestone?.row || "Ukjent"}{' '}
                <span className="font-bold">Plass: </span>{gravestone?.place || "Ukjent"}
              </span>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg shadow-sm flex items-start">
              <span className="font-bold text-gray-700 flex items-center">
                <FaBox className="mr-2 text-green-grave" />
                Produkter:
              </span>
              <div className="ml-2 flex flex-wrap gap-2">
                {gravestone?.productDetails && gravestone.productDetails.length > 0
                  ? gravestone.productDetails.map((product) => (
                      <span key={product.id} className="bg-green-grave text-white px-2 py-1 rounded-full text-sm shadow-sm">
                        {product.name}
                      </span>
                    ))
                  : "Ingen"}
              </div>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg shadow-sm flex items-center">
              <span className="font-bold text-gray-700 flex items-center">
                <FaExclamationTriangle className="mr-2 text-orange-grave" />
                Advarsel:
              </span>
              <span className="ml-2 text-gray-600">{gravestone?.warning || "Ingen"}</span>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg shadow-sm flex items-center">
              <span className="font-bold text-gray-700 flex items-center">
                <FaCogs className="mr-2 text-green-grave" />
                Automatisk:
              </span>
              <span className="ml-2 text-gray-600">{gravestone?.is_auto ? "Ja" : "Nei"}</span>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg shadow-sm flex items-center">
              <span className="font-bold text-gray-700 flex items-center">
                <FaCalendarAlt className="mr-2 text-green-grave" />
                Dager mellom besøk:
              </span>
              <span className="ml-2 text-gray-600">{gravestone?.days_between_visits || "Ikke spesifisert"}</span>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg shadow-sm flex items-center">
              <span className="font-bold text-gray-700 flex items-center">
                <FaChurch className="mr-2 text-green-grave" />
                Kirkegård:
              </span>
              <span className="ml-2 text-gray-600">{gravestone?.graveyard_name || "Ukjent"}</span>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg shadow-sm flex items-center">
              <span className="font-bold text-gray-700 flex items-center">
                <FaGlobe className="mr-2 text-green-grave" />
                Sist Besøkt:
              </span>
              <span className="ml-2 text-gray-600">{new Date(gravestone?.latest_visit_date).toLocaleString('nb-NO')}</span>
            </div>
          </div>
        </div>
  
        {/* Graveperson Cards Section */}
        <div className="p-6 bg-white flex flex-col">
          <h2 className="font-semibold text-2xl mb-4 text-gray-800">Gravpersoner</h2>
          <div className="grid grid-cols-1 gap-4">
            {gravestone?.gravepersons.map((person, index) => (
              <Link to={`/gravpersoner/${person.id}`} key={index} className="block">
                <div className="bg-gray-100 rounded-lg shadow-lg p-4 flex flex-col justify-between hover:shadow-xl transition-shadow duration-300">
                  <div>
                    <h3 className="font-semibold text-lg text-gray-800">{person.name}</h3>
                    <p className="text-sm text-gray-600 flex items-center mt-2">
                      <FaBirthdayCake className="text-green-grave mr-2" />
                      {person.birthdate ? new Date(person.birthdate).toLocaleDateString('nb-NO') : 'Ukjent fødselsdato'}
                    </p>
                    <p className="text-sm text-gray-600 flex items-center mt-2">
                      <FaCross className="text-orange-grave mr-2" />
                      {person.deathdate ? new Date(person.deathdate).toLocaleDateString('nb-NO') : 'Ukjent dødsdato'}
                    </p>
                  </div>
                </div>
              </Link>
            ))}
            {/* Add Graveperson Button */}
            {user.type === "admin" && (
              <Link to={`/gravpersoner/ny/${id}`} className="flex justify-center items-center bg-green-grave hover:bg-orange-grave text-white rounded-lg shadow-lg p-4 transition-colors duration-300">
                <FaPlusCircle className="text-2xl" />
                <span className="ml-2">Legg til gravperson</span>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  const groupVisitsByYear = (visits) => {
    return visits.reduce((acc, visit) => {
      const year = new Date(visit.visit_date).getFullYear();
      if (!acc[year]) {
        acc[year] = [];
      }
      acc[year].push(visit);
      return acc;
    }, {});
  };
  
  const renderVisitsTimeline = () => {
    const visitsByYear = groupVisitsByYear(visits);
  
    const handleWidthChange = (event) => {
      setWidth(event.target.value);
    };
  
    return (
      <div>
        <div className="max-w-lg mx-auto p-4 flex items-center space-x-4">
          <label htmlFor="width-slider" className="text-sm font-medium text-gray-900 whitespace-nowrap">Endre størrelse:</label>
          <input
            id="width-slider"
            type="range"
            min="50"
            max="100"
            value={width}
            onChange={handleWidthChange}
            className="flex-grow h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
          />
          <span className="text-sm font-medium text-gray-900">{width}%</span>
        </div>
        <div className="mx-auto p-4" style={{ width: `${width}%` }}>
          <div className="relative border-l-4 border-green-grave pl-4">
            {Object.keys(visitsByYear)
              .sort((a, b) => b - a) // Sort years in descending order
              .map((year) => (
                <div key={year} className="mb-10">
                  <div className="flex items-center mb-4">
                    <div className="flex-shrink-0 h-12 px-4 rounded-full bg-green-grave flex items-center justify-center text-white text-lg font-bold">
                      {year} ({visitsByYear[year].length} Besøk)
                    </div>
                  </div>
                  {visitsByYear[year].map((visit) => (
                    <div key={visit.id} className="mb-10 ml-12 relative">
                      <div className="flex items-center mb-4">
                        <Link to={visit.is_legacy ? `/besok/${visit.id}/legacy` : `/besok/${visit.id}`} className="flex-shrink-0 h-10 px-4 rounded-full bg-green-grave flex items-center justify-center text-white font-bold hover:bg-orange-grave">
                          {new Date(visit.visit_date).toLocaleString("nb-NO")}
                        </Link>
                        <div className="flex-grow border-t-2 border-green-grave ml-4"></div>
                        <div className="flex-shrink-0 pl-4">
                          <div className="text-green-grave">
                            <span className="text-md font-semibold">
                              Besøkt av <Link to={'/brukere/' + visit.user_id} className="hover:underline hover:text-orange-grave">{visit.user_name}</Link>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="flex space-x-4">
                        {[visit.before_picture_url, visit.after_picture_url].map((picture_url, i) => (
                          <div key={i} className="relative w-1/2">
                            <img
                              src={picture_url ? mainURL + picture_url : "/images/default_grave.jpg"}
                              alt={i === 0 ? "Before" : "After"}
                              className="object-cover rounded-lg shadow-lg w-full h-full"
                            />
                            {user.type === "admin" && (
                              <div className="absolute inset-0 bg-black bg-opacity-25 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300">
                                <button
                                  className="px-4 py-2 bg-green-grave text-white rounded hover:bg-orange-grave"
                                  onClick={() => handleSetMainImage(picture_url)}
                                >
                                  Bruk som hovedbilde
                                </button>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                      <div className="mt-4 p-4 bg-gray-50 rounded-lg shadow-md">
  <div className="text-sm text-gray-800">
    <strong className="block mb-1 text-green-grave">Arbeid Utført:</strong>
    <div className="flex flex-wrap mt-2">
      {visit.activities.map((activity, index) => (
        <span key={index} className="bg-green-grave text-white px-3 py-1 rounded-full text-md font-bold cursor-default hover:bg-orange-grave mr-2 mb-2 shadow-sm">
          {activity}
        </span>
      ))}
    </div>
  </div>
  <div className="text-sm text-gray-800 mt-4">
    <strong className="block mb-1 text-green-grave">Kommentar:</strong>
    <p className="bg-white p-3 rounded-lg shadow-inner">{visit.description}</p>
  </div>
</div>
                    </div>
                  ))}
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  };

  // Handler function for updating the main image
  const handleSetMainImage = async (imageUrl) => {
    try {
      const response = await fetch(`/api/gravestones/${id}/picture`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ pictureUrl: imageUrl }),
      });
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Error updating main image');
      }

      // Success feedback
      console.log('Main image updated successfully:', data);
      alert('Hovedbilde oppdatert!');
    } catch (error) {
      console.error('Error:', error);
      alert('Feil ved oppdatering av hovedbilde.');
    }
  };

  if (isLoading) return <LoadingScreen />;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="p-4 mt-8 sm:mt-0">
      <BackButton />
      <div className="mb-4">
        {/* Tab selection */}
        <button
          className={`mr-4 ${
            activeTab === "info" ? "text-green-grave font-bold" : "text-gray-600"
          }`}
          onClick={() => setActiveTab("info")}
        >
          Gravstein
        </button>
        <button
          className={`mr-4 ${
            activeTab === "visits"
              ? "text-green-grave font-bold"
              : "text-gray-600"
          }`}
          onClick={() => setActiveTab("visits")}
        >
          Besøk
        </button>
      </div>
      {/* Content based on active tab */}
      {activeTab === "info" && renderGravestoneInfo()}
      {activeTab === "visits" && renderVisitsTimeline()}
    </div>
  );
};

export default GravestoneProfile;