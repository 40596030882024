// components/ReusableSubheader.js
import React from 'react';
import { Link } from 'react-router-dom';
import { FaQuestionCircle } from 'react-icons/fa';

const ReusableSubheader = ({ title, linkTo, linkText, alignment = 'left', info }) => {
  const alignmentClasses = {
    left: 'text-left',
    center: 'text-center',
    right: 'text-right'
  };

  return (
    <div className={`flex items-center justify-between ${alignmentClasses[alignment]}`}>
      <h3 className="text-xl font-semibold">
        {title}
        {info && (
          <div className="relative group inline-block ml-2">
            <FaQuestionCircle className="text-gray-800 cursor-pointer" />
            <div className="opacity-0 w-64 bg-gray-800 text-white text-xs rounded-lg py-2 px-3 absolute z-10 group-hover:opacity-100 bottom-full left-1/2 transform -translate-x-1/2 pointer-events-none">
              {info}
              <svg className="absolute text-gray-800 h-2 w-full left-0 top-full" viewBox="0 0 255 255">
                <polygon className="fill-current" points="0,0 127.5,127.5 255,0"></polygon>
              </svg>
            </div>
          </div>
        )}
      </h3>
      {linkTo && (
        <Link to={linkTo}>
          <span className="bg-gray-800 text-white px-2 hover:bg-green-grave cursor-pointer rounded-full">
            {linkText || '+'}
          </span>
        </Link>
      )}
    </div>
  );
};

export default ReusableSubheader;