import React from 'react';
import { FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useAdminView } from '../../context/AdminViewContext';
import { useCustomer } from '../../context/CustomerContext';

const AdminBar = () => {
  const { exitAdminView } = useAdminView();
  const { customer } = useCustomer();
  const navigate = useNavigate();

  const handleExitAdminView = () => {
    exitAdminView(); // Exit admin view first
    navigate(`/kunder/${customer.id}`); // Navigate back to the previous page
  };

  return (
    <div className="bg-gray-900 text-white p-4 flex justify-between items-center">
      <span className='font-bold'>Viser kundeportal som: {customer.name}</span>
      <button
        onClick={handleExitAdminView}
        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition"
      >
        <FaTimes className="mr-2 inline-block" /> Avslutt kundevisning
      </button>
    </div>
  );
};

export default AdminBar;