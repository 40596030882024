import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useNotifications } from '../../context/NotificationContext';
import { FaEdit, FaSave, FaTimes } from 'react-icons/fa';
import { is } from 'date-fns/locale';

const EditDepartment = () => {
  const { id } = useParams();
  const [department, setDepartment] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const { addNotification } = useNotifications();
  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  useEffect(() => {
    axios.get(`${apiURL}/api/departments/${id}`)
      .then(response => {
        setDepartment(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, [id, apiURL, isEditing]);

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleSave = async () => {
    try {
      await axios.put(`${apiURL}/api/departments/${id}`, department);
    addNotification({ message: 'Avdelingen ble oppdatert', type: 'success' });
      setIsEditing(false);
    } catch (error) {
      console.error(error);
      addNotification({ message: error.message || 'An error occurred', type: 'error' });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDepartment(prevDept => ({ ...prevDept, [name]: value }));
  };

  return (
    <div className="max-w-2xl mx-auto p-8 bg-white rounded-lg shadow-lg">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold text-gray-800">{isEditing ? 'Endre Avdeling' : 'Vis Avdeling'}</h1>
        {isEditing ? (
          <div>
            <button
              onClick={handleSave}
              className="bg-green-grave text-white p-2 rounded-lg shadow-md hover:bg-orange-grave transition duration-150 ease-in-out mr-2"
            >
              <FaSave />
            </button>
            <button
              onClick={handleEditToggle}
              className="bg-red-500 text-white p-2 rounded-lg shadow-md hover:bg-red-600 transition duration-150 ease-in-out"
            >
              <FaTimes />
            </button>
          </div>
        ) : (
          <button
            onClick={handleEditToggle}
            className="bg-green-grave text-white p-2 rounded-lg shadow-md hover:bg-orange-grave transition duration-150 ease-in-out"
          >
            <FaEdit />
          </button>
        )}
      </div>
      {isEditing ? (
        <form className="space-y-6">
          <div>
            <label htmlFor="name" className="block text-lg font-medium text-gray-700">Navn:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={department.name || ''}
              onChange={handleChange}
              className="mt-2 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 transition duration-150 ease-in-out"
              placeholder="Legg til navn"
            />
          </div>
          <div>
            <label htmlFor="description" className="block text-lg font-medium text-gray-700">Beskrivelse:</label>
            <textarea
              id="description"
              name="description"
              value={department.description || ''}
              onChange={handleChange}
              className="mt-2 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 transition duration-150 ease-in-out"
              placeholder="Legg til beskrivelse"
            ></textarea>
          </div>
          <div>
            <label htmlFor="active" className="block text-lg font-medium text-gray-700">Aktiv?</label>
            <select
              id="active"
              name="active"
              value={department.active || 0}
              onChange={handleChange}
              className="mt-2 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 transition duration-150 ease-in-out"
            >
              <option value={1}>Ja</option>
              <option value={0}>Nei</option>
            </select>
          </div>
        </form>
      ) : (
        <div className="space-y-6">
          <div>
            <p className="text-lg font-medium text-gray-700">Navn:</p>
            <p className="text-lg text-gray-800">{department.name}</p>
          </div>
          <div>
            <p className="text-lg font-medium text-gray-700">Beskrivelse:</p>
            <p className="text-lg text-gray-800">{department.description}</p>
          </div>
          <div>
            <p className="text-lg font-medium text-gray-700">Aktiv?</p>
            <p className="text-lg text-gray-800">{department.active ? 'Ja' : 'Nei'}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditDepartment;