import React, { useState, useEffect } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import { Link } from 'react-router-dom';
import { FaCommentDots } from 'react-icons/fa';

const NewCommentsList = ({ customer_id }) => {
  const [comments, setComments] = useState([]);
  const [visibleCount, setVisibleCount] = useState(5);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUnseenComments = async () => {
      try {
        const response = await axiosInstance.get('/api/visit_comments/unseen_for_customer', {
          params: { customer_id }
        });
        setComments(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching unseen comments:', error);
        setError('Kunne ikke laste kommentarer');
        setLoading(false);
      }
    };

    fetchUnseenComments();
  }, [customer_id]);

  const handleLoadMore = () => {
    setVisibleCount((prevCount) => prevCount + 5);
  };

  if (loading) return <p className="text-center text-gray-500">Laster...</p>;
  if (error) return <p className="text-center text-red-500">{error}</p>;

  return (
    <div className="new-comments-list max-h-96 overflow-y-auto w-96"> {/* Made the container wider */}
      <h3 className="text-lg font-semibold mb-4 flex items-center space-x-2">
        <FaCommentDots className="text-green-grave" />
        <span>Nye kommentarer</span>
      </h3>
      {comments.length === 0 ? (
        <p className="text-center text-gray-500">Ingen nye kommentarer</p>
      ) : (
        <>
          {comments.slice(0, visibleCount).map((comment) => (
            <Link
              key={comment.id}
              to={`/kunde/besok/${comment.visit_id}`}
              className="block mb-4 border-b border-gray-200 pb-2 hover:bg-gray-100 transition duration-300 rounded-lg p-2"
            >
              <div className="text-gray-700">
                Du har en ny kommentar på besøk: <span className="text-green-grave font-semibold">{comment.visit_id}</span>
              </div>
              <div className="text-gray-500 text-sm">{new Date(comment.created_at).toLocaleString('nb-NO')}</div>
            </Link>
          ))}
          {visibleCount < comments.length && (
            <button
              onClick={handleLoadMore}
              className="w-full mt-4 px-4 py-2 bg-green-grave text-white rounded-full shadow-lg hover:bg-green-800 transition duration-300"
            >
              Vis flere
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default NewCommentsList;