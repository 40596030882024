// SpecialDaysManager.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axiosInstance from '../../utils/axiosInstance';
import { FaCalendarAlt, FaEdit, FaPlus, FaCheck, FaTrash, FaBirthdayCake, FaHeart, FaChild, FaGift, FaUsers, FaQuestionCircle, FaHeartBroken, FaHandHoldingHeart } from 'react-icons/fa';
import ReusableSubheader from '../ReusableSubheader';
import SpecialDayModal from './SpecialDayModal';
import SpecialDaysList from './SpecialDaysList';

const specialDayIcons = {
  'Fødselsdag': FaBirthdayCake,
  'Dødsdag': FaHeartBroken,
  'Bryllupsdag': FaHeart,
  'Personlige milepæler': FaGift,
  'Familiegjenforeningsdatoer': FaUsers,
  'Annet': FaQuestionCircle
};

const SpecialDaysManager = ({ gravestoneId }) => {
  const [specialDays, setSpecialDays] = useState([]);
  const [newSpecialDay, setNewSpecialDay] = useState({
    date: '',
    description: '',
    active: false,
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  useEffect(() => {
    const fetchSpecialDays = async () => {
      try {
        const response = await axiosInstance.get(`/api/special-days/gravestone/${gravestoneId}`);
        setSpecialDays(response.data);
      } catch (err) {
        console.error('Error fetching special days:', err);
      }
    };

    fetchSpecialDays();
  }, [gravestoneId, apiURL]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewSpecialDay((prevDay) => ({
      ...prevDay,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleAddSpecialDay = async (newDay) => {
    try {
      const response = await axiosInstance.post(`/api/special-days`, {
        ...newDay,
        gravestone_id: gravestoneId,
      });
      const addedSpecialDay = response.data;
      setSpecialDays([...specialDays, { ...newDay, id: addedSpecialDay.id, gravestone_id: gravestoneId }]);
      setNewSpecialDay({ date: '', description: '', active: false });
      setModalIsOpen(false); // Close modal on successful submission
    } catch (err) {
      console.error('Error adding special day:', err);
    }
  };

  const handleDeleteSpecialDay = async (id) => {
    try {
      await axiosInstance.delete(`/api/special-days/${id}`);
      setSpecialDays(specialDays.filter((day) => day.id !== id));
    } catch (err) {
      console.error('Error deleting special day:', err);
    }
  };

  const handleSetActiveSpecialDay = async (id) => {
    try {
      await axiosInstance.put(`/api/special-days/set-active/${id}`, { gravestone_id: gravestoneId });
      setSpecialDays(specialDays.map((day) => ({
        ...day,
        active: day.id === id,
      })));
    } catch (err) {
      console.error('Error setting active special day:', err);
    }
  };

  return (
    <div className="mt-6">
      <ReusableSubheader title="Friske Blomster" info="Dersom du har bestilt friske blomster på gravstedet angir dette dagen hvor vi setter ut blomsterbukett på gravstedet." />
      <button
        onClick={() => setModalIsOpen(true)}
        className="w-full bg-green-grave text-white px-4 py-2 rounded hover:bg-green-600 transition mb-6"
      >
        <FaPlus className="mr-2 inline-block" /> Legg til Spesiell Dag
      </button>

      <SpecialDaysList
        specialDays={specialDays}
        specialDayIcons={specialDayIcons}
        handleSetActiveSpecialDay={handleSetActiveSpecialDay}
        handleDeleteSpecialDay={handleDeleteSpecialDay}
      />
      <SpecialDayModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        newSpecialDay={newSpecialDay}
        handleChange={handleChange}
        handleAddSpecialDay={handleAddSpecialDay}
        specialDayIcons={specialDayIcons} // Pass specialDayIcons to modal
      />
    </div>
  );
};

export default SpecialDaysManager;