import React, { useState, useEffect } from 'react';
import axios from 'axios';

const GraveyardPicker = ({ value, onChange }) => {
  const [graveyards, setGraveyards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  useEffect(() => {
    const fetchGraveyards = async () => {
      setLoading(true);
      setError('');

      try {
        const response = await axios.get(`${apiURL}/api/graveyardsWorkorder`);
        setGraveyards(response.data);
      } catch (err) {
        setError('Failed to load graveyards.');
      } finally {
        setLoading(false);
      }
    };

    fetchGraveyards();
  }, []);

  return (
    <div>
      {loading ? (
        <div className="text-center p-2">Laster kirkegårder...</div>
      ) : error ? (
        <div className="text-center text-red-500 p-2">{error}</div>
      ) : (
        <select
          value={value}
          onChange={onChange}
          className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          <option value="">Velg kirkegård...</option>
          {graveyards.map((graveyard) => (
            <option key={graveyard.id} value={graveyard.id}>
              {graveyard.name}
            </option>
          ))}
        </select>
      )}
    </div>
  );
};

export default GraveyardPicker;