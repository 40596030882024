// src/constants/responseMessages.js

export const positiveFeedbackResponses = [
    'Hei! Så hyggelig å høre at du er fornøyd! Det betyr utrolig mye for oss. Tusen takk for at du tok deg tid til å gi oss tilbakemelding. Vi setter veldig stor pris på det!',
    'Hei! Det er så godt å høre at du er fornøyd! Det betyr utrolig mye for oss. Tusen takk for at du tok deg tid til å gi oss en tilbakemelding. Vi verdsetter det virkelig!',
    'Hei! Så flott å høre at du er fornøyd! Det betyr mye for oss. Tusen takk for at du tok deg tid til å gi tilbakemelding. Vi setter stor pris på det!',
    'Hei! Så glad vi ble for å høre at du er fornøyd! Det betyr mye for oss. Tusen takk for at du tok deg tid til å gi oss en tilbakemelding. Vi setter virkelig pris på det!',
    'Hei! Vi setter stor pris på å høre at du er fornøyd! Det betyr mye for oss. Tusen takk for at du tok deg tid til å gi oss tilbakemelding. Vi verdsetter det høyt!',
    'Hei! Tusen takk for din tilbakemelding. Det gleder oss å høre at du er fornøyd! Vi setter stor pris på at du tok deg tid til å dele din opplevelse.',
    'Hei! Din tilbakemelding varmer! Tusen takk for at du tok deg tid til å fortelle oss at du er fornøyd. Vi setter enormt stor pris på det!',
    'Hei! Så hyggelig at du er fornøyd! Din tilbakemelding betyr mye for oss, og vi setter stor pris på at du tok deg tid til å dele den.',
    'Hei! Vi er glade for at du er fornøyd! Tusen takk for din tilbakemelding. Det betyr mye for oss at du tok deg tid til å skrive til oss.',
    'Hei! Det betyr mye for oss å høre at du er fornøyd. Tusen takk for din tilbakemelding, og for at du tok deg tid til å dele den med oss.',
    'Hei! Så flott at du er fornøyd! Din tilbakemelding er viktig for oss. Tusen takk for at du tok deg tid til å gi oss beskjed!',
    'Hei! Tusen takk for din tilbakemelding! Det er fantastisk å høre at du er fornøyd. Vi setter stor pris på at du tok deg tid til å dele din opplevelse.',
    'Hei! Vi blir så glade for å høre at du er fornøyd! Tusen takk for din tilbakemelding, vi setter stor pris på den!',
    'Hei! Så hyggelig å få tilbakemelding fra en fornøyd kunde! Det betyr mye for oss. Tusen takk for at du delte din opplevelse!',
    'Hei! Det er flott å høre at du er fornøyd! Tusen takk for at du tok deg tid til å gi oss en tilbakemelding. Vi setter stor pris på det!',
    'Hei! Vi setter utrolig stor pris på din tilbakemelding. Det betyr mye for oss å høre at du er fornøyd. Tusen takk for at du tok deg tid til å dele din erfaring!',
    'Hei! Så fint at du er fornøyd! Din tilbakemelding er veldig viktig for oss. Tusen takk for at du delte den med oss!',
    'Hei! Det gleder oss å høre at du er fornøyd. Tusen takk for din tilbakemelding, det betyr mye for oss!',
    'Hei! Vi setter pris på din tilbakemelding og er glade for at du er fornøyd. Tusen takk for at du tok deg tid til å gi oss beskjed!',
    'Hei! Tusen takk for at du tok deg tid til å gi oss tilbakemelding. Det betyr mye for oss å høre at du er fornøyd!',
  ];

  export const apologeticResponses = [
    'Hei! Vi er lei oss for å høre at du ikke er fornøyd med besøket. Vi tar dette på alvor og vil gjøre vårt beste for å rette opp i det. Tusen takk for at du sa ifra.',
    'Hei! Vi beklager at du ikke er fornøyd med vårt arbeid. Din tilbakemelding er viktig for oss, og vi vil sørge for at situasjonen blir forbedret. Tusen takk for at du ga beskjed.',
    'Hei! Vi beklager på det sterkeste at tjenesten ikke levde opp til forventningene dine. Vi vil undersøke dette nærmere og gjøre nødvendige tiltak for å forbedre oss.',
    'Hei! Vi er oppriktig lei oss for at du har hatt en negativ opplevelse. Vi vil ta tak i dette umiddelbart for å sikre at det ikke skjer igjen.',
    'Hei! Beklager så mye for at opplevelsen din ikke har vært tilfredsstillende. Vi setter pris på at du gir oss beskjed, og vi vil gjøre alt vi kan for å forbedre oss.',
    'Hei! Det er trist å høre at du ikke er fornøyd med besøket. Vi beklager virkelig, og vi vil arbeide hardt for å rette opp i dette.',
    'Hei! Vi forstår at dette ikke var den opplevelsen du forventet. Vi beklager og vil gjøre vårt beste for å forbedre oss fremover. Takk for at du delte dette med oss.',
    'Hei! Beklager så mye for eventuelle problemer du har opplevd. Vi verdsetter din tilbakemelding høyt og vil sørge for å følge opp dette.',
    'Hei! Vi er lei oss for at vårt arbeid ikke levde opp til forventningene dine. Tusen takk for at du gir oss en sjanse til å rette opp i dette. Vi vil gjøre vårt beste for å forbedre oss.',
    'Hei! Vi beklager at du ikke er fornøyd med tjenesten vår denne gangen. Vi tar tilbakemeldingen din på alvor og vil gjøre nødvendige justeringer. Takk for at du sa ifra.',
    'Hei! Vi er oppriktig lei oss for at du ikke er fornøyd med resultatet. Vi vil gjøre alt vi kan for å forbedre oss og sørge for at dette ikke skjer igjen. Takk for tilbakemeldingen.',
    'Hei! Vi beklager at din opplevelse ikke var som forventet. Din tilbakemelding er viktig for oss, og vi vil gjøre vårt beste for å forbedre oss i fremtiden.',
    'Hei! Vi er svært lei oss for at du ikke er fornøyd. Vi tar dette veldig seriøst og vil følge opp for å sørge for at det blir bedre. Tusen takk for at du ga beskjed.',
    'Hei! Vi er lei oss for at du ikke er fornøyd med arbeidet vårt. Vi vil undersøke hva som gikk galt og sørge for at det ikke skjer igjen. Takk for tilbakemeldingen din.',
    'Hei! Vi beklager virkelig at tjenesten vår ikke møtte dine forventninger. Din tilbakemelding er uvurderlig, og vi vil bruke den til å forbedre våre tjenester.',
    'Hei! Beklager at du ikke er fornøyd med hvordan besøket ble utført. Vi vil gjøre vårt ytterste for å rette opp i dette og forbedre våre rutiner. Takk for at du sa ifra.',
    'Hei! Det er leit å høre at du ikke er fornøyd. Vi tar din tilbakemelding seriøst og vil arbeide for å sørge for at dette ikke skjer igjen. Tusen takk for at du informerte oss.',
    'Hei! Vi beklager virkelig at opplevelsen din ikke var tilfredsstillende. Vi vil ta dette videre for å sikre at vi forbedrer oss. Tusen takk for tilbakemeldingen din.',
    'Hei! Vi er svært lei oss for at du ikke er fornøyd. Vi vil ta tak i dette umiddelbart og gjøre vårt beste for å rette opp i situasjonen. Takk for at du sa ifra.',
    'Hei! Vi beklager så mye for at tjenesten ikke møtte dine forventninger. Vi verdsetter din tilbakemelding og vil jobbe for å forbedre oss fremover.',
];

export const empatheticResponses = [
  'Hei! Vi forstår at dette er en vanskelig tid, og vi ønsker å forsikre deg om at vi behandler gravstedet med den største respekt. Takk for at du deler dine tanker med oss.',
  'Hei! Vi setter pris på at du tok deg tid til å gi tilbakemelding. Vi er her for å sørge for at alt blir gjort med omsorg og verdighet, og vi vil gjøre vårt beste for å oppfylle dine ønsker.',
  'Hei! Tusen takk for at du deler dine følelser med oss. Vi ønsker å forsikre deg om at vi gjør alt vi kan for å ivareta gravstedet på en respektfull måte.',
  'Hei! Vi er takknemlige for at du gir oss beskjed. Vi vil sørge for at gravstedet alltid blir behandlet med verdighet og respekt.',
  'Hei! Vi forstår at dette betyr mye for deg, og vi lover å gjøre alt vi kan for å ta vare på gravstedet på en respektfull og omsorgsfull måte.',
  'Hei! Vi vet at dette kan være en vanskelig tid, og vi er her for å sørge for at gravstedet blir tatt hånd om med den største omsorg og respekt.',
  'Hei! Tusen takk for at du deler dette med oss. Vi vil forsikre deg om at vi behandler gravstedet med stor respekt og omtanke.',
  'Hei! Vi forstår hvor viktig dette er for deg, og vi vil gjøre alt vi kan for å sikre at gravstedet alltid blir ivaretatt med den største respekt.',
  'Hei! Vi verdsetter din tilbakemelding og vil sørge for at gravstedet blir tatt vare på med stor omsorg. Takk for at du gir oss muligheten til å gjøre dette for deg.',
  'Hei! Vi ønsker å uttrykke vår dypeste respekt for dine følelser, og vi vil sørge for at gravstedet blir behandlet med den omsorg og verdighet det fortjener.',
  'Hei! Vi forstår at dette er en svært personlig og viktig sak for deg. Vi lover å ivareta gravstedet med den største respekt og omtanke.',
  'Hei! Vi er her for å støtte deg, og vi vil sørge for at gravstedet blir tatt vare på med all den respekt og omsorg som kreves.',
  'Hei! Vi tar dette svært alvorlig og vil gjøre alt vi kan for å sørge for at gravstedet blir ivaretatt på en verdig måte. Takk for at du stoler på oss.',
  'Hei! Vi forstår at gravstedet har stor betydning for deg, og vi lover å behandle det med all den respekt det fortjener.',
  'Hei! Vi vil forsikre deg om at vi behandler gravstedet med stor respekt og omsorg, slik at du kan være trygg på at det blir tatt godt vare på.',
  'Hei! Tusen takk for at du deler dine følelser med oss. Vi lover å gjøre vårt beste for å sikre at gravstedet blir behandlet med den største respekt.',
  'Hei! Vi er takknemlige for at du gir oss beskjed. Vi lover å behandle gravstedet med all den respekt og omsorg som det fortjener.',
  'Hei! Vi forstår at dette betyr mye for deg, og vi er her for å sikre at gravstedet alltid blir ivaretatt på en respektfull måte.',
  'Hei! Vi setter stor pris på din tilbakemelding. Vi vil sørge for at gravstedet blir tatt hånd om med den største omtanke og respekt.',
  'Hei! Vi er her for å sikre at gravstedet blir tatt godt vare på, med all den respekt og omsorg som det fortjener. Takk for at du stoler på oss.',
];