import React, { useState } from 'react';
import axios from 'axios';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const DownloadImagesPage = () => {
  const [progress, setProgress] = useState({
    inProgress: false,
    total: 0,
    completed: 0,
    errors: [],
  });
  const [downloadParams, setDownloadParams] = useState({
    tableName: '',
    firstColumn: '',
    secondColumn: '',
    outputFirstColumn: '',
    outputSecondColumn: '',
  });
  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDownloadParams((prevParams) => ({
      ...prevParams,
      [name]: value,
    }));
  };

  const startDownload = async () => {
    setProgress({ ...progress, inProgress: true });
    try {
      await axios.get(`${apiURL}/download/images`, { params: downloadParams });

      const interval = setInterval(async () => {
        const progressResponse = await axios.get(`${apiURL}/api/download/progress`);
        setProgress({
          ...progress,
          total: progressResponse.data.total,
          completed: progressResponse.data.completed,
          errors: progressResponse.data.errors,
        });
        if (progressResponse.data.completed >= progressResponse.data.total) {
          clearInterval(interval);
          setProgress((prev) => ({ ...prev, inProgress: false }));
        }
      }, 1000);
    } catch (error) {
      console.error('Failed to start image download:', error);
      setProgress((prev) => ({
        ...prev,
        inProgress: false,
        errors: [...prev.errors, error.message],
      }));
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      {!progress.inProgress && (
        <>
          <input
            type="text"
            name="tableName"
            placeholder="Table Name"
            value={downloadParams.tableName}
            onChange={handleInputChange}
            className="input-field"
          />
          {/* Repeat input fields for each parameter */}
          <input
            type="text"
            name="firstColumn"
            placeholder="First Column"
            value={downloadParams.firstColumn}
            onChange={handleInputChange}
            className="input-field"
          />
          <input
            type="text"
            name="outputFirstColumn"
            placeholder="gravestones"
            value={downloadParams.outputFirstColumn}
            onChange={handleInputChange}
            className="input-field"
          />
          <input
            type="text"
            name="secondColumn"
            placeholder="Second Column (Optional)"
            value={downloadParams.secondColumn}
            onChange={handleInputChange}
            className="input-field"
          />
          <input
            type="text"
            name="outputSecondColumn"
            placeholder="Second Column Output"
            value={downloadParams.outputSecondColumn}
            onChange={handleInputChange}
            className="input-field"
          />
        </>
      )}
      <button
        className="mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        onClick={startDownload}
        disabled={progress.inProgress}
      >
        Start Download
      </button>
      <div style={{ width: 200, height: 200, marginBottom: 20 }}>
        <CircularProgressbar
          value={progress.total > 0 ? (progress.completed / progress.total) * 100 : 0}
          text={`${progress.total > 0 ? Math.round((progress.completed / progress.total) * 100) : 0}%`}
          styles={buildStyles({
            textColor: "black",
            pathColor: "turquoise",
            trailColor: "silver",
          })}
        />
      </div>
      {progress.errors.length > 0 && (
        <div className="text-red-500">
          <h3>Errors:</h3>
          <ul>
            {progress.errors.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DownloadImagesPage;