import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../context/UserContext';
import { AiOutlineLogout } from 'react-icons/ai'; // Import the logout icon

const Logout = () => {
  const navigate = useNavigate();
  const { logout } = useUser();

  useEffect(() => {
    logout();
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');

    setTimeout(() => {
      navigate('/logginn');
    }, 1500); // Delay before redirection
  }, [navigate, logout]);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      <AiOutlineLogout size="5rem" className="text-gray-600" /> {/* Logout icon */}
      <h2 className="text-lg font-semibold text-gray-800 mt-4">Logger deg ut...</h2>
      <p className="text-gray-600">Vennligst vent</p>
    </div>
  );
};

export default Logout;