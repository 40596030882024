import React from 'react';
import { useSidebar } from '../context/SidebarContext';
import Sidebar from '../components/Sidebar';

const UserLayout = ({ children }) => {
  const { isOpen } = useSidebar();
  const sidebarStyle = isOpen ? 'lg:ml-52' : 'lg:ml-20';

  return (
    <div className={`flex min-h-screen transition-margin duration-300 ease-in-out ${sidebarStyle}`}>
      <Sidebar />
      <div className="flex-grow">
        {children}
      </div>
    </div>
  );
};

export default UserLayout;