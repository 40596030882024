import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Topbar from "../../components/Topbar";
import Toolbar from "../../components/Toolbar";
import ReusableTable from "../../components/ReusableTable";
import Pagination from "../../components/Pagination";
import Spinner from "../../components/Spinner";
import ReusableSearch from "../../components/ReusableSearch";
import ReusableLimit from "../../components/ReusableLimit";
import ReusableHeader from "../../components/ReusableHeader";
import ReusableDatePicker from "../../components/ReusableDatePicker";
import Modal from "../../components/Modal";
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLogs } from "../../hooks/useLogs";
import { useUser } from "../../context/UserContext";

function VisitsThumbsDown() {
  const [visits, setVisits] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("visit_date");
  const [order, setOrder] = useState("DESC");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [from_date, setFromDate] = useState("");
  const [to_date, setToDate] = useState("");
  const [filter_send_sms, setFilterSendSms] = useState("");
  const [filter_send_email, setFilterSendEmail] = useState("");
  const [totalPages, setTotalPages] = useState(0);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [selectedDateTime, setSelectedDateTime] = useState(null);

  const { createLog } = useLogs();
  const { user } = useUser();

  const [selectedRows, setSelectedRows] = useState([]);

  const apiURL = process.env.REACT_APP_API_BASE_URL || "";

  // Event handlers remain within the parent component
  const handleSearchChange = (e) => setSearch(e.target.value);
  const handleLimitChange = (e) => setLimit(e.target.value);
  const handleFromDateChange = (date) => setFromDate(date);
  const handleToDateChange = (date) => setToDate(date);

  useEffect(() => {
    const fetchVisits = async () => {
      setIsLoading(true);
      try {
        const queryParams = new URLSearchParams({
          search,
          sort,
          order,
          page,
          limit,
          from_date: from_date ? from_date.toISOString() : "",
          to_date: to_date ? to_date.toISOString() : "",
          filter_send_email,
          filter_send_sms,
          filter_thumb_down: 1,
        }).toString();
        from_date && console.log(from_date.toISOString());
        to_date && console.log(to_date.toISOString());
        const response = await fetch(`${apiURL}/api/visits?${queryParams}`);
        if (!response.ok) {
          throw new Error("Something went wrong!");
        }
        const data = await response.json();
        setVisits(data.visits);
        setTotalPages(data.totalPages);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchVisits();
  }, [search, sort, order, page, limit, from_date, to_date, filter_send_email, filter_send_sms]);

  const handleSort = (field) => {
    if (sort === field) {
      setOrder(order === "ASC" ? "DESC" : "ASC");
    } else {
      setSort(field);
      setOrder("ASC");
    }
  };

  const columns = [
    { accessor: "id", type: "toggle", width: '50px' },
    { label: "Besøkt", accessor: "visit_date", type: "date", link: (row) => `/besok/${row.id}/detaljer/${row.is_legacy}`, width: '150px' },
    {
      label: "Gravstein",
      accessor: "gravepersons",
      type: "names",
      link: (row) => `/gravsteiner/${row.gravestone_id}`,
      width: '200px'
    },
    {
      label: "Kirkegård",
      accessor: "graveyard_name",
      link: (row) => `/kirkegarder/${row.graveyard_id}`,
      width: '150px'
    },
    { label: "Aktiviteter", accessor: "activities", type: "span", width: '200px' },
    {
      label: "Arbeider",
      accessor: "user_name",
      link: (row) => `/brukere/${row.user_id}`,
      width: '150px'
    },
    { label: "Legacy?", accessor: "is_legacy", type: "boolean", width: '100px' }
  ];

  const handleRowSelectionChange = (rowId, rowIsLegacy, isSelected) => {
    console.log(`Row ${rowId} is ${isSelected ? "selected" : "deselected"} (isLegacy: ${rowIsLegacy})`);
    setSelectedRows((prevSelectedRows) => {
        if (isSelected) {
            // Add the new selection
            return [...prevSelectedRows, { rowId, rowIsLegacy }];
        } else {
            // Remove the selection
            return prevSelectedRows.filter(
                (row) => row.rowId !== rowId || row.rowIsLegacy !== rowIsLegacy
            );
        }
    });
};

  const handleOpenEmailModal = () => {
    setModalContent("email");
    setModalOpen(true);
  };

  const handleOpenSmsModal = () => {
    setModalContent("sms");
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

const isSelected = (rowId, rowIsLegacy) => {
  return selectedRows.some(
      (row) => row.rowId === rowId && row.rowIsLegacy === rowIsLegacy
  );
};

  if (error)
    return <div className="text-red-500 text-center">Error: {error}</div>;

  return (
    <div className="overflow-x-auto p-4 relative">
      <Topbar>
  <ReusableHeader title="Besøk" />
  <Toolbar>
    <ReusableLimit value={limit} onChange={handleLimitChange} />
    <ReusableDatePicker
      selectedDate={from_date}
      onChange={handleFromDateChange}
      label="Fra dato"
      name="from_date"
    />
    <ReusableDatePicker
      selectedDate={to_date}
      onChange={handleToDateChange}
      label="Til dato"
      name="to_date"
    />
    <div className="flex space-x-4 items-center">
      {/* Toggle for Send Email filter */}
      <label className="flex items-center space-x-2">
        <input
          type="checkbox"
          checked={filter_send_email === 'false'}
          onChange={(e) =>
            setFilterSendEmail(e.target.checked ? 'false' : '')
          }
        />
        <span>Vis ikke-sendte e-poster</span>
      </label>

      {/* Toggle for Send SMS filter */}
      <label className="flex items-center space-x-2">
        <input
          type="checkbox"
          checked={filter_send_sms === 'false'}
          onChange={(e) =>
            setFilterSendSms(e.target.checked ? 'false' : '')
          }
        />
        <span>Vis ikke-sendte SMS</span>
      </label>
    </div>
    <ReusableSearch value={search} onChange={handleSearchChange} />
  </Toolbar>
</Topbar>
      {isLoading && <Spinner />}
      <ReusableTable
        columns={columns}
        data={visits}
        sort={{ field: sort, order: order }}
        onSort={handleSort}
        isSelected={isSelected}
        handleRowSelectionChange={handleRowSelectionChange}
      />
      <Pagination
        totalPages={totalPages}
        currentPage={page}
        setPage={setPage}
      />
    </div>
  );
}

export default VisitsThumbsDown;
