// UserGuidePage.jsx
import React from 'react';
import UserGuide from '../components/UserGuide'; // Ensure the path is correct
import BackButton from '../components/BackButton';

const UserGuidePage = () => {
  return (
    <div className="user-guide-page mt-8">
        <BackButton />
      <UserGuide />
    </div>
  );
};

export default UserGuidePage;