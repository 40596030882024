// SpecialDayModal.js
import React, { useState } from 'react';
import { FaCalendarAlt, FaEdit, FaPlus } from 'react-icons/fa';

const specialDayOptions = [
  'Fødselsdag',
  'Dødsdag',
  'Bryllupsdag',
  'Personlige milepæler',
  'Familiegjenforeningsdatoer',
  'Annet'
];

const SpecialDayModal = ({ modalIsOpen, setModalIsOpen, handleAddSpecialDay, newSpecialDay, handleChange, specialDayIcons }) => {
  const [isCustomDescription, setIsCustomDescription] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const finalDescription = isCustomDescription ? newSpecialDay.customDescription : newSpecialDay.description;
    handleAddSpecialDay({ ...newSpecialDay, description: finalDescription });
  };

  return (
    modalIsOpen && (
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
        <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
          <h2 className="text-2xl font-bold mb-4">Legg til Spesiell Dag</h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="date">
                <span className="flex items-center"><FaCalendarAlt className="mr-2" /> Dato</span>
              </label>
              <input
                id="date"
                name="date"
                type="date"
                value={newSpecialDay.date}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
                <span className="flex items-center"><FaEdit className="mr-2" /> Beskrivelse</span>
              </label>
              <select
                id="description"
                name="description"
                value={newSpecialDay.description}
                onChange={(e) => {
                  handleChange(e);
                  setIsCustomDescription(e.target.value === 'Annet');
                }}
                className="w-full px-4 py-2 border rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              >
                <option value="" disabled>Velg en spesiell dag</option>
                {specialDayOptions.map((option) => {
                  const Icon = specialDayIcons[option];
                  return (
                    <option key={option} value={option}>
                      {Icon && <Icon className="inline-block mr-2" />} {option}
                    </option>
                  );
                })}
              </select>
            </div>
            {isCustomDescription && (
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="customDescription">
                  <span className="flex items-center"><FaEdit className="mr-2" /> Spesifiser Beskrivelse</span>
                </label>
                <input
                  id="customDescription"
                  name="customDescription"
                  type="text"
                  value={newSpecialDay.customDescription}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
            )}
            <button
              type="submit"
              className="w-full bg-green-grave text-white px-4 py-2 rounded hover:bg-green-600 transition"
            >
              <FaPlus className="mr-2 inline-block" /> Legg til Spesiell Dag
            </button>
            <button
              type="button"
              onClick={() => setModalIsOpen(false)}
              className="w-full bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition mt-2"
            >
              Avbryt
            </button>
          </form>
        </div>
      </div>
    )
  );
};

export default SpecialDayModal;