import React from 'react';
import { Link } from 'react-router-dom';

const TableView = ({ visits, getPictureUrl }) => (
  <div className='overflow-x-auto'>
    <table className='min-w-full bg-white'>
      <thead>
        <tr>
          <th className='px-4 py-2 border'>Bilde</th>
          <th className='px-4 py-2 border'>Navn</th>
          <th className='px-4 py-2 border'>Besøksdato</th>
          <th className='px-4 py-2 border'>Utført Arbeid</th>
          <th className='px-4 py-2 border'>Kommentar</th>
          <th className='px-4 py-2 border'>Kirkegård</th>
        </tr>
      </thead>
      <tbody>
        {visits.map((visit) => (
          <tr key={visit.id} className='hover:bg-gray-100 transition-colors'>
            <td className='px-4 py-2 border'>
              <Link to={'/kunde/besok/' + visit.id} className='flex flex-row items-center'>
                <img
                  src={getPictureUrl(visit.is_legacy, visit.after_picture_url)}
                  alt="Visit"
                  className='w-24 h-24 object-cover'
                />
              </Link>
            </td>
            <td className='px-4 py-2 border'>
              <Link to={'/kunde/besok/' + visit.id} className='flex flex-row items-center'>
                {visit.gravepersons || 'Ingen gravstener registrert.'}
              </Link>
            </td>
            <td className='px-4 py-2 border'>
              <Link to={'/kunde/besok/' + visit.id} className='flex flex-row items-center'>
                {new Date(visit.visit_date).toLocaleDateString('nb-NO')}
              </Link>
            </td>
            <td className='px-4 py-2 border'>
              <Link to={'/kunde/besok/' + visit.id} className='flex flex-row items-center flex-wrap gap-2'>
                {visit.activities.split(',').map((activity, index) => (
                  <span key={index} className='bg-green-grave text-white px-2 py-1 rounded-full text-sm'>
                    {activity.trim().replace('V\\u00e5rstell', 'Vårstell')}
                  </span>
                ))}
              </Link>
            </td>
            <td className='px-4 py-2 border'>
              <Link to={'/kunde/besok/' + visit.id} className='flex flex-row items-center'>
                {visit.description || 'Ingen beskrivelse tilgjengelig.'}
              </Link>
            </td>
            <td className='px-4 py-2 border'>
              <Link to={'/kunde/besok/' + visit.id} className='flex flex-row items-center'>
                {visit.graveyard_name}
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default TableView;