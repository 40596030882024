import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useCustomer } from '../../context/CustomerContext';
import Spinner from '../../components/Spinner';
import { useNotifications } from '../../context/NotificationContext';
import Pagination from '../../components/Pagination';
import { FaTh, FaList } from 'react-icons/fa';
import CardView from '../../components/customer-components/CardView';
import TableView from '../../components/customer-components/TableView';
import ReusableSearch from '../../components/ReusableSearch';
import noImage from '../../images/no_image.png';

const CustomerVisits = () => {
  const { customer } = useCustomer();
  const { gravestoneId } = useParams();
  const { addNotification } = useNotifications();
  const [visits, setVisits] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("visit_date");
  const [order, setOrder] = useState("DESC");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [from_date, setFromDate] = useState("");
  const [to_date, setToDate] = useState("");
  const [year, setYear] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [viewMode, setViewMode] = useState('card'); // State to manage view mode
  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  const handleLimitChange = (e) => setLimit(e.target.value);
  const handleFromDateChange = (date) => setFromDate(date);
  const handleToDateChange = (date) => setToDate(date);
  const handleViewModeChange = (mode) => setViewMode(mode);
  const handleSearchChange = (e) => setSearch(e.target.value);

  useEffect(() => {
    if (!customer) return; // Exit if customer isn't available yet

    const fetchVisits = async () => {
      setIsLoading(true);
      setError('');

      try {
        const queryParams = new URLSearchParams({
          customerId: customer.id,
          search,
          sort,
          order,
          page,
          limit,
          from_date: from_date ? new Date(from_date).toISOString() : "",
          to_date: to_date ? new Date(to_date).toISOString() : "",
          year: year || getCurrentYear(),
          gravestoneId: gravestoneId || "", // Include gravestoneId if it exists
        }).toString();

        const response = await fetch(`${apiURL}/api/customer-visits?${queryParams}`);
        const data = await response.json();

        if (!response.ok) {
          setError(data.message || 'An error occurred');
          setIsLoading(false);
          return;
        }

        setVisits(data.visits);
        setTotalPages(data.totalPages);
        setIsLoading(false);
      } catch (err) {
        console.error('Error fetching visits:', err);
        setError('Failed to load visits');
        setIsLoading(false);
      }
    };

    fetchVisits(); // Fetch visits immediately when customer or any of the dependencies change
  }, [customer, apiURL, sort, order, search, page, limit, from_date, to_date, year, gravestoneId]); // Include all relevant dependencies

  const getCurrentYear = () => {
    const date = new Date();
    return date.getFullYear().toString();
  }

  if (error) return <div className="text-red-500 text-center">Error: {error}</div>;

  const getPictureUrl = (isLegacy, picturePath) => {
    if (!picturePath) return noImage;
    return isLegacy ? `https://cms.gravstellerne.no${picturePath}` : `https://dev.gravstellerne.no${picturePath}`;
  };

  return (
    <div className="bg-white shadow-lg rounded-2xl p-8 max-w-6xl w-full border-t-4 border-green-grave">
      <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">Besøk</h1>
      <div className="mb-4 flex justify-between">
        <div>
          <label htmlFor="limit" className="mr-2">Besøk Per Side:</label>
          <select value={limit} onChange={handleLimitChange} className="border p-2 rounded-lg">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
        <ReusableSearch search={search} onChange={handleSearchChange} />
        <div>
          <label htmlFor="from_date" className="mr-2">Fra:</label>
          <input type="date" value={from_date} onChange={(e) => handleFromDateChange(e.target.value)} className="border p-2 rounded-lg mr-4" />
          <label htmlFor="to_date" className="mr-2">Til:</label>
          <input type="date" value={to_date} onChange={(e) => handleToDateChange(e.target.value)} className="border p-2 rounded-lg" />
        </div>
        <div className="flex items-center space-x-2">
          <FaTh className={`cursor-pointer ${viewMode === 'card' ? 'text-green-700' : 'text-gray-500'}`} onClick={() => handleViewModeChange('card')} />
          <FaList className={`cursor-pointer ${viewMode === 'list' ? 'text-green-700' : 'text-gray-500'}`} onClick={() => handleViewModeChange('list')} />
        </div>
      </div>
      {isLoading ? (
        <Spinner />
      ) : visits.length === 0 ? (
        <p className="text-gray-600 text-center">Ingen besøk funnet.</p>
      ) : (
        <>
          {viewMode === 'card' ? (
            <CardView visits={visits} getPictureUrl={getPictureUrl} />
          ) : (
            <TableView visits={visits} getPictureUrl={getPictureUrl} />
          )}
        </>
      )}
      <Pagination totalPages={totalPages} currentPage={page} setPage={setPage} />
    </div>
  );
};

export default CustomerVisits;