import React, { useState } from "react";
import GeneralSettings from "./GeneralSettings";
import CustomerMessageSettings from "./CustomerMessageSettings";
import MapSettings from "./MapSettings";
import NotificationSettings from "./NotificationSettings";
import ContactSettings from "./ContactSettings";
import ScheduledJobs from "./ScheduledJobs";
import UpdatePasswords from "./UpdatePasswords";
import { FaCog, FaCommentAlt, FaMapMarkedAlt, FaBell } from "react-icons/fa";

const tabConfig = [
  { key: "general", label: "Generelt", icon: FaCog, component: GeneralSettings },
  { key: "customerMessage", label: "Kundemelding", icon: FaCommentAlt, component: CustomerMessageSettings },
  { key: "map", label: "Kart", icon: FaMapMarkedAlt, component: MapSettings },
  { key: "notification", label: "Varsler", icon: FaBell, component: NotificationSettings },
  { key: "contact", label: "Kontakt", icon: FaCommentAlt, component: ContactSettings },
  { key: "scheduledJobs", label: "Planlagte jobber", icon: FaCog, component: ScheduledJobs },
];

const Settings = () => {
  const [activeTab, setActiveTab] = useState("general");

  const renderTabContent = () => {
    const activeTabConfig = tabConfig.find((tab) => tab.key === activeTab);
    if (activeTabConfig) {
      const ActiveComponent = activeTabConfig.component;
      return <ActiveComponent />;
    }
    return <GeneralSettings />;
  };

  return (
    <div className="settings-page flex flex-col h-screen bg-gray-100">
      <h1 className="text-4xl font-bold mb-8 p-4 bg-white shadow-md">
        Innstillinger
      </h1>
      <div className="tabs flex space-x-4 px-6">
        {tabConfig.map((tab) => (
          <button
            key={tab.key}
            onClick={() => setActiveTab(tab.key)}
            className={`tab ${
              activeTab === tab.key
                ? "bg-green-grave text-white"
                : "bg-gray-200 text-gray-700"
            } flex items-center space-x-2 p-2 rounded-lg transition-all duration-300`}
          >
            <tab.icon className="text-xl" />
            <span className="text-lg">{tab.label}</span>
          </button>
        ))}
      </div>
      <div className="tab-content flex-1 p-8 bg-white shadow-md m-6 rounded-lg">
        {renderTabContent()}
      </div>
    </div>
  );
};

export default Settings;