import React, { useState, useEffect } from 'react';
import { useUser } from '../../context/UserContext'; // Assuming useUser is a hook for accessing user details
import { AiOutlineComment, AiFillDelete } from 'react-icons/ai';

const CustomerComments = ({ customerId }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [loadingComments, setLoadingComments] = useState(false);
  const [commentError, setCommentError] = useState('');
  const { user } = useUser();

  const apiURL = process.env.REACT_APP_API_BASE_URL || 'https://dev.gravstellerne.no/backend';

  useEffect(() => {
    const fetchComments = async () => {
      setLoadingComments(true);
      setCommentError('');

      try {
        const res = await fetch(`${apiURL}/api/customers/${customerId}/comments`);
        if (!res.ok) throw new Error('Failed to load comments.');

        const data = await res.json();
        setComments(data);
      } catch (error) {
        setCommentError(error.message);
      } finally {
        setLoadingComments(false);
      }
    };

    fetchComments();
  }, [customerId]);

  const handleCommentChange = (e) => {
    setNewComment(e.target.value);
  };

  const handleAddComment = async (e) => {
    e.preventDefault();
    if (!newComment.trim()) return; // Prevent empty comments

    try {
      const res = await fetch(`${apiURL}/api/customers/${customerId}/comments`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ comment: newComment, userId: user.id }),
      });
      if (!res.ok) throw new Error('Failed to add comment.');

      const addedComment = await res.json();
      setComments((prevComments) => [...prevComments, addedComment]);
      setNewComment(''); // Clear input after submission
    } catch (error) {
      setCommentError(error.message);
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      const res = await fetch(`${apiURL}/api/customers/comments/${commentId}`, {
        method: 'DELETE',
      });
      if (!res.ok) throw new Error('Failed to delete comment.');

      setComments((prevComments) =>
        prevComments.filter((comment) => comment.id !== commentId)
      );
    } catch (error) {
      setCommentError(error.message);
    }
  };

  return (
    <div className="space-y-4">
      <div>
        <h3 className="text-lg font-semibold">Legg til ny kommentar</h3>
        <form onSubmit={handleAddComment} className="flex space-x-2 mt-2">
          <div className="relative flex-1">
            <input
              type="text"
              id="newComment"
              name="newComment"
              placeholder="Skriv din kommentar her"
              value={newComment}
              onChange={handleCommentChange}
              className="w-full px-4 py-2 pl-10 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-green-grave"
            />
            <AiOutlineComment className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-400" />
          </div>
          <button
            type="submit"
            className="px-4 py-2 bg-green-grave text-white rounded-lg hover:bg-orange-grave"
          >
            Legg til
          </button>
        </form>
      </div>
      {loadingComments ? (
        <p className="text-center">Laster kommentarer...</p>
      ) : comments.length > 0 ? (
        <ul className="space-y-4">
          {comments.map((comment) => (
            <li key={comment.id} className="bg-white rounded-lg shadow-lg p-4 hover:bg-gray-100 transition-colors">
              <div className="flex items-start space-x-4">
                <div className="flex-shrink-0">
                  {/* Placeholder for user avatar; replace with actual image if available */}
                  <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
                    <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M24 20.993V24H0v-3.007a6 6 0 0 1 6-6h12a6 6 0 0 1 6 6zM12 12a6 6 0 1 1 0-12 6 6 0 0 1 0 12z" />
                    </svg>
                  </span>
                </div>
                <div className="flex-1">
                  <div className="flex items-center justify-between">
                    <h3 className="text-sm font-medium">{comment.userName}</h3>
                    <p className="text-sm text-gray-500">{new Date(comment.created_at).toLocaleDateString('nb-NO')} kl. {new Date(comment.created_at).toLocaleTimeString('nb-NO')}</p>
                  </div>
                  <p className="text-sm text-gray-500">{comment.comment}</p>
                </div>
                <div className="flex-shrink-0">
                  <button
                    onClick={() => handleDeleteComment(comment.id)}
                    className="text-red-600 hover:text-red-800 transition-colors duration-150"
                    aria-label="Delete comment"
                  >
                    <AiFillDelete size={20} />
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <div className="text-center text-gray-500">
          <AiOutlineComment className="text-2xl mx-auto mb-2" />
          <p>Ingen kommentarer enda.</p>
        </div>
      )}
      {commentError && <p className="text-red-500 text-center">{commentError}</p>}
    </div>
  );
};

export default CustomerComments;