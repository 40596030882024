import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { FaRegCalendarAlt, FaTools } from 'react-icons/fa';

const LegacyVisitDetailPage = () => {
  const { visitId } = useParams();
  const [visitDetails, setVisitDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const apiURL = process.env.REACT_APP_API_BASE_URL || '';
  const baseURL = process.env.REACT_APP_MAIN_URL || '';

  useEffect(() => {
    const fetchVisitDetails = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${apiURL}/api/legacy-visits/${visitId}`);
        if (!response.ok) throw new Error('Failed to fetch visit details');
        const data = await response.json();
        setVisitDetails(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchVisitDetails();
  }, [visitId]);

  if (loading) return <div className="text-center p-4">Laster...</div>;
  if (error) return <div className="text-center text-red-500 p-4">Error: {error}</div>;

  return (
    <div className="visit-detail-page py-8">
      <div className="max-w-4xl mx-auto bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6 border-b">
          <h1 className="text-lg leading-6 font-medium text-gray-900">Legacy Besøksdetaljer</h1>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">Informasjon fra et tidligere besøk</p>
        </div>
        {visitDetails && (
          <>
          <div className="bg-gray-50 px-4 py-5 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 sm:mb-4">Bilder</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                <div className="flex flex-wrap -mx-2 overflow-hidden">
                <div className="my-2 px-2 w-full overflow-hidden sm:w-1/2">
                    <img src={baseURL + visitDetails.after_pictures || "/images/default_after.jpg"} alt="After the visit" className="object-cover rounded-lg shadow-lg mx-auto" style={{maxHeight: "300px"}} />
                    <p className="text-center text-sm mt-2">Etter</p>
                  </div>
                  <div className="my-2 px-2 w-full overflow-hidden sm:w-1/2">
                    <img src={baseURL + visitDetails.before_pictures || "/images/default_before.jpg"} alt="Before the visit" className="object-cover rounded-lg shadow-lg mx-auto" style={{maxHeight: "300px"}} />
                    <p className="text-center text-sm mt-2">Før</p>
                  </div>
                </div>
              </dd>
            </div>
            <div className="border-t border-gray-200">
              <dl>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Beskrivelse</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{visitDetails.description || 'Ingen beskrivelse tilgjengelig'}</dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Besøksdato</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <FaRegCalendarAlt className="inline mr-2 text-blue-500" />
                    {new Date(visitDetails.visit_date).toLocaleDateString("nb-NO")}
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Aktiviteter utført</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <FaTools className="inline mr-2 text-green-600" />
                    {visitDetails.activities.join(', ') || 'Ingen aktiviteter registrert'}
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Utført av</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{visitDetails.worker_name || 'Ingen arbeider tilgjengelig'}</dd>
                </div>
                {/* Add more sections as necessary */}
              </dl>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LegacyVisitDetailPage;