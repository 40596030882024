import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

const CustomerContext = createContext();

export function useCustomer() {
  return useContext(CustomerContext);
}

export const CustomerProvider = ({ children }) => {
  const [customer, setCustomer] = useState(null);

  useEffect(() => {
    const loadCustomerFromLocalStorage = () => {
      const accessToken = localStorage.getItem('accessToken');
      const refreshToken = localStorage.getItem('refreshToken');
      if (accessToken && refreshToken) {
        try {
          const decoded = jwtDecode(accessToken);
          console.log('Decoded token:', decoded);
          setCustomer({
            id: decoded.customerId,
            name: decoded.customerName,
            type: decoded.userType,
            accessToken,
          });
        } catch (error) {
          console.error('Error decoding token', error);
          // If there's an error decoding, clear the tokens
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
        }
      }
    };

    loadCustomerFromLocalStorage();
  }, []);

  const login = (customerData) => {
    localStorage.setItem('accessToken', customerData.accessToken);
    localStorage.setItem('refreshToken', customerData.refreshToken);
    setCustomer(customerData);
  };

  const logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    setCustomer(null);
  };

  return (
    <CustomerContext.Provider value={{ customer, login, logout }}>
      {children}
    </CustomerContext.Provider>
  );
};