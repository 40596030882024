import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Keep the base styles
import '../css/customDatePicker.css'; // Custom styles for overriding

const ReusableDatePicker = ({ selectedDate, onChange, label, name }) => {
  return (
    <div className="flex items-center space-x-2">
      {label && (
        <label htmlFor={name} className="text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      <div className="relative rounded-md shadow-sm flex-grow">
  <DatePicker
    selected={selectedDate}
    onChange={onChange}
    dateFormat="MMMM d, yyyy"
    className="block w-full pl-3 pr-10 py-2 text-base border-2 border-gray-400 bg-gray-50 focus:bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
    wrapperClassName="date-picker"
    name={name}
    id={name}
  />
</div>
    </div>
  );
};

export default ReusableDatePicker;