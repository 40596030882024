import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useCustomer } from '../../context/CustomerContext';
import axios from 'axios';
import { FaBirthdayCake, FaCross, FaThList, FaBoxOpen, FaClock } from 'react-icons/fa';
import GravestoneContactsManager from '../../components/customer-components/GravestoneContactsManager';
import ReusableHeader from '../../components/ReusableHeader';
import ReusableSubheader from '../../components/ReusableSubheader';
import SpecialDaysManager from '../../components/customer-components/SpecialDaysManager';
import FreshFlowersAd from '../../components/customer-components/FreshFlowersAd';

const CustomerGravestoneProfile = () => {
  const { id } = useParams();
  const [gravestone, setGravestone] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasFreshFlowers, setHasFreshFlowers] = useState(false);
  const { customer } = useCustomer();
  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  useEffect(() => {
    const fetchGravestone = async () => {
      try {
        const response = await axios.get(`${apiURL}/api/gravestones/${id}`);
        setGravestone(response.data);
        setIsLoading(false);

        // Check for fresh flowers product
        if (response.data.productDetails) {
          const freshFlowers = response.data.productDetails.some(product => product.id === 1004);
          setHasFreshFlowers(freshFlowers);
        }
      } catch (err) {
        console.error('Error fetching gravestone data:', err);
        setIsLoading(false);
      }
    };

    fetchGravestone();
  }, [id, apiURL]);

  if (isLoading) {
    return <div className="text-center">Loading...</div>;
  }

  if (!gravestone) {
    return <div className="text-center text-red-500">Gravestone not found</div>;
  }

  const getPictureUrl = (picturePath) => {
    if (!picturePath) return 'https://via.placeholder.com/500';
    return `https://cms.gravstellerne.no${picturePath}`;
  };

  return (
    <div className="bg-white shadow-lg rounded-2xl p-8 w-full md:w-3/4 lg:w-2/3 mx-auto border-t-4 border-green-600">
      <ReusableHeader title="Gravsten Detaljer" alignment='center' />
      <p className="text-center text-gray-600 mb-6">Detaljert informasjon om gravstenen og de gravlagte personene.</p>

      <div className="bg-white rounded-xl overflow-hidden flex flex-col lg:flex-row">
        <div className="lg:w-1/2 flex flex-col items-center p-6 border-r border-gray-200">
          <img
            src={getPictureUrl(gravestone.picture)}
            alt="Gravstein Bilde"
            className="rounded-lg shadow-lg w-full max-w-xs mb-6"
          />
          <div className="w-full">
            <InfoCard icon={<FaClock />} title="Sist Besøkt">
              <p className="text-gray-700 mt-2">{new Date(gravestone.latest_visit_date).toLocaleDateString('nb-NO')}</p>
              {/* Link to Customer Visits with gravestone ID */}
              <Link to={`/kunde/besok/gravstein/${gravestone.id}`} className="text-green-grave hover:underline text-sm mt-2 inline-block">
                Se Besøk for Denne Gravstenen
              </Link>
            </InfoCard>
            <LocationCard 
              icon={<FaThList className="text-gray-800" />} 
              title="Plassering" 
              field={gravestone?.field} 
              row={gravestone?.row} 
              place={gravestone?.place} 
            />
            <InfoCard icon={<FaBoxOpen />} title="Produkter">
              <div className="flex flex-wrap gap-2 mt-2">
                {gravestone.productDetails.map((product) => (
                  <Tag key={product.id} text={product.name} />
                ))}
              </div>
            </InfoCard>
            {hasFreshFlowers ? (
              <SpecialDaysManager gravestoneId={id} />
            ) : (
              <FreshFlowersAd gravestone={gravestone} customer={customer}/>
            )}
          </div>
        </div>

        <div className="lg:w-1/2 p-6">
          <ReusableSubheader title="Gravlagte Personer" />
          <div className="space-y-4 mb-4">
            {gravestone.gravepersons.map((person, index) => (
              <PersonCard key={index} person={person} />
            ))}
          </div>
          <ReusableSubheader title="Kontakter for Gravstein" info="Kontakter som legges til her vil motta e-post og/eller SMS på besøk som er foretatt på dette gravstedet." />
          <GravestoneContactsManager gravestoneId={id} />
        </div>
      </div>

      <div className="text-center mt-8">
        <img src="/images/gravstellerne-logo.png" alt="Gravstellerne Logo" className="mx-auto max-w-xs" />
      </div>
    </div>
  );
};

const InfoCard = ({ icon, title, content, children }) => (
  <div className="p-4 bg-gray-50 rounded-lg shadow-md mb-4 hover:bg-gray-100 transition-colors">
    <h3 className="text-xl font-semibold text-gray-800 flex items-center">
      {icon} <span className="ml-2">{title}</span>
    </h3>
    {content && <p className="text-gray-700 mt-2">{content}</p>}
    {children}
  </div>
);

const LocationCard = ({ icon, title, field, row, place }) => (
  <div className="p-4 bg-gray-50 rounded-lg shadow-md mb-4 hover:bg-gray-100 transition-colors">
    <h3 className="text-xl font-semibold text-gray-800 flex items-center">
      {icon} <span className="ml-2">{title}</span>
    </h3>
    <p className="text-gray-700 mt-2">
      <span className="font-bold">Felt: </span>{field || "Ukjent"}{' '}
      <span className="font-bold">Rad: </span>{row || "Ukjent"}{' '}
      <span className="font-bold">Plass: </span>{place || "Ukjent"}
    </p>
  </div>
);

const PersonCard = ({ person }) => (
  <div className="p-4 bg-gray-50 rounded-lg shadow-md hover:bg-gray-100 transition-colors">
    <h3 className="text-xl font-semibold text-gray-800">{person.name}</h3>
    <div className="mt-2 flex flex-col space-y-2">
      <div className="flex items-center space-x-2">
        <FaBirthdayCake className="text-gray-800" />
        <p className="text-gray-700">{person.birthdate ? new Date(person.birthdate).toLocaleDateString('nb-NO') : 'Ukjent fødselsdato'}</p>
      </div>
      <div className="flex items-center space-x-2">
        <FaCross className="text-gray-800" />
        <p className="text-gray-700">{person.deathdate ? new Date(person.deathdate).toLocaleDateString('nb-NO') : 'Ukjent dødsdato'}</p>
      </div>
    </div>
  </div>
);

const Tag = ({ text }) => (
  <span className="bg-green-grave text-white text-sm font-medium px-3 py-1 rounded-full">
    {text}
  </span>
);

export default CustomerGravestoneProfile;