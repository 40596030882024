import React from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

const Spinner = () => (
  <div className="fixed inset-0 z-50 flex justify-center items-center">
    <div className="p-5 rounded-full bg-white shadow-lg flex items-center justify-center">
      <AiOutlineLoading3Quarters className="animate-spin text-green-500 text-4xl" />
    </div>
  </div>
);

export default Spinner;