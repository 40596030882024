import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaBookOpen, FaMonument, FaUser, FaSignOutAlt, FaBell } from 'react-icons/fa'; // Importing icons
import axiosInstance from '../../utils/axiosInstance';
import NewCommentsList from './NewCommentsList'; // Import the NewCommentsList component
import { useCustomer } from '../../context/CustomerContext';

const menuItems = [
  { href: '/kunde', label: 'Hjem', icon: <FaHome /> },
  { href: '/kunde/gravsteiner', label: 'Dine Gravsteiner', icon: <FaMonument /> },
  { href: '/kunde/besok', label: 'Besøk', icon: <FaBookOpen /> },
  { href: '/kunde/profil', label: 'Din Profil', icon: <FaUser /> },
  { href: '/kunde/loggut', label: 'Logg ut', icon: <FaSignOutAlt /> },
];

const TopBar = ({ children }) => {
  const [portalNotice, setPortalNotice] = useState({});
  const { customer } = useCustomer();
  const [hasUnseenComments, setHasUnseenComments] = useState(false); // State for unseen comments
  const [showComments, setShowComments] = useState(false); // State to toggle the visibility of NewCommentsList
  const commentsRef = useRef(null); // Create a reference for the NewCommentsList container

  useEffect(() => {
    const fetchPortalNotice = async () => {
      try {
        const response = await axiosInstance.get(`/api/site_settings/customer_portal_notice`);
        const data = response.data.setting_value;
        setPortalNotice(data);
      } catch (error) {
        console.error('Error fetching portal notice:', error);
      }
    };

    const fetchUnseenComments = async () => {
      try {
        const response = await axiosInstance.get('/api/visit_comments/unseen_by_customer', {
          params: { customer_id: customer.id }, // Replace with actual customer_id
        });
        setHasUnseenComments(response.data.unseen);
      } catch (error) {
        console.error('Error fetching unseen comments status:', error);
      }
    };

    fetchPortalNotice();
    fetchUnseenComments();
  }, [customer.id]);

  // Effect to handle clicks outside the comments list
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (commentsRef.current && !commentsRef.current.contains(event.target)) {
        setShowComments(false);
      }
    };

    if (showComments) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showComments]);

  const toggleCommentsList = () => {
    setShowComments(!showComments);
  };

  return (
    <div>
      <header className="bg-white shadow-lg border-green-grave">
        <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-16 h-24 flex items-center">
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center">
              <img src="/images/gravstellerne-logo-kundeportal.png" alt="Logo" className="h-12" /> {/* Replace with actual logo path */}
            </div>
            <div className="flex space-x-4">
              {menuItems.map((item) => (
                <Link key={item.href} to={item.href} className="text-gray-700 text-md hover:text-green-grave transition p-2 rounded-lg hover:bg-gray-100 flex items-center space-x-2">
                  {item.icon}
                  <span>{item.label}</span>
                </Link>
              ))}
              <div className="relative">
                <button onClick={toggleCommentsList} className="relative text-gray-700 text-md hover:text-green-grave transition p-2 mt-1 rounded-lg hover:bg-gray-100 flex items-center space-x-2">
                  <FaBell />
                  {hasUnseenComments && (
                    <span className="absolute top-0 right-0 bg-red-600 h-3 w-3 rounded-full"></span>
                  )}
                </button>
                {showComments && (
                  <div ref={commentsRef} className="absolute right-0 mt-2 bg-white border border-gray-300 rounded-lg shadow-lg p-4 z-50">
                    <NewCommentsList customer_id={customer.id} /> {/* Replace with actual customer_id */}
                  </div>
                )}
              </div>
            </div>
          </div>
        </nav>
        {portalNotice.visible && (
          <div className={`${portalNotice.color} text-lg font-bold text-white text-center py-2`}>
            {portalNotice.message}
          </div>
        )}
      </header>
      {children}
    </div>
  );
};

export default TopBar;