import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import BackButton from '../../components/BackButton';
import GravestoneMapAll from '../../components/GravestoneMapAll';
import ReusableSearch from '../../components/ReusableSearch';

function GravestonesMapMobile() {
  const { customerId, graveyardId } = useParams();
  const [gravestones, setGravestones] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('id');
  const [order, setOrder] = useState('ASC');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(9999);
  const [active, setActive] = useState("all");
  const [totalPages, setTotalPages] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageSrc, setSelectedImageSrc] = useState('');

  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  // Event handlers remain within the parent component
  const handleSearchChange = (e) => setSearch(e.target.value);
  const handleLimitChange = (e) => setLimit(e.target.value);
  const handleActiveChange = (e) => setActive(e.target.value);

  useEffect(() => {
    const queryParams = new URLSearchParams({ search, sort, order, page, limit, active });

if (customerId) {
  queryParams.append('customer_id', customerId);
} else if (graveyardId) {
  queryParams.append('graveyard_id', graveyardId);
}

const queryString = queryParams.toString();
    const fetchGravestones = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${apiURL}/api/gravestones?${queryString}`);
        if (!response.ok) {
          throw new Error('Something went wrong!');
        }
        const data = await response.json();
        setGravestones(data.gravestones);
        setTotalPages(data.totalPages);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchGravestones();
  }, [search, sort, order, page, limit, active, customerId, graveyardId]);

  //if (isLoading) return <Spinner />;
  if (error) return <div className="text-red-500 text-center">Error: {error}</div>;

  return (
    <div className="relative">
        <div className="absolute z-30 w-full p-4 text-center">
        <BackButton />
          <ReusableSearch search={search} onChange={handleSearchChange} />
        </div>
        {isLoading && <Spinner />}
        {gravestones.length > 0 ? (
          <GravestoneMapAll gravestones={gravestones} />
        ) : (
          <div className="text-center text-gray-500 mt-16"> {/* Adjusted margin to make room for the search bar */}
            <p className="text-2xl">Ingen gravsteiner funnet</p>
            <p>Prøv å søke etter noe annet</p>
          </div>
        )}
    </div>
);
}

export default GravestonesMapMobile;