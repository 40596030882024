import React from 'react';

const Modal = ({ isOpen, close, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center p-4">
      <div className="relative bg-white rounded-lg max-w-full w-full max-h-full overflow-auto">
        {/* Overlay Close Button */}
        <button
          onClick={close}
          className="absolute top-0 right-0 m-2 text-white bg-red-500 hover:bg-red-600 rounded-full p-2 leading-none"
          style={{ zIndex: 1 }} // Ensure the button is above other content
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path></svg>
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;