import React, { useState } from "react";
import {
  AiOutlineArrowUp,
  AiOutlineArrowDown,
  AiOutlineLike,
  AiOutlineDislike,
} from "react-icons/ai";
import { Link } from "react-router-dom"; 
import Rating from "./Rating"; 
import ContextMenu from "./ContextMenu"; // Import your context menu component

const ReusableTable = ({
  columns,
  data,
  sort,
  onSort,
  isSelected, // New prop to check if a row is selected
  handleRowSelectionChange, // New prop to handle row selection change
  onRowRightClick, // New prop to handle right-click
  contextMenuItems, // New prop for context menu items
}) => {
  const [contextMenu, setContextMenu] = useState(null);

  const handleRightClick = (event, row) => {
    event.preventDefault();
    if (onRowRightClick) {
      const menuItems = onRowRightClick(row);
      if (menuItems && menuItems.length > 0) {
        setContextMenu({
          items: menuItems,
          position: { x: event.pageX, y: event.pageY },
        });
      }
    }
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };
  const apiURL = process.env.REACT_APP_API_BASE_URL || "";

  const getColorForDateDifference = (latestVisitDateStr, daysBetweenVisits) => {
    if (!latestVisitDateStr) return "text-gray-500"; 

    const today = new Date();
    const latestVisitDate = new Date(latestVisitDateStr);
    const differenceInTime = today.getTime() - latestVisitDate.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);

    if (differenceInDays <= daysBetweenVisits) return "text-green-grave"; 
    if (differenceInDays <= daysBetweenVisits * 2) return "text-orange-grave"; 
    return "text-red-500"; 
  };

  const renderStatus = (cellValue) => {
    return cellValue === "completed" ? (
      <span className="text-green-grave">Fullført</span>
    ) : cellValue === "failed" ? (
      <span className="text-red-500">Mislyktes</span>
    ) : (
      <span className="text-yellow-500">Ventende</span>
    );
  };

  const calculateDaysSinceLastVisit = (lastVisitDate) => {
    const today = new Date();
    const lastVisit = new Date(lastVisitDate);
    const differenceInTime = today.getTime() - lastVisit.getTime();
    return Math.floor(differenceInTime / (1000 * 3600 * 24)); 
  };

  const renderNames = (cellValue, column, row) => {
    const linkPath = typeof column.link === "function" ? column.link(row) : column.link;
    const names = cellValue.split(",").map((name, index) => <div key={index}>{name.trim()}</div>);

    return column.link ? (
      <Link to={linkPath} className="text-green-grave hover:text-orange-grave underline">
        <div>{names}</div>
      </Link>
    ) : (
      <div>{names}</div>
    );
  };

  const renderSpan = (cellValue) => {
    return (
      <div className="flex flex-wrap gap-2">
        {cellValue.split(",").map((item, index) => (
          <span key={index} className="bg-orange-grave rounded px-1 text-sm">
            {item.trim()}
          </span>
        ))}
      </div>
    );
  };

  const renderImage = (cellValue, cellLabel, cellSecond) => {
    const borderStyle = cellSecond ? { border: `4px solid ${cellSecond}` } : {};
    return (
      <img
        src={`${apiURL}${cellValue}`}
        alt={cellLabel}
        className="w-16 h-16 min-w-16 object-cover"
        style={borderStyle}
      />
    );
  };

  const renderBoolean = (cellValue) => {
    return parseInt(cellValue) ? (
      <AiOutlineLike className="text-green-grave" />
    ) : (
      <AiOutlineDislike className="text-red-500" />
    );
  };

  const renderReverseBoolean = (cellValue) => {
    return cellValue ? (
      <AiOutlineDislike className="text-red-500" />
    ) : (
      <AiOutlineLike className="text-green-grave" />
    );
  };

  const renderDate = (cellValue, column, row) => {
    const formattedDate = new Date(cellValue).toLocaleDateString("nb-NO");
    const linkPath = typeof column.link === "function" ? column.link(row) : column.link;

    return column.link ? (
      <Link to={linkPath} className="text-green-grave hover:text-orange-grave underline">
        {formattedDate}
      </Link>
    ) : (
      formattedDate
    );
  };

  const renderDateTime = (cellValue, column, row) => {
    const formattedDateTime = new Date(cellValue).toLocaleString("nb-NO");
    const linkPath = typeof column.link === "function" ? column.link(row) : column.link;

    return column.link ? (
      <Link to={linkPath} className="text-green-grave hover:text-orange-grave underline">
        {formattedDateTime}
      </Link>
    ) : (
      formattedDateTime
    );
  };

  const renderDays = (cellValue, cellSecond) => {
    const daysSinceLastVisit = cellSecond ? calculateDaysSinceLastVisit(cellSecond) : null;
    const daysBetweenVisits = parseInt(cellValue, 10);
    const daysOverLimit = daysSinceLastVisit > daysBetweenVisits ? daysSinceLastVisit - daysBetweenVisits : 0;

    const colorClass = getColorForDateDifference(cellSecond, daysBetweenVisits);

    return (
      <span className={`${colorClass}`}>
        {cellSecond ? `${daysBetweenVisits}` : "Ingen Besøk"}
        {daysOverLimit > 0 ? ` (+${daysOverLimit})` : ""}
      </span>
    );
  };

  const renderRating = (row) => {
    return (
      <Rating
        visit_type={row.visit_type}
        visit_id={row.visit_id}
        gravestone_id={row.gravestone_id}
        customer_id={row.customer_id}
        readOnly={true} 
      />
    );
  };

  const renderVisitLink = (row) => {
    if (row.visit_type === "visits") {
      return <Link to={`/besok/${row.visit_id}/detaljer/1`} className="text-green-grave underline hover:text-orange-grave">{row.visit_id}</Link>;
    } else if (row.visit_type === "visits_new") {
      return <Link to={`/besok/${row.visit_id}/detaljer/0`} className="text-green-grave underline hover:text-orange-grave">{row.visit_id}</Link>;
    }
  };

  const renderToggle = (rowId, rowIsLegacy) => (
    <input
      type="checkbox"
      className="form-checkbox h-5 w-5"
      checked={isSelected(rowId, rowIsLegacy)}
      onChange={(e) => handleRowSelectionChange(rowId, rowIsLegacy, e.target.checked)}
    />
  );

  const renderMeasurement = (cellValue, column) => {
    if (!cellValue) return "Nei";
    return (
      <div className="flex items-center">
        <span>{cellValue}</span>
        <span className="text-gray-500 text-sm ml-1">cm</span>
      </div>
    );
  };

  const renderDefault = (cellValue, column, row) => {
    if (column.link) {
      const linkPath = typeof column.link === "function" ? column.link(row) : column.link;
      return (
        <Link to={linkPath} className="text-green-grave hover:text-orange-grave underline">
          {cellValue}
        </Link>
      );
    } else {
      return cellValue;
    }
  };

  const handlePayloadTo = (row) => {
    const payload = JSON.parse(row.payload);  
    return payload.to;
  };

  const renderCell = (row, column) => {
    const cellLabel = row[column.label] || ""; 
    const cellValue = row[column.accessor] || ""; 
    const cellSecond = row[column.second] || ""; 

    const cellContent = (() => {
      switch (column.type) {
        case "names":
          return renderNames(cellValue, column, row);
        case "span":
          return renderSpan(cellValue);
        case "image":
          return renderImage(cellValue, cellLabel, cellSecond);
        case "boolean":
          return renderBoolean(cellValue);
        case "reverse-boolean":
          return renderReverseBoolean(cellValue);
        case "date":
          return renderDate(cellValue, column, row);
        case "date-time":
          return renderDateTime(cellValue, column, row);
        case "html":
          return <div dangerouslySetInnerHTML={{ __html: cellValue }} />;
        case "days":
          return renderDays(cellValue, cellSecond);
        case "toggle":
          return renderToggle(row.id, row.is_legacy);
        case "measurement":
          return renderMeasurement(cellValue, column);
        case "rating":
          return renderRating(row);
        case "visit-link":
          return renderVisitLink(row);
        case "status":
          return renderStatus(cellValue);
        case "payload-to":
          return handlePayloadTo(row);
        default:
          return renderDefault(cellValue, column, row);
      }
    })();

    return column.onClick ? (
      <div onClick={() => column.onClick(row)} className="cursor-pointer">
        {cellContent}
      </div>
    ) : (
      cellContent
    );
  };

  const hasToggle = columns.some((column) => column.type === 'toggle') && typeof isSelected === 'function';
  const isAllSelected = hasToggle ? data.every((row) => isSelected(row.id, row.is_legacy)) : false;
  const toggleAll = hasToggle ? (isChecked) => {
    data.forEach((row) => handleRowSelectionChange(row.id, row.is_legacy, isChecked));
  } : () => {};

  return (
    <div>
      <table className="fixed-table shadow rounded-lg">
        <thead className="bg-gray-200 text-gray-600">
          <tr>
            {columns.map((column) => (
              <th
                key={column.accessor}
                className="px-4 py-2 text-left cursor-pointer"
                onClick={() => column.type !== "toggle" && onSort(column.accessor)}
                style={{ width: column.width || 'auto' }} 
              >
                {column.type === "toggle" ? (
                  <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5"
                    checked={isAllSelected}
                    onChange={(e) => toggleAll(e.target.checked)}
                  />
                ) : (
                  <div className="flex items-center justify-start space-x-2">
                    {column.label}
                    {sort.field === column.accessor &&
                      (sort.order === "ASC" ? (
                        <AiOutlineArrowUp />
                      ) : (
                        <AiOutlineArrowDown />
                      ))}
                  </div>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white">
          {data.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              className="border-b hover:bg-gray-50"
              onContextMenu={(e) => handleRightClick(e, row)} // Right-click handler
            >
              {columns.map((column) => (
                <td
                  key={column.accessor}
                  className={`px-4 py-2 ${column.type === 'color' ? 'text-white' : ''}`}
                  style={{ backgroundColor: column.type === 'color' ? renderCell(row, column) : '', width: column.width || 'auto' }}
                >
                  {renderCell(row, column)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {contextMenu && (
        <ContextMenu
          items={contextMenu.items}
          position={contextMenu.position}
          onClose={handleCloseContextMenu}
        />
      )}
    </div>
  );
};

export default ReusableTable;