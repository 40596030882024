import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useNotifications } from '../../context/NotificationContext';
import { useActivities } from '../../hooks';

const AddActivity = () => {
    const navigate = useNavigate();
    const { addNotification } = useNotifications();

    const { activity, setActivity, addActivity } = useActivities();

    const handleChange = (e) => {
        setActivity({ ...activity, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        addActivity(activity, navigate, addNotification);
    };

    return (
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold text-gray-700 mb-3 mt-6 text-center">Legg til aktivitet</h1>
            <form onSubmit={handleSubmit} className="max-w-lg mx-auto bg-white p-6 rounded-lg shadow">
                <div className="mb-4">
                    <label htmlFor="name" className="block text-lg font-semibold text-gray-700 mb-1">Navn</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={activity.name}
                        onChange={handleChange}
                        className="border border-gray-300 px-3 py-2 rounded-md w-full text-lg"
                        placeholder="Navn på aktiviteten"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="code" className="block text-lg font-semibold text-gray-700 mb-1">Kode</label>
                    <input
                        type="text"
                        id="code"
                        name="code"
                        value={activity.code}
                        onChange={handleChange}
                        className="border border-gray-300 px-3 py-2 rounded-md w-full text-lg"
                        placeholder="Unik kode"
                    />
                </div>
                {/* Add more form fields as needed */}
                <button type="submit" className="bg-green-grave hover:bg-orange-grave text-white px-4 py-2 rounded-md text-lg transition duration-200 ease-in-out">
                    Opprett aktivitet
                </button>
            </form>
        </div>
    );    
};

export default AddActivity;
