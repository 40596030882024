import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useUser } from "../../context/UserContext";
import { FaEdit, FaSave, FaTimes, FaEye, FaEyeSlash, FaKey, FaCalendarAlt, FaClock } from 'react-icons/fa';
import { useNotifications } from '../../context/NotificationContext';
import { useUsers } from "../../hooks";

const EditUser = () => {
  const { id } = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const apiURL = process.env.REACT_APP_API_BASE_URL || '';
  const { addNotification } = useNotifications();
  const navigate = useNavigate();
  const { user } = useUser();
  const { fetchUserById, profile, setProfile, updateUser } = useUsers();

  useEffect(() => {
    if (user.type && user.type !== 'admin') {
      addNotification({ message: 'Du har ikke tilgang til dette.', type: 'error' });
      navigate('/');
    }
  }, [user.type]);

  useEffect(() => {
    fetchUserById(id);
  }, [id, isEditing]);

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleSave = async () => {
    updateUser(id, profile, selectedFile, setIsEditing);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile(prevUser => ({ ...prevUser, [name]: value }));
  };

  const handleGeneratePassword = () => {
    const length = 12;
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let randomPassword = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      randomPassword += charset.charAt(Math.floor(Math.random() * n));
    }
    setProfile(prevUser => ({ ...prevUser, password: randomPassword }));
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(prevShowPassword => !prevShowPassword);
  };

  return (
    <div className="max-w-2xl mx-auto p-8 bg-white rounded-lg shadow-lg">
      <div className="flex justify-between items-center mb-6">
        {isEditing ? (
          <h1 className="text-3xl font-bold text-gray-800">Endre Bruker</h1>
        ) : (
          <h1 className="text-3xl font-bold text-gray-800">Vis Bruker</h1>
        )}
        {isEditing ? (
          <div>
            <button
              onClick={handleSave}
              className="bg-green-grave text-white p-2 rounded-lg shadow-md hover:bg-orange-grave transition duration-150 ease-in-out mr-2"
            >
              <FaSave />
            </button>
            <button
              onClick={handleEditToggle}
              className="bg-red-500 text-white p-2 rounded-lg shadow-md hover:bg-red-600 transition duration-150 ease-in-out"
            >
              <FaTimes />
            </button>
          </div>
        ) : (
          <button
            onClick={handleEditToggle}
            className="bg-green-grave text-white p-2 rounded-lg shadow-md hover:bg-orange-grave transition duration-150 ease-in-out"
          >
            <FaEdit />
          </button>
        )}
      </div>
      {isEditing ? (
        <form className="space-y-6">
          <div>
            <label htmlFor="name" className="block text-lg font-medium text-gray-700">Navn:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={profile.name || ''}
              onChange={handleChange}
              className="mt-2 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 transition duration-150 ease-in-out"
              placeholder="Skriv navnet her"
            />
          </div>

          <div>
            <label htmlFor="type" className="block text-lg font-medium text-gray-700">Type:</label>
            <input
              type="text"
              id="type"
              name="type"
              value={profile.type || ''}
              onChange={handleChange}
              className="mt-2 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 transition duration-150 ease-in-out"
              placeholder="Skriv type her (worker / admin)"
            />
          </div>

          <div>
            <label htmlFor="email" className="block text-lg font-medium text-gray-700">E-post:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={profile.email || ''}
              onChange={handleChange}
              className="mt-2 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 transition duration-150 ease-in-out"
              placeholder="Skriv e-post her"
            />
          </div>

          <div>
            <label htmlFor="password" className="block text-lg font-medium text-gray-700">Passord:</label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                value={profile.password || ''}
                onChange={handleChange}
                className="mt-2 block w-full pr-10 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 transition duration-150 ease-in-out"
                placeholder="Skriv et nytt passord her"
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute inset-y-0 right-8 px-3 flex items-center bg-gray-200 rounded-r-md hover:bg-gray-300"
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
              <button
                type="button"
                onClick={handleGeneratePassword}
                className="absolute inset-y-0 right-0 px-3 flex items-center bg-gray-200 rounded-r-md hover:bg-gray-300"
              >
                <FaKey />
              </button>
            </div>
          </div>

          <div>
            <label htmlFor="active" className="block text-lg font-medium text-gray-700">Aktiv?</label>
            <select
              id="active"
              name="active"
              value={profile.active || ''}
              onChange={handleChange}
              className="mt-2 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 transition duration-150 ease-in-out"
            >
              <option value="1">Ja</option>
              <option value="0">Nei</option>
            </select>
          </div>

          <div>
            <label htmlFor="level" className="block text-lg font-medium text-gray-700">Nivå:</label>
            <input
              type="number"
              id="level"
              name="level"
              value={profile.level || ''}
              onChange={handleChange}
              className="mt-2 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 transition duration-150 ease-in-out"
              placeholder="Skriv bruker nivå her"
            />
          </div>

          <div>
            <label htmlFor="picture" className="block text-lg font-medium text-gray-700">Bilde:</label>
            <input
              type="file"
              id="picture"
              name="picture"
              onChange={handleFileChange}
              className="mt-2 block w-full text-gray-700"
            />
          </div>
        </form>
      ) : (
        <div className="space-y-6 bg-white p-8 rounded-lg shadow-md">
          <div className="flex items-center space-x-4">
            {profile.picture && <img src={`${apiURL}/${profile.picture}`} alt="User Profile" className="w-20 h-20 rounded-full shadow-lg" />}
            <div>
              <h2 className="text-3xl font-bold text-gray-800">{profile.name}</h2>
              <p className="text-sm text-gray-500">{profile.email}</p>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-sm font-medium text-gray-600">Type:</p>
              <p className="text-lg text-gray-700">{profile.type}</p>
            </div>
            <div>
              <p className="text-sm font-medium text-gray-600">Aktiv?</p>
              <p className={`text-lg ${profile.active ? 'text-green-grave' : 'text-orange-grave'}`}>{profile.active ? 'Ja' : 'Nei'}</p>
            </div>
            <div>
              <p className="text-sm font-medium text-gray-600">Nivå:</p>
              <p className="text-lg text-gray-700">{profile.level}</p>
            </div>
          </div>
          <div className="mt-6 p-4 bg-gray-100 rounded-lg">
            <div className="grid grid-cols-3 gap-4">
              <div className="flex items-center space-x-2">
                <FaCalendarAlt className="text-gray-500" />
                <div>
                  <p className="text-xs font-medium text-gray-600">Opprettet:</p>
                  <p className="text-sm text-gray-700">{new Date(profile.created_at).toLocaleString('nb-NO')}</p>
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <FaClock className="text-gray-500" />
                <div>
                  <p className="text-xs font-medium text-gray-600">Oppdatert:</p>
                  <p className="text-sm text-gray-700">{new Date(profile.updated_at).toLocaleString('nb-NO')}</p>
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <FaClock className="text-gray-500" />
                <div>
                  <p className="text-xs font-medium text-gray-600">Sist Innlogget:</p>
                  <p className="text-sm text-gray-700">{new Date(profile.last_login).toLocaleString('nb-NO')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditUser;