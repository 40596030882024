import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useNotifications } from '../../context/NotificationContext';
import { useGraveyards } from '../../hooks';

const formFields = [
  { name: 'name', label: 'Navn', type: 'text', required: true },
  { name: 'location', label: 'Sted', type: 'text' },
  { name: 'latitude', label: 'Latitude', type: 'text' },
  { name: 'longitude', label: 'Longitude', type: 'text' },
  { name: 'contact', label: 'Kontaktperson', type: 'text' },
  { name: 'contact_phone', label: 'Kontakttelefon', type: 'text' },
  { name: 'contact_email', label: 'Kontakt e-post', type: 'email' },
  { name: 'description', label: 'Beskrivelse', type: 'textarea' },
  { name: 'eclesia_url', label: 'Eclesia URL', type: 'text' },
  // Add more fields as necessary
];

const AddGraveyard = () => {
  const [formData, setFormData] = useState({
    name: '',
    fellesraad_id: '',
    fellesraad: '',
    butikk_id: '',
    description: '',
    picture: '',
    location: '',
    latitude: '',
    longitude: '',
    contact: '',
    contact_phone: '+47',
    contact_email: '',
    eclesia_url: '',
    graves: '',
    active: true,
    plantasjen_butikk_id: ''
  });

  const { addGraveyard, isLoading, error } = useGraveyards(); // Use the hook
  const navigate = useNavigate();
  const { addNotification } = useNotifications();

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: type === 'checkbox' ? checked : files ? files[0] : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await addGraveyard(formData); // Call the hook's addGraveyard function
  };

  return (
    <div className="max-w-4xl mx-auto my-10 p-5 bg-white border rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold text-gray-800 mb-6">Opprett en ny kirkegård</h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-6">
          {formFields.map(({ name, label, type, required }) => (
            <div key={name} className="flex flex-col">
              <label htmlFor={name} className="mb-2 text-sm font-semibold text-gray-700">{label}</label>
              {type === 'textarea' ? (
                <textarea
                  name={name}
                  id={name}
                  rows="3"
                  value={formData[name]}
                  onChange={handleChange}
                  className="mt-1 p-2 w-full rounded-md border border-gray-300 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
                ></textarea>
              ) : (
                <input
                  type={type}
                  name={name}
                  id={name}
                  value={formData[name]}
                  onChange={handleChange}
                  className="mt-1 p-2 w-full rounded-md border border-gray-300 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
                  required={required}
                />
              )}
            </div>
          ))}
          {/* File Upload for Picture */}
          <div className="col-span-2">
            <label htmlFor="picture" className="block mb-2 text-sm font-semibold text-gray-700">Bilde</label>
            <input
              type="file"
              name="picture"
              id="picture"
              onChange={handleChange}
              className="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-green-50 file:text-green-700 hover:file:bg-green-100"
            />
          </div>
          {/* Active Checkbox */}
          <div className="col-span-2 flex items-center">
            <input
              type="checkbox"
              name="active"
              id="active"
              checked={formData.active}
              onChange={handleChange}
              className="h-4 w-4 text-green-600 focus:ring-green-500 border-gray-300 rounded"
            />
            <label htmlFor="active" className="ml-2 text-sm font-medium text-gray-700">Aktiv</label>
          </div>
        </div>
        <button 
          type="submit" 
          className="mt-4 px-6 py-2 bg-green-600 text-white font-semibold rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
          disabled={isLoading}
        >
          Opprett Kirkegård
        </button>
        {error && <p className="text-red-500">{error}</p>}
      </form>
    </div>
  );
};

export default AddGraveyard;