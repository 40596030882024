import { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../utils/axiosInstance'; // Assuming axiosInstance is configured

export const useActivities = () => {
  const [activities, setActivities] = useState([]);
  const [activity, setActivity] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch all activities
  const fetchActivities = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get('/api/activitiesWorkorder');
      setActivities(response.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchToggleActivities = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get('/api/activitiesWorkorder');
      
      // Map activities to format required by react-select
      const options = response.data.map((activity) => ({
        value: activity.id.toString(),
        label: activity.name,
        isSelected: false,
      }));

      setActivities(options);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Fetch a single activity by ID
  const fetchActivityById = useCallback(async (id) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/api/activities/${id}`);
      setActivity(response.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Add a new activity
  const addActivity = async (newActivity, navigate, addNotification) => {
    try {
      const response = await axiosInstance.post('/api/activities', newActivity);
      setActivities((prevActivities) => [...prevActivities, response.data]);
      addNotification({ message: 'Aktivitet opprettet suksessfullt', type: 'success' });
      navigate('/aktiviteter');
    } catch (err) {
      setError(err.message);
      addNotification({ message: err.message || 'En feil oppstod', type: 'error' });
    }
  };

  // Update an existing activity by ID
  const updateActivity = async (id, updatedActivity, setIsEditing, addNotification) => {
    try {
      const response = await axiosInstance.put(`/api/activities/${id}`, updatedActivity);
      setActivity(response.data);
      setIsEditing(false);
      addNotification({ message: 'Aktivitet oppdatert suksessfullt', type: 'success' });
    } catch (err) {
      setError(err.message);
      addNotification({ message: err.message || 'En feil oppstod', type: 'error' });
    }
  };

  // Format activities for react-select
  const getActivityOptions = useCallback(() => {
    return activities.map((activity) => ({
      value: activity.id.toString(),
      label: activity.name,
    }));
  }, [activities]);

  return {
    activities,
    activity,
    setActivity,
    setActivities,
    fetchToggleActivities,
    isLoading,
    error,
    fetchActivities,
    fetchActivityById,
    addActivity,
    updateActivity,
    getActivityOptions,  // Expose the formatted options
  };
};