import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash, FaKey } from 'react-icons/fa';
import { useNotifications } from '../../context/NotificationContext';

const CreateUser = () => {
    const [userInfo, setUserInfo] = useState({
        name: '',
        type: 'worker',
        email: '',
        password: '',
        active: '1',
        level: '1'
    });
    const [showPassword, setShowPassword] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const navigate = useNavigate();
    const { addNotification } = useNotifications();
    const apiURL = process.env.REACT_APP_API_BASE_URL || '';

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserInfo(prevUser => ({ ...prevUser, [name]: value }));
    };

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(prevShowPassword => !prevShowPassword);
    };

    const handleGeneratePassword = () => {
        const length = 12;
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        let randomPassword = "";
        for (let i = 0, n = charset.length; i < length; ++i) {
            randomPassword += charset.charAt(Math.floor(Math.random() * n));
        }
        setUserInfo(prevUser => ({ ...prevUser, password: randomPassword }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', userInfo.name);
        formData.append('type', userInfo.type);
        formData.append('email', userInfo.email);
        formData.append('password', userInfo.password);
        formData.append('active', userInfo.active);
        formData.append('level', userInfo.level);
        if (selectedFile) formData.append('picture', selectedFile);

        try {
            const response = await axios.post(`${apiURL}/api/users`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            addNotification({ message: 'Bruker opprettet.', type: 'success' });
            navigate('/users'); // Redirect to the users page
        } catch (error) {
            console.error(error);
            addNotification({ message: error.message || 'An error occurred', type: 'error' });
        }
    };

    return (
        <div className="max-w-2xl mx-auto p-8 bg-white rounded-lg shadow-lg">
            <h1 className="text-3xl font-bold text-gray-800 mb-6">Ny Bruker</h1>
            <form className="space-y-6" onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="name" className="block text-lg font-medium text-gray-700">Navn:</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={userInfo.name}
                        onChange={handleChange}
                        className="mt-2 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 transition duration-150 ease-in-out"
                        placeholder="Skriv navnet her"
                    />
                </div>
                <div>
                    <label htmlFor="type" className="block text-lg font-medium text-gray-700">Type:</label>
                    <input
                        type="text"
                        id="type"
                        name="type"
                        value={userInfo.type}
                        onChange={handleChange}
                        className="mt-2 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 transition duration-150 ease-in-out"
                        placeholder="Skriv type her (worker / admin)"
                    />
                </div>
                <div>
                    <label htmlFor="email" className="block text-lg font-medium text-gray-700">E-post:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={userInfo.email}
                        onChange={handleChange}
                        className="mt-2 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 transition duration-150 ease-in-out"
                        placeholder="Skriv e-post her"
                    />
                </div>
                <div>
                    <label htmlFor="password" className="block text-lg font-medium text-gray-700">Passord:</label>
                    <div className="relative">
                        <input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            name="password"
                            value={userInfo.password}
                            onChange={handleChange}
                            className="mt-2 block w-full pr-10 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 transition duration-150 ease-in-out"
                            placeholder="Skriv et nytt passord her"
                        />
                        <button
                            type="button"
                            onClick={togglePasswordVisibility}
                            className="absolute inset-y-0 right-8 px-3 flex items-center bg-gray-200 rounded-r-md hover:bg-gray-300"
                        >
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </button>
                        <button
                            type="button"
                            onClick={handleGeneratePassword}
                            className="absolute inset-y-0 right-0 px-3 flex items-center bg-gray-200 rounded-r-md hover:bg-gray-300"
                        >
                            <FaKey />
                        </button>
                    </div>
                </div>
                <div>
                    <label htmlFor="active" className="block text-lg font-medium text-gray-700">Aktiv?</label>
                    <select
                        id="active"
                        name="active"
                        value={userInfo.active}
                        onChange={handleChange}
                        className="mt-2 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 transition duration-150 ease-in-out"
                    >
                        <option value="1">Ja</option>
                        <option value="0">Nei</option>
                    </select>
                </div>
                <div>
                    <label htmlFor="level" className="block text-lg font-medium text-gray-700">Nivå:</label>
                    <input
                        type="number"
                        id="level"
                        name="level"
                        value={userInfo.level}
                        onChange={handleChange}
                        className="mt-2 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 transition duration-150 ease-in-out"
                        placeholder="Skriv bruker nivå her"
                    />
                </div>
                <div>
                    <label htmlFor="picture" className="block text-lg font-medium text-gray-700">Bilde:</label>
                    <input
                        type="file"
                        id="picture"
                        name="picture"
                        onChange={handleFileChange}
                        className="mt-2 block w-full text-gray-700"
                    />
                </div>
                <button
                    type="submit"
                    className="w-full bg-green-grave text-white py-3 px-4 rounded-lg shadow-lg hover:bg-orange-grave focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-150 ease-in-out"
                >
                    Legg Til
                </button>
            </form>
        </div>
    );
};

export default CreateUser;