import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { FaClock, FaPlay, FaPause } from 'react-icons/fa';

moment.locale('nb');

const PunchClock = ({ userId }) => {
    const [currentTime, setCurrentTime] = useState(moment());
    const [punchInTime, setPunchInTime] = useState(null);
    const [isPunchedIn, setIsPunchedIn] = useState(false);

    const apiURL = process.env.REACT_APP_API_BASE_URL || '';

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(moment());
        }, 1000);

        checkPunchStatus();
        
        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        if (punchInTime) {
            const diffDuration = moment.duration(currentTime.diff(punchInTime));
            const durationFormatted = `${diffDuration.hours()}t ${diffDuration.minutes()}m ${diffDuration.seconds()}s`;
            document.title = durationFormatted; // Optionally update the tab title with duration
        }
    }, [currentTime, punchInTime]);

    const checkPunchStatus = async () => {
        try {
            const response = await axios.get(`${apiURL}/api/logs/status/${userId}`);
            if (response.data.punchedIn) {
                setPunchInTime(moment(response.data.log.start_time));
                setIsPunchedIn(true);
            }
        } catch (error) {
            console.error('Error fetching punch status:', error);
        }
    };

    const handlePunchIn = async () => {
        try {
            const response = await axios.post(`${apiURL}/api/logs/punch-in`, { userId });
            setPunchInTime(moment(response.data.start_time));
            setIsPunchedIn(true);
        } catch (error) {
            console.error('Error punching in:', error);
        }
    };

    const handlePunchOut = async () => {
        try {
            await axios.post(`${apiURL}/api/logs/punch-out`, { userId });
            setPunchInTime(null);
            setIsPunchedIn(false);
        } catch (error) {
            console.error('Error punching out:', error);
        }
    };

    return (
        <div className="p-5 rounded-xl shadow-xl border-2 border-transparent bg-white"
             style={{ borderImage: 'linear-gradient(to right, purple, pink) 1' }}>
            <h1 className="text-4xl font-bold mb-3 text-gray-800 text-center">Timelogg</h1>
            <div className="mb-2 flex items-center justify-center p-3 rounded-lg">
                <FaClock className="text-2xl mr-2 text-gray-600" />
                <span className="text-1xl font-bold text-gray-600">{currentTime.format('LLLL')}</span>
            </div>
            {isPunchedIn ? (
                <>
                    <div className="text-lg mb-2 text-gray-700 text-center">
                        Logget Inn: <span className="font-medium">{punchInTime.format('LT')}</span>
                    </div>
                    <div className="text-xl mb-2 text-green-600 text-center">
                        Varighet: <span className="font-semibold">{moment.utc(moment(currentTime, "DD/MM/YYYY HH:mm:ss").diff(moment(punchInTime, "DD/MM/YYYY HH:mm:ss"))).format("HH:mm:ss")}</span>
                    </div>
                    <button onClick={handlePunchOut} className="w-full bg-red-500 hover:bg-red-600 text-white py-3 rounded-full transition duration-300">
                        <FaPause className="inline mr-2" />Stopp
                    </button>
                </>
            ) : (
                <button onClick={handlePunchIn} className="w-full bg-green-500 hover:bg-green-600 text-white py-3 rounded-full transition duration-300">
                    <FaPlay className="inline mr-2" />Start
                </button>
            )}
        </div>
    );
};

export default PunchClock;    