import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomDatePicker from '../../components/CustomDatePicker';
import { ChromePicker } from 'react-color';
import { useNotifications } from '../../context/NotificationContext';
import { useProducts } from '../../hooks';

const AddProduct = () => {
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [periodStart, setPeriodStart] = useState('');
  const [periodEnd, setPeriodEnd] = useState('');
  const [color, setColor] = useState('#ffffff');

  const navigate = useNavigate();
  const { addNotification } = useNotifications();
  const { addProduct } = useProducts();

  const handleSubmit = (e) => {
    e.preventDefault();
    const newProduct = {
      id,
      name,
      description,
      period_start: periodStart.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' }).replace(/\//g, '-'),
      period_end: periodEnd.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' }).replace(/\//g, '-'),
      color,
    };

    addProduct(newProduct, navigate, addNotification);

    // Reset the form
    setId('');
    setName('');
    setDescription('');
    setPeriodStart('');
    setPeriodEnd('');
    setColor('#ffffff');
  };

  return (
    <div className="max-w-lg mx-auto p-8 bg-white rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold mb-8 text-center text-gray-800">Legg til Produkt</h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label htmlFor="id" className="block text-lg font-medium text-gray-700">ID:</label>
          <input
            type="text"
            id="id"
            value={id}
            onChange={(e) => setId(e.target.value)}
            className="mt-2 block w-full border-gray-300 rounded-lg shadow-sm focus:border-indigo-500 focus:ring-indigo-500 transition duration-150 ease-in-out"
            placeholder="Skriv inn produkt-ID"
          />
        </div>
        <div>
          <label htmlFor="name" className="block text-lg font-medium text-gray-700">Navn:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="mt-2 block w-full border-gray-300 rounded-lg shadow-sm focus:border-indigo-500 focus:ring-indigo-500 transition duration-150 ease-in-out"
            placeholder="Skriv inn produktnavn"
          />
        </div>

        <div>
          <label htmlFor="description" className="block text-lg font-medium text-gray-700">Beskrivelse:</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="mt-2 block w-full border-gray-300 rounded-lg shadow-sm focus:border-indigo-500 focus:ring-indigo-500 transition duration-150 ease-in-out"
            placeholder="Skriv inn produktbeskrivelse"
            rows="4"
          ></textarea>
        </div>

        <div className="grid grid-cols-2 gap-6">
          <div>
            <label htmlFor="periodStart" className="block text-lg font-medium text-gray-700">Periode Start:</label>
            <CustomDatePicker
              selectedDate={periodStart}
              onChange={(date) => setPeriodStart(date)}
              className="mt-2 block w-full border-gray-300 rounded-lg shadow-sm focus:border-indigo-500 focus:ring-indigo-500 transition duration-150 ease-in-out"
            />
          </div>

          <div>
            <label htmlFor="periodEnd" className="block text-lg font-medium text-gray-700">Periode Slutt:</label>
            <CustomDatePicker
              selectedDate={periodEnd}
              onChange={(date) => setPeriodEnd(date)}
              className="mt-2 block w-full border-gray-300 rounded-lg shadow-sm focus:border-indigo-500 focus:ring-indigo-500 transition duration-150 ease-in-out"
            />
          </div>
        </div>

        <div>
          <label htmlFor="color" className="block text-lg font-medium text-gray-700">Farge:</label>
          <div className="mt-2">
            <ChromePicker
              color={color}
              onChangeComplete={(color) => setColor(color.hex)}
              className="w-full"
            />
          </div>
        </div>

        <div>
          <button
            type="submit"
            className="w-full bg-indigo-600 text-white py-3 px-4 rounded-lg shadow-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-150 ease-in-out"
          >
            Legg til Produkt
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddProduct;