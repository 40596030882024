import React, { useState, useEffect } from "react";
import ReusableTable from "../../components/ReusableTable";
import Spinner from "../../components/Spinner";
import ReusableHeader from "../../components/ReusableHeader";

function Templates() {
  const [smsTemplates, setSmsTemplates] = useState([]);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [title, setTitle] = useState("Maler");
  const [newPath, setNewPath] = useState("/maler/ny");
  const [activeTab, setActiveTab] = useState('sms');
  const [sort, setSort] = useState("id");
  const [order, setOrder] = useState("DESC");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const apiURL = process.env.REACT_APP_API_BASE_URL || "";

  useEffect(() => {
    const fetchTemplates = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${apiURL}/api/templates/getAll`);
        if (!response.ok) {
          throw new Error("Something went wrong!");
        }
        const data = await response.json();
        console.log(data);
        setSmsTemplates(data.sms);
        setEmailTemplates(data.email);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTemplates();
  }, []);

  useEffect(() => {
    if (activeTab === 'sms') {
      setTitle('SMS Maler');
      setNewPath('/maler/ny/sms');
    } else {
      setTitle('E-post Maler');
      setNewPath('/maler/ny/epost');
    }
  }, [activeTab]);

  const handleSort = (field) => {
    if (sort === field) {
      setOrder(order === "ASC" ? "DESC" : "ASC");
    } else {
      setSort(field);
      setOrder("ASC");
    }
  };

  // Adjust columns as per your gravestone data structure
  const smsColumns = [
    { label: "ID", accessor: "id", width: '50px' },
    { label: "Navn", accessor: "name", link: (row) => `/maler/${row.id}/sms`, width: '150px' },
    { label: "Innhold", accessor: "content", width: '250px' },
    { label: "Standard?", accessor: "is_default", type: "boolean", width: '100px' },
    { label: "Laget", accessor: "created_at", type: "date-time", width: '200px' },
    { label: "Oppdatert", accessor: "updated_at", type: "date-time", width: '200px' },
    // Add more columns as needed
  ];

  const emailColumns = [
    { label: "ID", accessor: "id", width: '50px' },
    {
      label: "Navn",
      accessor: "name",
      link: (row) => `/maler/${row.id}/epost`,
      width: '150px'
    },
    { label: "Innhold", accessor: "content", type: "html", width: '250px' },
    { label: "Standard?", accessor: "is_default", type: "boolean", width: '100px' },
    { label: "Laget", accessor: "created_at", type: "date-time", width: '200px' },
    { label: "Oppdatert", accessor: "updated_at", type: "date-time", width: '200px' },
    // Add more columns as needed
  ];

  if (error)
    return <div className="text-red-500 text-center">Error: {error}</div>;

    return (
        <div className="overflow-x-auto p-4 relative">
          <div className="flex justify-between items-center mb-4">
            <ReusableHeader title={title} linkTo={newPath} />
          </div>
          <div className="mb-4">
            <button
              className={`py-2 px-4 ${activeTab === 'sms' ? 'text-green-grave border-b-2 border-green-grave' : 'text-gray-500'}`}
              onClick={() => setActiveTab('sms')}
            >
              SMS Maler
            </button>
            <button
              className={`py-2 px-4 ${activeTab === 'email' ? 'text-green-grave border-b-2 border-green-grave' : 'text-gray-500'}`}
              onClick={() => setActiveTab('email')}
            >
              E-post Maler
            </button>
          </div>
          {isLoading && <Spinner />}
          {activeTab === 'sms' && (
            <ReusableTable
              columns={smsColumns}
              data={smsTemplates}
              sort={{ field: sort.field, order: sort.order }}
              onSort={handleSort}
            />
          )}
          {activeTab === 'email' && (
            <ReusableTable
              columns={emailColumns}
              data={emailTemplates}
              sort={{ field: sort.field, order: sort.order }}
              onSort={handleSort}
            />
          )}
        </div>
      );
}

export default Templates;
