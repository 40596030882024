import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import Topbar from '../../components/Topbar';
import Toolbar from '../../components/Toolbar';
import ReusableTable from '../../components/ReusableTable';
import Pagination from '../../components/Pagination';
import Spinner from '../../components/Spinner';
import ReusableSearch from '../../components/ReusableSearch';
import ReusableLimit from '../../components/ReusableLimit';
import ReusableActive from '../../components/ReusableActive';
import ReusableHeader from '../../components/ReusableHeader';
import ProductSelect from '../../components/ProductSelect';
import GraveyardPicker from '../../components/GraveyardPicker';
import ImageModal from '../../components/ImageModal';
import ContextMenu from '../../components/ContextMenu';
import { AiOutlineGlobal } from 'react-icons/ai';
import { FaEdit, FaBan, FaCheckCircle } from 'react-icons/fa';

function useWindowSize() {
  const [size, setSize] = useState([window.innerWidth]);
  useEffect(() => {
      const handleResize = () => {
          setSize([window.innerWidth]);
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);
  return size;
}

function Gravestones() {
  const location = useLocation();
  const [width] = useWindowSize();  // Get the current window width
  const isMobile = width < 768;  // Define mobile view threshold

  const [gravestones, setGravestones] = useState([]);
  const [graveyardId, setGraveyardId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('id');
  const [order, setOrder] = useState('ASC');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(isMobile ? 10 : 100);
  const [active, setActive] = useState('all');
  const [product, setProduct] = useState('');
  const [totalPages, setTotalPages] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageSrc, setSelectedImageSrc] = useState('');

  const navigate = useNavigate();

  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  const checkParams = () => {
    const params = new URLSearchParams(location.search);
    const graveyardIdFromURL = params.get('kirkegard');
    if (graveyardIdFromURL) {
      setGraveyardId(parseInt(graveyardIdFromURL));
    }
  };

  const handleSearchChange = (e) => setSearch(e.target.value);
  const handleProductChange = (e) => setProduct(e.target.value);
  const handleLimitChange = (e) => setLimit(e.target.value);
  const handleActiveChange = (e) => setActive(e.target.value);
  const handleGraveyardChange = (e) => setGraveyardId(e.target.value);

  const handleRowRightClick = (row) => {
    const menuItems = [
      {
        label: 'Rediger',
        icon: FaEdit,
        onClick: () => navigate(`/gravsteiner/${row.id}/endre`),
      },
      {
        label: row.active ? 'Deaktiver' : 'Aktiver',
        icon: row.active ? FaBan : FaCheckCircle, // Conditional icon
        iconColor: row.active ? 'text-orange-grave' : 'text-green-grave', // Conditional icon color
        onClick: () => {
          if (row.active) {
            console.log('Deaktiver', row);
            changeActiveStatus(row.id, false);
          } else {
            console.log('Aktiver', row);
            changeActiveStatus(row.id, true);
          }
        },
      },
    ];
  
    return menuItems;
  };

  const changeActiveStatus = async (id, active) => {
    try {
      const response = await fetch(`${apiURL}/api/gravestones/${id}/active`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ active }),
      });
      if (!response.ok) {
        throw new Error('Failed to update active status');
      }
      const data = await response.json();
      console.log('Active status updated:', data);
      fetchGravestones();
    } catch (error) {
      console.error('Failed to update active status:', error);
    }
  };

  const fetchGravestones = async () => {
    setIsLoading(true);
    try {
      const queryParams = new URLSearchParams({ graveyard_id: graveyardId, search, sort, order, page, limit, active, product }).toString();
      const response = await fetch(`${apiURL}/api/gravestones?${queryParams}`);
      if (!response.ok) {
        throw new Error('Something went wrong!');
      }
      const data = await response.json();
      setGravestones(data.gravestones);
      setTotalPages(data.totalPages);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
    checkParams();
  };

  useEffect(() => {
    fetchGravestones();
  }, [graveyardId, search, sort, order, page, limit, active, product]);

  const handleSort = (field) => {
    if (sort === field) {
      setOrder(order === 'ASC' ? 'DESC' : 'ASC');
    } else {
      setSort(field);
      setOrder('ASC');
    }
  };

  const handleThumbnailClick = (thumbnail) => {
    setSelectedImageSrc(`${apiURL}${thumbnail}`);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const daysSinceLastVisit = (latestVisit) => {
    if (!latestVisit) return Infinity; // Return a large number if no visit date
    const today = new Date();
    const lastVisitDate = new Date(latestVisit);
    const difference = today - lastVisitDate;
    return Math.floor(difference / (1000 * 60 * 60 * 24)); // Convert ms to days
  };

  const columns = [
    { label: '', accessor: 'thumbnail', type: 'image', second: 'productColor', width: '60px' },
    { label: 'Personer', accessor: 'gravepersons_names', type: 'names', link: (row) => `/gravsteiner/${row.id}`, width: '200px' },
    { label: 'Kirkegård', accessor: 'graveyard_name', link: (row) => `/kirkegarder/${row.graveyard_id}`, width: '150px' },
    { label: 'Felt', accessor: 'field', width: '50px' },
    { label: 'Rad', accessor: 'row', width: '50px' },
    { label: 'Plass', accessor: 'place', width: '50px' },
    { label: 'Box', accessor: 'box', width: '50px', type: 'measurement' },
    { label: 'Kunde', accessor: 'customer_name', link: (row) => `/kunder/${row.customer_id}`, width: '150px' },
    { label: 'Aktiv', accessor: 'active', type: 'boolean', width: '100px' },
    { label: 'Følger', accessor: 'followed', type: 'boolean', width: '100px' },
    { label: 'Advarsel', accessor: 'warning', width: '100px' },
    { label: 'Legat', accessor: 'legat', width: '50px', type: 'boolean' },
    { label: 'Dager Mellom Besøk', accessor: 'days_between_visits', second: 'latest_visit_date', type: 'days', width: '150px' },
  ];

  if (error) return <div className="text-red-500 text-center">Error: {error}</div>;

  return (
    <div className="p-2 relative">
      {isMobile ? (
        <>
          <header className="fixed top-0 left-0 right-0 bg-white z-10 shadow-md p-2 flex justify-between items-center">
            <h1 className="text-base font-bold">Gravsteiner</h1>
            <div className="flex items-center space-x-2">
              {!graveyardId && (
                <Link to={`/mobil/gravsteiner/kart`} className="text-black hover:text-green-500">
                  <AiOutlineGlobal size="1.5em" />
                </Link>
              )}
              <ReusableSearch value={search} onChange={handleSearchChange} className="ml-2 flex-grow" />
            </div>
          </header>
  
          <div className="pt-14 pb-2 space-y-2 overflow-y-auto">
            {isLoading && <Spinner />}
            {gravestones.map((gravestone) => {
              const days = daysSinceLastVisit(gravestone.latest_visit_date);
              const cardBackgroundColor = days <= 5 ? 'bg-green-200' : 'bg-white';
  
              return (
                <div
                  key={gravestone.id}
                  className={`${cardBackgroundColor} shadow rounded-lg overflow-hidden my-2`}
                  style={{ border: `2px solid ${gravestone.productColor || '#ccc'}` }}
                >
                  <div className="flex items-center">
                    <div className="w-1/4 h-16">
                      <img
                        src={gravestone.thumbnail ? `${apiURL}${gravestone.thumbnail}` : "https://via.placeholder.com/100"}
                        alt={gravestone.gravepersons_names || 'Unknown'}
                        className="object-cover h-full w-full cursor-pointer"
                        onClick={() => handleThumbnailClick(gravestone.picture)}
                      />
                    </div>
                    <div className="p-2 w-3/4">
                      <Link to={`/gravsteiner/${gravestone.id}`} className="text-sm font-semibold block">
                        {gravestone.gravepersons_names}
                        <p className="text-xs text-gray-600">{gravestone.location}</p>
                        <div className="text-xs text-gray-600">
                          <span className="font-medium">Kirkegård:</span> {gravestone.graveyard_name}
                        </div>
                        <div className="text-xs text-gray-600">
                          <span className="font-medium">Kunde:</span> {gravestone.customer_name}
                        </div>
                        <div className="text-xs bg-gray-200 text-black px-1 py-1 rounded-md mt-1">
                          {gravestone.productNames}
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <Pagination
            totalPages={totalPages}
            currentPage={page}
            setPage={setPage}
          />
          <ImageModal
            isOpen={isModalOpen}
            onClose={closeModal}
            imageSrc={selectedImageSrc}
          />
        </>
      ) : (
        <>
          <Topbar>
            <ReusableHeader title="Gravsteiner" />
            <Toolbar>
              <ReusableLimit value={limit} onChange={handleLimitChange} />
              <ProductSelect value={product} onChange={handleProductChange} />
              <GraveyardPicker value={graveyardId} onChange={handleGraveyardChange} />
              <ReusableActive value={active} onChange={handleActiveChange} />
              <ReusableSearch value={search} onChange={handleSearchChange} />
            </Toolbar>
          </Topbar>
          {isLoading && <Spinner />}
          <ReusableTable columns={columns} data={gravestones} sort={{ field: sort, order: order }} onSort={handleSort} onRowRightClick={handleRowRightClick} />
          <Pagination totalPages={totalPages} currentPage={page} setPage={setPage} />
        </>
      )}
    </div>
  );
  
}

export default Gravestones;