import React, { useState, useEffect } from 'react';
import { useCustomer } from '../../context/CustomerContext';
import LoadingScreen from '../../components/LoadingScreen';
import { useNotifications } from '../../context/NotificationContext';
import GravestoneList from '../../components/customer-components/GravestoneList';

const CustomerGravestones = () => {
  const { customer } = useCustomer();
  const { addNotification } = useNotifications();
  const [visits, setVisits] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  if (error) return <div className="text-red-500 text-center">Error: {error}</div>;

  return (
      <div className="bg-white shadow-lg rounded-2xl p-8 max-w-6xl w-full border-t-4 border-green-grave">
        <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">Dine Gravsteiner</h1>
          <GravestoneList fetchId={customer?.id} queryParam="customer_id" showAddNewGravestone={false} showSearch={true} showLimit={true} customLink='/kunde/gravstein/' />
      </div>
  );
};

export default CustomerGravestones;