import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FaBirthdayCake, FaCross } from 'react-icons/fa';
import axios from 'axios';

const VisitCustomerPage = () => {
    const { visitId } = useParams();
  const [visitDetails, setVisitDetails] = useState(null);
  const [activities, setActivities] = useState([]);
  const [gravepersons, setGravepersons] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  useEffect(() => {
    const fetchVisitDetails = async () => {
      try {
        const response = await axios.get(`${apiURL}/api/visits/${visitId}`);
        setVisitDetails(response.data.visitDetails);
        setGravepersons(response.data.gravepersonsDetails);
        setActivities(response.data.selectedActivities); // Change based on how you want to use activities
        setIsLoading(false);
      } catch (err) {
        console.error('Error fetching visit details:', err);
        // Handle error
      }
    };

    fetchVisitDetails();
  }, [visitId]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-gray-200 to-gray-100 p-5">
      <div className="bg-white rounded-xl shadow-2xl overflow-hidden max-w-4xl flex flex-col md:flex-row">
        <div className="p-5 w-full md:w-1/2">
          <h1 className="text-center text-3xl md:text-4xl font-bold text-green-800 mb-10">Stell av Gravsted</h1>
  
          <div className="mb-8">
            <h2 className="text-xl md:text-2xl font-semibold text-green-700">Gravsten Informasjon</h2>
            <div className="mt-4 space-y-4">
              {gravepersons.map((person, index) => (
                <div key={index} className="p-4 md:mr-5 bg-white rounded-lg shadow border border-gray-200">
                  <h3 className="text-lg md:text-xl font-semibold text-green-800">{`${person.first_name} ${person.last_name}`}</h3>
                  <div className="mt-2 flex flex-col items-start space-y-2">
                    <div className="flex items-center space-x-2">
                      <FaBirthdayCake className="text-green-500" />
                      <p className="text-green-700">{person.birthdate ? new Date(person.birthdate).toLocaleDateString('nb-NO') : 'Ukjent fødselsdato'}</p>
                    </div>
                    <div className="flex items-center space-x-2">
                      <FaCross className="text-green-500" />
                      <p className="text-green-700">{person.deathdate ? new Date(person.deathdate).toLocaleDateString('nb-NO') : 'Ukjent dødsdato'}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
  
          <div className="mb-10">
            <h2 className="text-xl md:text-2xl font-semibold text-green-700">Arbeid utført</h2>
            <div className="mt-4 flex flex-wrap gap-4">
              {activities.map((activity) => (
                <span key={activity.id} className="px-4 py-2 bg-green-200 text-green-800 rounded-full shadow hover:bg-green-300 transition duration-300">
                  {activity.name}
                </span>
              ))}
            </div>
          </div>
        </div>
  
        <div className="w-full md:w-1/2 p-5 flex flex-col items-center justify-center">
          <h2 className="text-xl md:text-2xl font-semibold text-green-700 mb-4">Bilde fra Besøket</h2>
          <img
            src={apiURL + visitDetails.after_picture_url}
            alt="Etter besøket"
            className="rounded-lg shadow-lg max-w-full md:max-w-xl md:pr-5"
          />
        </div>
      </div>
      <div className="text-center mt-8">
        <img src="/images/gravstellerne-logo.png" alt="Gravstellerne Logo" className="mx-auto max-w-xs" />
      </div>
    </div>
  );  
};

export default VisitCustomerPage;