import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaSave, FaTimes } from 'react-icons/fa';
import { AiOutlineArrowUp, AiOutlineArrowDown, AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import { useNotifications } from '../../context/NotificationContext';

const NotificationSettings = () => {
  const [duration, setDuration] = useState(5000);
  const [position, setPosition] = useState('bottom-right');
  const [loading, setLoading] = useState(false);
  const { addNotification } = useNotifications();
  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(`${apiURL}/api/site_settings/notification_settings`);
        const data = response.data.setting_value;
        setDuration(data.duration);
        setPosition(data.position);
      } catch (error) {
        console.error('Error fetching notification settings:', error);
      }
    };

    fetchSettings();
  }, [apiURL]);

  const handleSave = async () => {
    setLoading(true);
    const settingValue = { duration, position };
    try {
      await axios.put(`${apiURL}/api/site_settings/notification_settings`, { setting_value: settingValue });
      addNotification({ type: 'success', message: 'Innstillinger oppdatert.' });
    } catch (error) {
      console.error('Error updating notification settings:', error);
      addNotification({ type: 'error', message: 'Kunne ikke oppdatere innstillinger' });
    } finally {
      setLoading(false);
    }
  };

  const handleClear = () => {
    setDuration(5000);
    setPosition('bottom-right');
  };

  return (
    <div>
      <h2 className="text-3xl font-bold mb-6">Varslingsinnstillinger</h2>
      <div className="grid grid-cols-2 gap-6">
        <div className="mb-6">
          <label className="block text-lg font-semibold mb-2">Varighet (ms):</label>
          <input
            type="number"
            value={duration}
            onChange={(e) => setDuration(Number(e.target.value))}
            className="w-full p-3 border border-gray-300 rounded-md focus:ring focus:ring-green-300"
          />
        </div>
        <div className="mb-6">
          <label className="block text-lg font-semibold mb-2">Posisjon:</label>
          <div className="relative w-64 h-64 border border-gray-300 bg-gray-100 mx-auto grid grid-cols-3 grid-rows-3 gap-2">
            <PositionButton
              position="top-left"
              current={position}
              setPosition={setPosition}
              icon={<AiOutlineArrowUp className="transform -rotate-45 text-white" />}
            />
            <PositionButton
              position="top-center"
              current={position}
              setPosition={setPosition}
              icon={<AiOutlineArrowUp className="text-white" />}
            />
            <PositionButton
              position="top-right"
              current={position}
              setPosition={setPosition}
              icon={<AiOutlineArrowUp className="transform rotate-45 text-white" />}
            />
            <PositionButton
              position="center-left"
              current={position}
              setPosition={setPosition}
              icon={<AiOutlineArrowLeft className="text-white" />}
            />
            <PositionButton
              position="center"
              current={position}
              setPosition={setPosition}
              icon={<div className="w-4 h-4 bg-white rounded-full" />}
            />
            <PositionButton
              position="center-right"
              current={position}
              setPosition={setPosition}
              icon={<AiOutlineArrowRight className="text-white" />}
            />
            <PositionButton
              position="bottom-left"
              current={position}
              setPosition={setPosition}
              icon={<AiOutlineArrowDown className="transform rotate-45 text-white" />}
            />
            <PositionButton
              position="bottom-center"
              current={position}
              setPosition={setPosition}
              icon={<AiOutlineArrowDown className="text-white" />}
            />
            <PositionButton
              position="bottom-right"
              current={position}
              setPosition={setPosition}
              icon={<AiOutlineArrowDown className="transform -rotate-45 text-white" />}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center space-x-4 mt-6">
        <button
          onClick={handleSave}
          disabled={loading}
          className="flex items-center p-3 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors disabled:opacity-50"
        >
          <FaSave className="mr-2" />
          Lagre
        </button>
        <button
          onClick={handleClear}
          className="flex items-center p-3 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors"
        >
          <FaTimes className="mr-2" />
          Resett
        </button>
      </div>
    </div>
  );
};

const PositionButton = ({ position, current, setPosition, icon }) => (
  <div
    className={`flex items-center justify-center cursor-pointer ${current === position ? 'bg-green-500' : 'bg-gray-500'}`}
    onClick={() => setPosition(position)}
  >
    {icon}
  </div>
);

export default NotificationSettings;