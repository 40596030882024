import React, { useEffect, useState } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import { useParams, Link } from 'react-router-dom';
import { useUser } from '../../context/UserContext';
import { useUsers } from '../../hooks';
import { useLogs } from '../../hooks/useLogs'; // Import the useLogs hook
import { 
  FaUserCircle, 
  FaEnvelope, 
  FaCalendarAlt, 
  FaUserTag, 
  FaCalendarCheck, 
  FaEdit, 
  FaSignOutAlt 
} from 'react-icons/fa';

const UserProfile = () => {
  const { id } = useParams();
  const [personalProfile, setPersonalProfile] = useState(false);

  const { profile, fetchUserById } = useUsers();
  const { logs, fetchLogsByUserId, isLoading: logsLoading } = useLogs(); // Destructure logs and fetchLogsByUserId
  const { user } = useUser();

  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  useEffect(() => {
    fetchUserById(id);
    fetchLogsByUserId(id); // Fetch logs by user id when the component mounts
    if (user && user.id === parseInt(id)) {
      setPersonalProfile(true);
    }
  }, [id]);

  if (!profile) {
    return <div>Loading user profile...</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100 p-2 lg:p-6">
      <div className="max-w-7xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="bg-gradient-to-r from-green-grave to-orange-grave p-6 text-white flex justify-between items-center">
          <h2 className="text-2xl font-bold">Profil</h2>
          {personalProfile && (
            <div className="flex">
              <Link 
                to={`/brukere/${id}/endre`} 
                className="flex items-center p-2 hover:bg-green-grave rounded-full text-white space-x-2"
              >
                <FaEdit />
                <span className="font-bold text-sm">Endre</span>
              </Link>
              <Link 
                to="/loggut" 
                className="ml-3 flex items-center p-2 hover:bg-green-grave rounded-full text-white space-x-2"
              >
                <FaSignOutAlt />
                <span className="font-bold text-sm">Logg Ut</span>
              </Link>
            </div>
          )}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 lg:gap-6 p-6">
          <div className="col-span-1 bg-gray-100 p-4 rounded-lg">
          <div className="flex flex-col items-center">
  {profile.picture ? (
    <img
      src={apiURL + '/' + profile.picture}
      alt={`${profile.name}'s profile`}
      className="w-36 h-36 rounded-full object-cover shadow-md"
    />
  ) : (
    <FaUserCircle className="text-gray-400 text-9xl" />
  )}
  <h3 className="mt-4 text-3xl font-semibold text-gray-700">{profile.name}</h3>
  <p className="text-green-grave">
    {profile.type === 'worker' ? 'Arbeider' : 'Admin'}
  </p>
</div>
            <div className="mt-6">
              <div className="flex items-center justify-start my-3">
                <FaEnvelope className="text-green-grave mr-3" />
                <span className="text-gray-700">{profile.email}</span>
              </div>
              <div className="flex items-center justify-start my-3">
                <FaCalendarAlt className="text-green-grave mr-3" />
                <span className="text-gray-700">Opprettet: {new Date(profile.created_at).toLocaleString('nb-NO')}</span>
              </div>
              <div className="flex items-center justify-start my-3">
                <FaCalendarCheck className="text-green-grave mr-3" />
                <span className="text-gray-700">Sist Innlogget: {new Date(profile.last_login).toLocaleString('nb-NO')}</span>
              </div>
            </div>
          </div>
          <div className="col-span-2 bg-white p-6 rounded-lg shadow-md">
  <h3 className="text-2xl font-bold text-gray-700">Brukeraktivitet</h3>
  <p className="text-gray-600 mt-2">Siste aktiviteter og logger</p>
  <div className="bg-gray-50 mt-4 p-4 rounded-lg border border-gray-200 h-60 max-h-60 overflow-y-auto">
    {logsLoading ? (
      <p className="text-gray-500">Laster aktiviteter...</p>
    ) : logs.length > 0 ? (
      <ul className="space-y-3">
        {logs.map(log => (
          <li key={log.id} className="flex items-start">
            <span className="font-semibold text-gray-700 mr-2">
              {new Date(log.created_at).toLocaleString('nb-NO')}:
            </span>
            <span className="text-gray-600">{log.message}</span>
          </li>
        ))}
      </ul>
    ) : (
      <p className="text-gray-500">Ingen nylige aktiviteter.</p>
    )}
  </div>
</div>
        </div>
        <div className="mt-6 p-6">
          <h3 className="text-2xl font-bold text-gray-700">Kommentarer</h3>
          <div className="bg-white p-4 mt-4 rounded-lg">
            <p className="text-gray-600">Ingen kommentarer.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;