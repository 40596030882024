import React, { useState } from 'react';

const ToggleSwitch = ({ initialState = false, onToggle }) => {
  const [isActive, setIsActive] = useState(initialState);

  const handleToggle = () => {
    const newState = !isActive;
    setIsActive(newState);
    if (onToggle) {
      onToggle(newState);
    }
  };

  return (
    <div className="relative inline-block w-24 h-10 cursor-pointer" onClick={handleToggle}>
      <div className={`absolute inset-0 rounded-full transition-colors duration-300 ${isActive ? 'bg-green-grave' : 'bg-gray-300'}`}></div>
      <div
        className={`absolute inset-y-0 left-0 w-1/2 h-full rounded-full bg-white shadow-md transform transition-transform duration-300 ${isActive ? 'translate-x-full' : ''}`}
      ></div>
      <span
        className={`absolute left-2 top-1/2 transform -translate-y-1/2 text-sm font-semibold transition-opacity duration-300 ${
          isActive ? 'opacity-100 text-white' : 'opacity-0'
        }`}
      >
        Aktiv
      </span>
      <span
        className={`absolute right-1 top-1/2 transform -translate-y-1/2 text-sm font-semibold transition-opacity duration-300 ${
          isActive ? 'opacity-0' : 'opacity-100 text-gray-700'
        }`}
      >
        Inaktiv
      </span>
    </div>
  );
};

export default ToggleSwitch;