import React, { useState, useEffect, useMemo } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useUser } from './context/UserContext';
import { useAdminView } from './context/AdminViewContext';
import LoadingScreen from './components/LoadingScreen';
import ProtectedRoute from './components/ProtectedRoute';
import ProtectedRouteCustomer from './components/ProtectedRouteCustomer';
import UserLayout from './layouts/UserLayout';
import CustomerLayout from './layouts/CustomerLayout';
import AdminViewCustomerLayout from './layouts/AdminViewCustomerLayout';
import LayoutWrapper from './layouts/LayoutWrapper';
import CustomerDashboard from './pages/customer-pages/CustomerDashboard';
import CustomerVisits from './pages/customer-pages/CustomerVisits';
import CustomerVisitPage from './pages/customer-pages/CustomerVisitPage';
import CustomerGravestones from './pages/customer-pages/CustomerGravestones';
import CustomerGravestoneProfile from './pages/customer-pages/CustomerGravestoneProfile';
import CustomerProfile from './pages/customer-pages/CustomerProfile';
import CustomerLogOut from './pages/customer-pages/CustomerLogOut';

import Dashboard from './pages/Dashboard';
import Customers from './pages/customers/Customers';
import AddCustomer from './pages/customers/AddCustomer';
import CustomerDetails from './pages/customers/CustomerDetails';
import Graveyards from './pages/graveyards/Graveyards';
import AddGraveyard from './pages/graveyards/AddGraveyard';
import GraveyardProfile from './pages/graveyards/GraveyardProfile';
import GraveyardProfileMobile from './pages/graveyards/GraveyardProfileMobile';
import Gravestones from './pages/gravestones/Gravestones';
import GravestoneProfile from './pages/gravestones/GravestoneProfile';
import AddGravestone from './pages/gravestones/AddGravestone';
import EditGravestone from './pages/gravestones/EditGravestone';
import Gravepersons from './pages/gravestones/Gravepersons';
import AddGraveperson from './pages/gravestones/AddGraveperson';
import GravepersonDetail from './pages/gravestones/GravepersonDetail';
import Workorders from './pages/workorders/Workorders';
import WorkOrderForm from './pages/workorders/WorkorderForm';
import WorkordersUser from './pages/workorders/WorkordersUser';
import WorkOrderDetailsPage from './pages/workorders/WorkOrderDetailsPage';
import Visits from './pages/visits/Visits';
import VisitsThumbsDown from './pages/visits/VisitsThumbsDown';
import VisitCustomerPage from './pages/visits/VisitCustomerPage';
import VisitEditPage from './pages/visits/VisitEditPage';
import VisitDetailPage from './pages/visits/VisitDetailPage';
import LegacyVisitDetailPage from './pages/visits/LegacyVisitDetailPage';
import VisitComments from './pages/visits/VisitComments';
import Users from './pages/users/Users';
import UserProfile from './pages/users/UserProfile';
import CreateUser from './pages/users/CreateUser';
import EditUser from './pages/users/EditUser';
import UserProfileEdit from './pages/users/UserProfileEdit';
import SmsLogs from './pages/Logs/SmsLogs';
import EmailLogs from './pages/Logs/EmailLogs';
import Templates from './pages/templates/Templates';
import CreateEmailTemplate from './pages/templates/CreateEmailTemplate';
import CreateSMSTemplate from './pages/templates/CreateSMSTemplate';
import EditEmailTemplate from './pages/templates/EditEmailTemplate';
import EditSMSTemplate from './pages/templates/EditSMSTemplate';
import Activities from './pages/activities/Activities';
import AddActivity from './pages/activities/AddActivity';
import ViewActivity from './pages/activities/ViewActivity';
import EditActivity from './pages/activities/EditActivity';
import Products from './pages/products/Products';
import AddProduct from './pages/products/AddProduct';
import ViewProduct from './pages/products/ViewProduct';
import Departments from './pages/departments/Departments';
import EditDepartment from './pages/departments/EditDepartment';
import CreateDepartment from './pages/departments/CreateDepartment';
import Login from './pages/Login';
import CustomerLogin from './pages/CustomerLogin';
import DownloadImagesPage from './pages/tools/DownloadImagesPage';
import Slideshow from './pages/Slideshow';
import Logout from './pages/Logout';
import Settings from './pages/settings/Settings';

import GraveyardsMobile from './pages/graveyards/GraveyardsMobile';
import GravestonesMobile from './pages/gravestones/GravestonesMobile';
import GravestonesMapMobile from './pages/gravestones/GravestoneMapMobile';
import MyVisits from './pages/visits/MyVisits';

import UserGuidePage from './pages/UserGuidePage';

const AppContent = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const { login } = useUser();
  const { isAdminView } = useAdminView();
  const apiURL = useMemo(() => process.env.REACT_APP_API_BASE_URL || '', []);

  useEffect(() => {
    const refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken) {
      fetch(`${apiURL}/token`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: refreshToken }),
      })
        .then(res => res.json())
        .then(data => {
          if (data.accessToken) {
            localStorage.setItem('accessToken', data.accessToken.accessToken);
            login({ id: data.accessToken.userId, name: data.accessToken.userName, type: data.accessToken.userType, accessToken: data.accessToken.accessToken });
          }
          setIsLoading(false);
        })
        .catch(error => {
          console.error('Refresh token invalid', error);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [apiURL]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Routes>
      <Route path="/logginn" element={<Login />} />
      <Route path="/kunde/logginn" element={<CustomerLogin />} />
      <Route path="/ditt-besok/:visitId" element={<VisitCustomerPage />} />
      <Route path="/last-ned-bilder" element={<DownloadImagesPage />} />
      <Route path="/slideshow" element={<Slideshow />} />
      <Route path="/" element={<ProtectedRoute element={<UserLayout><Dashboard /></UserLayout>} />} />
      <Route path="/kunder" element={<ProtectedRoute element={<UserLayout><Customers /></UserLayout>} />} />
      <Route path="/kunder/ny" element={<ProtectedRoute element={<UserLayout><AddCustomer /></UserLayout>} />} />
      <Route path="/kunder/:id" element={<ProtectedRoute element={<UserLayout><CustomerDetails /></UserLayout>} />} />
      <Route path="/kirkegarder" element={<ProtectedRoute element={<UserLayout><Graveyards /></UserLayout>} />} />
      <Route path="/kirkegarder/ny" element={<ProtectedRoute element={<UserLayout><AddGraveyard /></UserLayout>} />} />
      <Route path="/kirkegarder/:id" element={<ProtectedRoute element={<UserLayout><GraveyardProfile /></UserLayout>} />} />
      <Route path="/gravsteiner" element={<ProtectedRoute element={<UserLayout><Gravestones /></UserLayout>} />} />
      <Route path="/gravsteiner/ny/:customerId" element={<ProtectedRoute element={<UserLayout><AddGravestone /></UserLayout>} />} />
      <Route path="/gravsteiner/:id" element={<ProtectedRoute element={<UserLayout><GravestoneProfile /></UserLayout>} />} />
      <Route path="/gravsteiner/:id/endre" element={<ProtectedRoute element={<UserLayout><EditGravestone /></UserLayout>} />} />
      <Route path="/gravpersoner" element={<ProtectedRoute element={<UserLayout><Gravepersons /></UserLayout>} />} />
      <Route path="/gravpersoner/:id" element={<ProtectedRoute element={<UserLayout><GravepersonDetail /></UserLayout>} />} />
      <Route path="/gravpersoner/ny/:gravestoneId" element={<ProtectedRoute element={<UserLayout><AddGraveperson /></UserLayout>} />} />
      <Route path="/arbeidslister" element={<ProtectedRoute element={<UserLayout><Workorders /></UserLayout>} />} />
      <Route path="/arbeidslister/ny" element={<ProtectedRoute element={<UserLayout><WorkOrderForm /></UserLayout>} />} />
      <Route path="/arbeidslister/:id" element={<ProtectedRoute element={<UserLayout><WorkOrderDetailsPage /></UserLayout>} />} />
      <Route path="/mine-arbeidslister" element={<ProtectedRoute element={<UserLayout><WorkordersUser /></UserLayout>} />} />
      <Route path="/besok" element={<ProtectedRoute element={<UserLayout><Visits /></UserLayout>} />} />
      <Route path="/besok/tommel-ned" element={<ProtectedRoute element={<UserLayout><VisitsThumbsDown /></UserLayout>} />} />
      <Route path="/besok/:visitId/detaljer/:isLegacy" element={<ProtectedRoute element={<UserLayout><VisitDetailPage /></UserLayout>} />} />
      <Route path="/besok/:visitId/legacy" element={<ProtectedRoute element={<UserLayout><LegacyVisitDetailPage /></UserLayout>} />} />
      <Route path="/besok/:visitId/endre" element={<ProtectedRoute element={<UserLayout><VisitEditPage /></UserLayout>} />} />
      <Route path="/tilbakemeldinger" element={<ProtectedRoute element={<UserLayout><VisitComments /></UserLayout>} />} />
      <Route path="/brukere" element={<ProtectedRoute element={<UserLayout><Users /></UserLayout>} />} />
      <Route path="/brukere/ny" element={<ProtectedRoute element={<UserLayout><CreateUser /></UserLayout>} />} />
      <Route path="/brukere/:id" element={<ProtectedRoute element={<UserLayout><UserProfile /></UserLayout>} />} />
      <Route path="/brukere/:id/endre" element={<ProtectedRoute element={<UserLayout><EditUser /></UserLayout>} />} />
      <Route path="/profil/:id/endre" element={<ProtectedRoute element={<UserLayout><UserProfileEdit /></UserLayout>} />} />
      <Route path="/sms-logger" element={<ProtectedRoute element={<UserLayout><SmsLogs /></UserLayout>} />} />
      <Route path="/epost-logger" element={<ProtectedRoute element={<UserLayout><EmailLogs /></UserLayout>} />} />
      <Route path="/maler" element={<ProtectedRoute element={<UserLayout><Templates /></UserLayout>} />} />
      <Route path="/maler/ny/epost" element={<ProtectedRoute element={<UserLayout><CreateEmailTemplate /></UserLayout>} />} />
      <Route path="/maler/ny/sms" element={<ProtectedRoute element={<UserLayout><CreateSMSTemplate /></UserLayout>} />} />
      <Route path="/maler/:id/epost" element={<ProtectedRoute element={<UserLayout><EditEmailTemplate /></UserLayout>} />} />
      <Route path="/maler/:id/sms" element={<ProtectedRoute element={<UserLayout><EditSMSTemplate /></UserLayout>} />} />
      <Route path="/aktiviteter" element={<ProtectedRoute element={<UserLayout><Activities /></UserLayout>} />} />
      <Route path="/aktiviteter/ny" element={<ProtectedRoute element={<UserLayout><AddActivity /></UserLayout>} />} />
      <Route path="/aktiviteter/:id" element={<ProtectedRoute element={<UserLayout><ViewActivity /></UserLayout>} />} />
      <Route path="/aktiviteter/:id/endre" element={<ProtectedRoute element={<UserLayout><EditActivity /></UserLayout>} />} />
      <Route path="/produkter" element={<ProtectedRoute element={<UserLayout><Products /></UserLayout>} />} />
      <Route path="/produkter/ny" element={<ProtectedRoute element={<UserLayout><AddProduct /></UserLayout>} />} />
      <Route path="/produkter/:id" element={<ProtectedRoute element={<UserLayout><ViewProduct /></UserLayout>} />} />
      <Route path="/avdelinger" element={<ProtectedRoute element={<UserLayout><Departments /></UserLayout>} />} />
      <Route path="/avdelinger/ny" element={<ProtectedRoute element={<UserLayout><CreateDepartment /></UserLayout>} />} />
      <Route path="/avdelinger/:id/endre" element={<ProtectedRoute element={<UserLayout><EditDepartment /></UserLayout>} />} />
      <Route path="/innstillinger" element={<ProtectedRoute element={<UserLayout><Settings /></UserLayout>} />} />
      <Route path="/loggut" element={<Logout />} />
      
      <Route path="/mobil/kirkegarder" element={<ProtectedRoute element={<UserLayout><GraveyardsMobile /></UserLayout>} />} />
      <Route path="/mobil/kirkegarder/:id" element={<ProtectedRoute element={<UserLayout><GraveyardProfileMobile /></UserLayout>} />} />
      <Route path="/mobil/gravsteiner" element={<ProtectedRoute element={<UserLayout><GravestonesMobile /></UserLayout>} />} />
      <Route path="/mobil/gravsteiner/kirkegard/:graveyardId" element={<ProtectedRoute element={<UserLayout><GravestonesMobile /></UserLayout>} />} />
      <Route path="/mobil/gravsteiner/kunde/:customerId" element={<ProtectedRoute element={<UserLayout><GravestoneProfile /></UserLayout>} />} />
      <Route path="/mobil/gravsteiner/kirkegard/:graveyardId/kart" element={<ProtectedRoute element={<UserLayout><GravestonesMapMobile /></UserLayout>} />} />
      <Route path="/mobil/gravsteiner/kirkegard/:customerId/kart" element={<ProtectedRoute element={<UserLayout><GravestonesMapMobile /></UserLayout>} />} />
      <Route path="/mobil/gravsteiner/kart" element={<ProtectedRoute element={<UserLayout><GravestonesMapMobile /></UserLayout>} />} />
      <Route path="/mine-besok" element={<ProtectedRoute element={<UserLayout><MyVisits /></UserLayout>} />} />

      <Route path="/brukerveiledning" element={<ProtectedRoute element={<UserLayout><UserGuidePage /></UserLayout>} />} />

      <Route path="/kunde" element={<ProtectedRouteCustomer element={<LayoutWrapper element={<CustomerDashboard />} />} />} />
      <Route path="/kunde/besok" element={<ProtectedRouteCustomer element={<LayoutWrapper element={<CustomerVisits />} />} />} />
      <Route path="/kunde/besok/gravstein/:gravestoneId" element={<ProtectedRouteCustomer element={<LayoutWrapper element={<CustomerVisits />} />} />} />
      <Route path="/kunde/besok/:visitId" element={<ProtectedRouteCustomer element={<LayoutWrapper element={<CustomerVisitPage />} />} />} />
      <Route path="/kunde/gravsteiner" element={<ProtectedRouteCustomer element={<LayoutWrapper element={<CustomerGravestones />} />} />} />
      <Route path="/kunde/gravstein/:id" element={<ProtectedRouteCustomer element={<LayoutWrapper element={<CustomerGravestoneProfile />} />} />} />
      <Route path="/kunde/profil" element={<ProtectedRouteCustomer element={<LayoutWrapper element={<CustomerProfile />} />} />} />
      <Route path="/kunde/loggut" element={<CustomerLogOut />} />
        {/* Add other routes as needed */}
    </Routes>
  );
};

export default AppContent;