import React, { useState, useEffect } from 'react';
import Topbar from '../../components/Topbar';
import Toolbar from '../../components/Toolbar';
import ReusableTable from '../../components/ReusableTable';
import Pagination from '../../components/Pagination';
import Spinner from '../../components/Spinner';
import ReusableSearch from '../../components/ReusableSearch';
import ReusableLimit from '../../components/ReusableLimit';
import ReusableHeader from '../../components/ReusableHeader';

function Gravepersons() {
  const [gravepersons, setGravepersons] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('first_name');
  const [order, setOrder] = useState('ASC');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  // Event handlers remain within the parent component
  const handleSearchChange = (e) => setSearch(e.target.value);
  const handleLimitChange = (e) => setLimit(e.target.value);

  useEffect(() => {
    const fetchGravepersons = async () => {
      setIsLoading(true);
      try {
        const queryParams = new URLSearchParams({ search, sort, order, page, limit }).toString();
        const response = await fetch(`${apiURL}/api/gravepersons?${queryParams}`);
        if (!response.ok) {
          throw new Error('Something went wrong!');
        }
        const data = await response.json();
        setGravepersons(data.gravepersons);
        setTotalPages(data.totalPages);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchGravepersons();
  }, [search, sort, order, page, limit]);

  const handleSort = (field) => {
    if (sort === field) {
      setOrder(order === 'ASC' ? 'DESC' : 'ASC');
    } else {
      setSort(field);
      setOrder('ASC');
    }
  };

  // Adjust columns as per your graveperson data structure
  const columns = [
    { label: '', accessor: 'gravestone_thumbnail', type: 'image', width: '60px' },
    { label: 'Fornavn', accessor: 'first_name', link: (row) => `/gravpersoner/${row.id}`, width: '150px' },
    { label: 'Etternavn', accessor: 'last_name', link: (row) => `/gravpersoner/${row.id}`, width: '150px' },
    { label: 'Født', accessor: 'birthdate', type: "date", width: '150px' },
    { label: 'Døde', accessor: 'deathdate', type: "date", width: '150px' },
    { label: 'Gravstein ID', accessor: 'gravestone_id', link: (row) => `/gravsteiner/${row.gravestone_id}`, width: '100px' }, // This assumes your API provides a count of gravepersons
    { label: 'Kirkegård', accessor: 'graveyard_name', width: '150px' },
    // Add more columns as needed
  ];

  if (error) return <div className="text-red-500 text-center">Error: {error}</div>;

  return (
    <div className="overflow-x-auto p-4 relative">
      <Topbar>
        <ReusableHeader title="Gravpersoner" />
        {/* Rest of the component similar to Customers component */}
        <Toolbar>
        <ReusableLimit value={limit} onChange={handleLimitChange} />
        <ReusableSearch value={search} onChange={handleSearchChange} />
        </Toolbar>
      </Topbar>
      {isLoading && <Spinner />}
      <ReusableTable columns={columns} data={gravepersons} sort={{ field: sort, order: order }} onSort={handleSort} />
      <Pagination totalPages={totalPages} currentPage={page} setPage={setPage} />
    </div>
  );
}

export default Gravepersons;