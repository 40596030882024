import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Topbar from '../../components/Topbar';
import Toolbar from '../../components/Toolbar';
import ReusableTable from '../../components/ReusableTable';
import Spinner from '../../components/Spinner';
import ReusableHeader from '../../components/ReusableHeader';

function Departments() {
  const [departments, setDepartments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sort, setSort] = useState('created_at');
  const [order, setOrder] = useState('DESC');

  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  useEffect(() => {
    const fetchDepartments = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${apiURL}/api/departments`);
        if (!response.ok) {
          throw new Error('Something went wrong!');
        }
        const data = await response.json();
        setDepartments(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDepartments();
  }, []);

  const handleSort = (field) => {
    if (sort === field) {
      setOrder(order === 'ASC' ? 'DESC' : 'ASC');
    } else {
      setSort(field);
      setOrder('ASC');
    }
  };

  // Adjust columns as per your gravestone data structure
  const columns = [
    { label: 'ID', accessor: 'id', link: (row) => `/avdelinger/${row.id}/endre`, width: '50px' },
    { label: 'Navn', accessor: 'name', width: '150px' },
    { label: 'Beskrivelse', accessor: 'description', width: '250px' },
    { label: 'Aktiv?', accessor: 'active', type: 'boolean', width: '100px' },
    { label: 'Opprettet', accessor: 'created_at', type: 'date-time', width: '200px' },
    { label: 'Oppdatert', accessor: 'updated_at', type: 'date-time', width: '200px' },
    // Add more columns as needed
  ];

  if (error) return <div className="text-red-500 text-center">Error: {error}</div>;

  return (
    <div className="overflow-x-auto p-4 relative">
      <Topbar>
        <ReusableHeader title="Avdelinger" linkTo="/avdelinger/ny" />
        <Toolbar></Toolbar>
      </Topbar>
      {isLoading && <Spinner />}
      <ReusableTable columns={columns} data={departments} sort={{ field: sort, order: order }} onSort={handleSort} />
    </div>
  );
}

export default Departments;