import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBookOpen, FaMonument } from 'react-icons/fa';
import axiosInstance from '../../utils/axiosInstance';
import { useCustomer } from '../../context/CustomerContext';

const CustomerDashboard = () => {
  const { customer } = useCustomer();
  const [gravestoneCount, setGravestoneCount] = useState(0);
  const [visitCount, setVisitCount] = useState(0);
  const [error, setError] = useState(null);

  const getCurrentYear = () => {
    const date = new Date();
    return date.getFullYear().toString();
  };

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axiosInstance.get(`/api/stats/visits-and-gravestones/${customer.id}?year=${getCurrentYear()}`);
        setGravestoneCount(response.data.gravestoneCount);
        setVisitCount(response.data.visitCount);
      } catch (error) {
        console.error('Error fetching customer stats:', error);
        setError('Kunne ikke hente data');
      }
    };

    if (customer?.id) {
      fetchStats();
    }
  }, [customer?.id]);

  return (
    <div className="bg-white shadow-lg rounded-2xl p-8 max-w-6xl w-full border-t-4 border-green-grave">
      <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">Velkommen, {customer.name}</h1>
      <p className="text-gray-600 mb-8 text-center">
        Her kan du se besøkslogg og gravsteiner tilknyttet deg.
      </p>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
        <Link to="/kunde/gravsteiner" id="gravsteiner" className="bg-white border-4 border-green-grave text-gray-800 cursor-pointer rounded-xl p-6 shadow-md transition transform hover:scale-105 flex flex-col items-center text-center">
          <FaMonument className="text-5xl mb-4" />
          <h2 className="text-xl font-semibold">Gravsteiner</h2>
          <p className="mt-2 mb-4">Se gravsteiner tilknyttet deg.</p>
          <p className="text-lg text-green-grave font-bold">
            Du har {gravestoneCount} {gravestoneCount === 1 ? 'gravstein' : 'gravsteiner'}
          </p>
        </Link>
        <Link to="/kunde/besok" id="besok" className="bg-white border-4 border-green-grave text-gray-800 cursor-pointer rounded-xl p-6 shadow-md transition transform hover:scale-105 flex flex-col items-center text-center">
          <FaBookOpen className="text-5xl mb-4" />
          <h2 className="text-xl font-semibold">Besøk</h2>
          <p className="mt-2 mb-4">Se besøkslogg tilknyttet deg.</p>
          <p className="text-lg text-green-grave font-bold">Vi har utført {visitCount} besøk</p> {/* Display visit count here */}
        </Link>
      </div>
      {error && <p className="text-red-500 text-center">{error}</p>}
      <p className="text-gray-600 text-center">
        Trenger du å komme i kontakt med oss? Send oss en e-post da vel: <a href="mailto:kunde@gravstellerne.no" className="text-green-grave">kunde@gravstellerne.no</a>
      </p>
    </div>
  );
};

export default CustomerDashboard;