import React from 'react';

const Pagination = ({ totalPages, currentPage, setPage }) => {
    const range = (start, end) => {
        return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    };

    let pageNumbers = [1]; // Always start with the first page

    // Determine the dynamic range based on currentPage
    if (totalPages > 1) {
        if (currentPage <= 4) {
            let endPage = Math.min(5, totalPages - 1); // Ensure we don't go beyond totalPages
            pageNumbers.push(...range(2, endPage), '...');
        } else if (currentPage >= totalPages - 3) {
            pageNumbers.push('...', ...range(totalPages - 4, totalPages - 1));
        } else {
            pageNumbers.push('...', ...range(currentPage - 1, currentPage + 1), '...');
        }
        pageNumbers.push(totalPages); // Always end with the last page
    }

    return (
        <div className="flex space-x-2 justify-center pt-4">
            {pageNumbers.map((number, index) => (
                typeof number === 'number' ? (
                    <button
                        key={number} // numbers are inherently unique
                        className={`px-4 py-2 ${number === currentPage ? 'bg-orange-grave text-white' : 'bg-green-grave text-white'} rounded hover:bg-orange-grave transition-colors`}
                        onClick={() => setPage(number)}
                    >
                        {number}
                    </button>
                ) : (
                    // Use a fixed key for ellipses as their position (start or end) makes them unique
                    <span key={`ellipsis-${index}`}>...</span>
                )
            ))}
        </div>
    );
};

export default Pagination;