import React, { useEffect, useState } from 'react';

const CharacterCounter = ({ text = '', maxCharactersPerSMS = 160 }) => {
  const [smsCount, setSmsCount] = useState(1);

  useEffect(() => {
    const urlPlaceholder = '{{visit_url}}';
    const fullUrlLength = 'https://cms.gravstellerne.no/visitinfo/00000'.length;

    const calculateSMSCount = (text) => {
      // Replace all occurrences of {{visit_url}} with the actual URL length
      const adjustedText = text.replace(new RegExp(urlPlaceholder, 'g'), ' '.repeat(fullUrlLength));
      return Math.ceil(adjustedText.length / maxCharactersPerSMS);
    };

    setSmsCount(calculateSMSCount(text));
  }, [text, maxCharactersPerSMS]);

  return (
    <div className="mt-2 text-sm text-gray-600">
      {text.replace(new RegExp('{{visit_url}}', 'g'), 'https://cms.gravstellerne.no/visitinfo/00000').length} tegn ({smsCount} SMS)
    </div>
  );
};

export default CharacterCounter;