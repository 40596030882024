import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import PlaceholderInserter from '../../components/settings/PlaceholderInserter';
import { useNotifications } from '../../context/NotificationContext';

const ContactSettings = () => {
  const [activeTab, setActiveTab] = useState('email');
  const [emailSubject, setEmailSubject] = useState('');
  const [emailContent, setEmailContent] = useState('');
  const [smsContent, setSmsContent] = useState('');
  const [customers, setCustomers] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(9999);
  const [totalPages, setTotalPages] = useState(1);
  const [sendToAll, setSendToAll] = useState(false);
  const [focusedField, setFocusedField] = useState('');
  const [sending, setSending] = useState(false);

  const { addNotification } = useNotifications();

  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  const emailSubjectRef = useRef(null);
  const smsContentRef = useRef(null);
  const emailQuillRef = useRef(null);

  useEffect(() => {
    fetchCustomers();
  }, [search, page]);

  const fetchCustomers = async () => {
    try {
      const response = await axios.get(`${apiURL}/api/customers`, {
        params: {
          search,
          page,
        limit,
        },
      });
      setCustomers(response.data.customers);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Failed to fetch customers', error);
    }
  };

  const handleEmailSubmit = async (e) => {
    setSending(true);
    e.preventDefault();
    try {
      const recipientEmails = sendToAll ? customers.map((c) => c.email).join(',') : selectedCustomers.map((c) => c.email).join(',');
      await axios.post(`${apiURL}/api/send-email`, {
        to: recipientEmails,
        subject: emailSubject,
        html: emailContent,
        email_type: 'custom',
        related_id: null,
        from: 'kunde@gravstellerne.no',
      });
      setSending(false);
      addNotification({ type: 'success', message: 'E-post(er) ble sendt.' });
    } catch (error) {
      setSending(false);
      console.error('Failed to send email', error);
      addNotification({ type: 'error', message: 'Kunne ikke sende e-post' });
    }
  };

  const handleSmsSubmit = async (e) => {
    setSending(true);
    e.preventDefault();
    try {
      const recipientPhones = sendToAll ? customers.map((c) => c.phone_number).join(',') : selectedCustomers.map((c) => c.phone_number).join(',');
      await axios.post(`${apiURL}/api/send-sms`, {
        to: recipientPhones,
        body: smsContent,
        sms_type: 'custom',
        related_id: null,
      });
      setSending(false);
      addNotification({ type: 'success', message: 'SMS ble sendt.' });
    } catch (error) {
      setSending(false);
      console.error('Failed to send SMS', error);
      addNotification({ type: 'error', message: 'Kunne ikke sende SMS' });
    }
  };

  const handleCustomerSelection = (customer) => {
    setSelectedCustomers((prevSelected) =>
      prevSelected.some((c) => c.id === customer.id) ? prevSelected.filter((c) => c.id !== customer.id) : [...prevSelected, customer]
    );
  };

  const insertPlaceholder = (placeholder) => {
    if (focusedField === 'emailSubject') {
      insertAtCursor(emailSubjectRef.current, placeholder, setEmailSubject);
    } else if (focusedField === 'emailContent') {
      insertAtCursorQuill(emailQuillRef.current.getEditor(), placeholder);
    } else if (focusedField === 'smsContent') {
      insertAtCursor(smsContentRef.current, placeholder, setSmsContent);
    }
  };

  const insertAtCursor = (field, placeholder, setter) => {
    const startPos = field.selectionStart;
    const endPos = field.selectionEnd;
    setter((prev) => prev.substring(0, startPos) + placeholder + prev.substring(endPos));
    setTimeout(() => {
      field.selectionStart = field.selectionEnd = startPos + placeholder.length;
      field.focus();
    }, 0);
  };

  const insertAtCursorQuill = (editor, placeholder) => {
    const cursorPosition = editor.getSelection().index;
    editor.insertText(cursorPosition, placeholder);
    editor.setSelection(cursorPosition + placeholder.length);
  };

  return (
    <div>
      <h1 className="text-3xl font-bold mb-4">Kontakt</h1>
      <div className="flex mb-6">
        <button
          className={`px-4 py-2 ${activeTab === 'email' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          onClick={() => setActiveTab('email')}
        >
          E-post
        </button>
        <button
          className={`px-4 py-2 ${activeTab === 'sms' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          onClick={() => setActiveTab('sms')}
        >
          SMS
        </button>
      </div>

      <div className="flex">
        <div className="w-1/2">
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Kunder</label>
            <div className="flex items-center mb-2">
              <input
                type="checkbox"
                checked={sendToAll}
                onChange={() => setSendToAll(!sendToAll)}
                className="h-4 w-4 text-blue-600 border-gray-300 rounded"
              />
              <span className="ml-2">Send til alle kunder</span>
            </div>
            {!sendToAll && (
              <>
                <input
                  type="text"
                  placeholder="Søk kunder..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-500"
                />
                <div className="mt-2 max-h-48 overflow-y-auto border border-gray-300 rounded-md">
                  {customers.map((customer) => (
                    <div
                      key={customer.id}
                      className={`flex items-center px-3 py-2 cursor-pointer ${selectedCustomers.some((c) => c.id === customer.id) ? 'bg-blue-100' : ''}`}
                      onClick={() => handleCustomerSelection(customer)}
                    >
                      <span>{`${customer.first_name} ${customer.last_name} (${customer.email})`}</span>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="w-1/2 pl-4">
          <h2 className="text-xl font-semibold mb-4">Valgte Mottakere</h2>
          <div className="max-h-60 overflow-y-auto border border-gray-300 rounded-md mt-6">
            {selectedCustomers.map((customer) => (
              <div
                key={customer.id}
                className="flex items-center px-3 py-2 cursor-pointer hover:bg-red-100"
                onClick={() => handleCustomerSelection(customer)}
              >
                <span>{`${customer.first_name} ${customer.last_name} (${customer.email})`}</span>
              </div>
            ))}
          </div>
        </div>
      </div>

      {activeTab === 'email' && (
        <form onSubmit={handleEmailSubmit} className="mt-6">
          <PlaceholderInserter onInsert={insertPlaceholder} />
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Emne</label>
            <input
              type="text"
              value={emailSubject}
              ref={emailSubjectRef}
              onFocus={() => setFocusedField('emailSubject')}
              onChange={(e) => setEmailSubject(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Innhold</label>
            <ReactQuill
              value={emailContent}
              ref={emailQuillRef}
              onFocus={() => setFocusedField('emailContent')}
              onChange={setEmailContent}
            />
          </div>
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none"
          >
            {sending ? 'Sender...' : 'Send e-post'}
          </button>
        </form>
      )}

      {activeTab === 'sms' && (
        <form onSubmit={handleSmsSubmit} className="mt-6">
          <PlaceholderInserter onInsert={insertPlaceholder} />
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Innhold</label>
            <textarea
              value={smsContent}
              ref={smsContentRef}
              onFocus={() => setFocusedField('smsContent')}
              onChange={(e) => setSmsContent(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-500"
              rows="4"
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none"
          >
            {sending ? 'Sender...' : 'Send SMS'}
          </button>
        </form>
      )}
    </div>
  );
};

export default ContactSettings;