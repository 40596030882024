import React from 'react';
import TopBar from '../components/customer-components/TopBar';
import AdminBar from '../components/admin-components/AdminBar';

const AdminViewCustomerLayout = ({ children }) => {
  return (
    <div className="flex min-h-screen bg-gradient-to-br from-green-100 to-gray-200">
      <div className="w-full">
        <AdminBar />
        <div className="flex-grow">
          <TopBar />
          <main className="flex flex-col items-center justify-center p-4">
            {children}
          </main>
        </div>
      </div>
    </div>
  );
};

export default AdminViewCustomerLayout;