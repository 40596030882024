import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaSave, FaTimes } from 'react-icons/fa';
import { useNotifications } from '../../context/NotificationContext';

const COLOR_OPTIONS = [
  'bg-gray-800',
  'bg-red-500',
  'bg-green-grave',
  'bg-blue-500',
  'bg-orange-grave',
  'bg-purple-500',
  'bg-pink-500',
];

const CustomerMessageSettings = () => {
  const [message, setMessage] = useState('');
  const [color, setColor] = useState(COLOR_OPTIONS[0]);
  const [visible, setVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const { addNotification } = useNotifications();
  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  useEffect(() => {
    const fetchMessage = async () => {
      try {
        const response = await axios.get(`${apiURL}/api/site_settings/customer_portal_notice`);
        const { message, color, visible } = response.data.setting_value;
        setMessage(message);
        setColor(color);
        setVisible(visible);
      } catch (error) {
        console.error('Error fetching customer message:', error);
      }
    };
    fetchMessage();
  }, [apiURL]);

  const handleSave = async () => {
    setLoading(true);
    const settingValue = { message, color, visible };
    try {
      await axios.put(`${apiURL}/api/site_settings/customer_portal_notice`, { setting_value: settingValue });
      addNotification({ type: 'success', message: 'Melding oppdatert.' });
    } catch (error) {
      console.error('Error updating customer message:', error);
      addNotification({ type: 'error', message: 'Kunne ikke oppdatere melding' });
    } finally {
      setLoading(false);
    }
  };

  const handleClear = () => {
    setMessage('');
    setColor(COLOR_OPTIONS[0]);
    setVisible(true);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">Kundemelding</h2>
      <p className="mb-4">Her kan du skrive en melding som vises på toppen i kundeportalen. La det være blankt om ingen melding skal vises.</p>
      <div className="mb-4">
        <label className="block mb-2 font-semibold">Melding:</label>
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Skriv meldingen her"
          className="w-full p-3 border border-gray-300 rounded-md focus:ring focus:ring-green-300"
        />
      </div>
      <div className="mb-4">
        <label className="block mb-2 font-semibold">Bakgrunnsfarge:</label>
        <div className="flex space-x-2">
          {COLOR_OPTIONS.map((colorOption) => (
            <div
              key={colorOption}
              className={`w-8 h-8 cursor-pointer rounded ${colorOption} ${color === colorOption ? 'border-4 border-gray-400' : ''}`}
              onClick={() => setColor(colorOption)}
            ></div>
          ))}
        </div>
      </div>
      <div className="mb-4">
        <label className="block mb-2 font-semibold">Synlig:</label>
        <div className="flex items-center">
          <input
            type="checkbox"
            checked={visible}
            onChange={(e) => setVisible(e.target.checked)}
            className="mr-2 leading-tight"
          />
          <span className="text-sm">Ja</span>
        </div>
      </div>
      <div className="flex items-center space-x-4 mt-4">
        <button
          onClick={handleSave}
          disabled={loading}
          className="flex items-center p-3 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors disabled:opacity-50"
        >
          <FaSave className="mr-2" />
          Lagre
        </button>
        <button
          onClick={handleClear}
          className="flex items-center p-3 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors"
        >
          <FaTimes className="mr-2" />
          Tøm
        </button>
      </div>
      <div className="mt-6">
        <h3 className="text-xl font-semibold mb-2">Forhåndsvisning</h3>
        {visible ? (
          <div className={`text-lg font-bold text-white text-center py-2 ${color}`}>
            {message || 'Forhåndsvisning av meldingen din vil vises her'}
          </div>
        ) : (
          <div className="text-lg text-center py-2">Meldingen er skjult</div>
        )}
      </div>
    </div>
  );
};

export default CustomerMessageSettings;