import React from 'react';
import { FaChevronCircleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const BackButton = ({style}) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(-1);
    }

    return (
        <div className="absolute top-2 left-1 z-20" style={style}>
            <button className="focus:outline-none text-4xl" onClick={handleClick}>
                <FaChevronCircleLeft className="text-black hover:text-green-grave" />
            </button>
        </div>
    )
}

export default BackButton;