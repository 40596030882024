import React, { useState, useEffect } from 'react';
import { useCustomer } from '../../context/CustomerContext';
import LoadingScreen from '../../components/LoadingScreen';
import { useNotifications } from '../../context/NotificationContext';
import axios from 'axios';
import {
  FaUser, FaEnvelope, FaPhone, FaMapMarkerAlt, FaGenderless, FaBirthdayCake, FaIdBadge, FaBuilding, FaClock, FaCommentDots, FaEdit, FaSave, FaTimes
} from 'react-icons/fa';

const CustomerProfile = () => {
  const { customer } = useCustomer();
  const { addNotification } = useNotifications();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [postNumberError, setPostNumberError] = useState('');
  const [profile, setProfile] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({});
  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  useEffect(() => {
    const fetchProfile = async () => {
      if (!customer) return;

      setIsLoading(true);
      setError('');

      try {
        const response = await axios.get(`${apiURL}/api/customers/${customer.id}`);
        setProfile(response.data);
        setFormData(response.data); // Initialize form data
        setIsLoading(false);
      } catch (err) {
        console.error('Error fetching profile:', err);
        setError('Failed to load profile');
        setIsLoading(false);
      }
    };

    fetchProfile();
  }, [customer, apiURL]);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // If the field is customer_post_number, fetch the corresponding post location
    if (name === 'customer_post_number') {
      if (value.length === 4) {
        try {
          const response = await axios.get(`${apiURL}/api/postLocation/${parseInt(value)}`);
          console.log(response.data);
    
          if (response.data.length > 0) {
            const postLocation = response.data[0].Poststed; // Access the 'Poststed' from the first object in the array
            setFormData((prevData) => ({
              ...prevData,
              customer_post_location: postLocation,
            }));
          } else {
            setFormData((prevData) => ({
              ...prevData,
              customer_post_location: '',
            }));
            setPostNumberError('Ugyldig postnummer');
          }
        } catch (err) {
          setFormData((prevData) => ({
            ...prevData,
            customer_post_location: ''
          }));
          setPostNumberError('Ugyldig postnummer');
        }
      } else {
        setPostNumberError('');
        setFormData((prevData) => ({
          ...prevData,
          customer_post_location: ''
        }));
      }
    }
  };

  const toggleEditMode = () => {
    setIsEditing(!isEditing);
    setFormData(profile); // Reset form data when toggling edit mode
  };

  const handleSave = async () => {
    try {
      await axios.put(`${apiURL}/api/customers/${customer.id}/profile`, formData);
      setProfile(formData); // Update the profile state with the new data
      addNotification({ type: 'success', message: 'Profilen er oppdatert!' });
      setIsEditing(false);
    } catch (err) {
      console.error('Error saving profile:', err);
      addNotification({ type: 'error', message: 'Kunne ikke oppdatere profilen.' });
    }
  };

  if (isLoading) return <LoadingScreen />;
  if (error) return <div className="text-red-500 text-center">Error: {error}</div>;

  return (
    <div className="bg-white shadow-lg rounded-2xl p-8 max-w-6xl w-full border-t-4 border-green-grave">
      <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">Din Profil</h1>
      {profile && (
        <div className="space-y-8">
          <div className="bg-gray-100 p-6 rounded-xl shadow-md">
            <div className="flex justify-between items-center">
              <h2 className="text-2xl font-semibold text-gray-800 mb-4 flex items-center">
                <FaUser className="mr-2" /> Personlig informasjon
              </h2>
              {isEditing ? (
                <div className="flex space-x-2">
                  <button onClick={handleSave} className="bg-green-500 hover:bg-green-600 text-white px-2 py-2 rounded">
                    <FaSave />
                  </button>
                  <button onClick={toggleEditMode} className="bg-red-500 hover:bg-red-600 text-white px-2 py-2 rounded">
                    <FaTimes />
                  </button>
                </div>
              ) : (
                <button onClick={toggleEditMode} className="bg-blue-500 hover:bg-blue-600 text-white px-2 py-2 rounded">
                  <FaEdit />
                </button>
              )}
            </div>
            {isEditing ? (
              <>
                <div className="mb-4">
                  <label className="block text-gray-700">Fornavn:</label>
                  <input
                    type="text"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border rounded"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">Etternavn:</label>
                  <input
                    type="text"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border rounded"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">E-post:</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border rounded"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">Telefon:</label>
                  <input
                    type="text"
                    name="phone_number"
                    value={formData.phone_number}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border rounded"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">Adresse:</label>
                  <input
                    type="text"
                    name="customer_address"
                    value={formData.customer_address}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border rounded"
                  />
                </div>
                <div className="flex space-x-4">
                  <div className="mb-4">
                    <label className="block text-gray-700">Postnummer:</label>
                    <input
                      type="text"
                      name="customer_post_number"
                      value={formData.customer_post_number}
                      onChange={handleChange}
                      className="w-full px-4 py-2 border rounded"
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">Poststed:</label>
                    <input
                      type="text"
                      name="customer_post_location"
                      value={formData.customer_post_location}
                      onChange={handleChange}
                      className="w-full px-4 py-2 border rounded"
                    />
                  </div>
                </div>
                {postNumberError && <p className="text-red-500">{postNumberError}</p>}
              </>
            ) : (
              <>
                <p className="text-gray-600 flex items-center">
                  <FaUser className="mr-2" /> Navn: {profile.first_name} {profile.last_name}
                </p>
                <p className="text-gray-600 flex items-center">
                  <FaEnvelope className="mr-2" /> E-post: {profile.email}
                </p>
                <p className="text-gray-600 flex items-center">
                  <FaPhone className="mr-2" /> Telefon: {profile.phone_number}
                </p>
                <p className="text-gray-600 flex items-center">
                  <FaMapMarkerAlt className="mr-2" /> Adresse:
                </p>
                <p className="text-gray-600 ml-6 flex items-center">
                  {profile.customer_address}
                </p>
                <p className="text-gray-600 ml-6 flex items-center">
                  {profile.customer_post_number} {profile.customer_post_location}
                </p>
              </>
            )}
          </div>

          <div className="bg-gray-100 p-6 rounded-xl shadow-md">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4 flex items-center">
              <FaIdBadge className="mr-2" /> Kundeinformasjon
            </h2>
            <p className="text-gray-600 flex items-center">
              <FaIdBadge className="mr-2" /> Kundenummer: {profile.customer_number}
            </p>
            <p className="text-gray-600 flex items-center">
              <FaCommentDots className="mr-2" /> Beskrivelse: {profile.description || 'Ingen beskrivelse'}
            </p>
            <p className="text-gray-600 flex items-center">
              <FaBuilding className="mr-2" /> Org-nummer: {profile.org_number || 'Ikke oppgitt'}
            </p>
            <p className="text-gray-600 flex items-center">
              <FaBuilding className="mr-2" /> Firma: {profile.is_company ? profile.company_name : 'Ikke et firma'}
            </p>
            <p className="text-gray-600 flex items-center">
              <FaClock className="mr-2" /> Sist pålogget: {profile.last_login ? new Date(profile.last_login).toLocaleString('nb-NO') : 'Aldri'}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerProfile;