import React from 'react';

const ReusableActive = ({ value, onChange }) => {
  return (
    <div>
      <select
        value={value}
        onChange={onChange}
        className="p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
      >
        <option value="all">Alle</option>
        <option value="1">Kun Aktive</option>
        <option value="0">Kun Inaktive</option>
      </select>
    </div>
  );
};

export default ReusableActive;