import axios from 'axios';

const apiURL = process.env.REACT_APP_API_BASE_URL || '';

const axiosInstance = axios.create({
  baseURL: apiURL,
  headers: {
    'Content-Type': 'application/json',
    // Add other default headers if needed
  }
});

// Optional: Add interceptors for request and response
axiosInstance.interceptors.request.use(
  config => {
    // Modify config before the request is sent, if needed
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => {
    // Modify response data if needed
    return response;
  },
  error => {
    return Promise.reject(error);
  }
);

export default axiosInstance;