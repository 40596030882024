import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import LoadingScreen from "../../components/LoadingScreen";
import ImageModal from "../../components/ImageModal";
import BackButton from "../../components/BackButton";
import {
  FaMapMarkedAlt,
  FaInfoCircle,
  FaPhone,
  FaEnvelope,
  FaGlobe,
  FaRoute,
  FaMap,
  FaMonument,
} from "react-icons/fa"; // Icons for the details

const GraveyardProfileMobile = () => {
  const { id } = useParams();
  const [graveyard, setGraveyard] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageSrc, setSelectedImageSrc] = useState("");

  const apiURL = process.env.REACT_APP_API_BASE_URL || "";
  const baseURL = "https://cms.gravstellerne.no";

  useEffect(() => {
    setIsLoading(true);
    fetch(`${apiURL}/api/graveyards/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(false);
        setGraveyard(data);
        const googleUrl = `https://www.google.com/maps?q=${data.latitude},${data.longitude}`;
      })
      .catch((err) => {
        setIsLoading(false);
        setError("Kunne ikke hente kirkegårdsdetaljer"); // Translated error message
      });
  }, [id]);

  // Function to open the modal with a specific image
  const openModal = (imageSrc) => {
    setSelectedImageSrc(imageSrc);
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Example function that could be triggered when clicking an image
  const handleImageClick = (imageSrc) => {
    openModal(imageSrc);
  };

  if (isLoading) return <LoadingScreen />;
  if (error) return <div className="text-red-600 text-center">{error}</div>;
  if (!graveyard)
    return <div className="text-center">Kirkegård ikke funnet</div>;

  return (
    <div className="p-4 mt-4">
      <BackButton />
      <div className="bg-white shadow rounded-lg">
        <div className="lg:flex lg:items-center">
          <div className="lg:w-1/2 p-4">
            <img
              src={
                graveyard.picture
                  ? `${baseURL}${graveyard.picture}`
                  : "https://via.placeholder.com/400"
              }
              alt={`${graveyard.name}`}
              className="rounded-lg mb-1 lg:mb-0"
              onClick={() => handleImageClick(`${baseURL}${graveyard.picture}`)}
            />
          </div>
          {/* Button bar with icons */}
          <div className="grid grid-cols-3 gap-1">
            <a
              href={`https://www.google.com/maps?q=${graveyard.latitude},${graveyard.longitude}`}
              target="_blank"
              rel="noreferrer"
              className="flex items-center justify-center px-1 py-3 bg-green-500 text-white rounded hover:bg-green-600 text-xs"
            >
              <button className="flex items-center justify-center px-1 py-3 bg-green-500 text-white rounded hover:bg-green-600 text-xs">
                <FaRoute className="mr-1" /> <span>Veibeskrivelse</span>
              </button>
            </a>
            <Link
              to={`/mobil/gravsteiner/kirkegard/${graveyard.id}/kart`}
              className="flex items-center justify-center px-1 py-3 bg-blue-500 text-white rounded hover:bg-blue-600 text-xs"
            >
              <FaMap className="mr-1" /> <span>Gravplass Kart</span>
            </Link>
            <Link
              to={`/gravsteiner/kirkegard/${graveyard.id}`}
              className="flex items-center justify-center px-1 py-3 bg-purple-500 text-white rounded hover:bg-purple-600 text-xs"
            >
              <FaMonument className="mr-1" />{" "}
              <span>Gravsteiner ({graveyard.gravestone_count})</span>
            </Link>
          </div>

          <div className="lg:w-1/2 p-4">
            <h2 className="text-2xl font-bold mb-2">{graveyard.name}</h2>
            <p className="flex items-center text-gray-700 mb-2">
              <FaMapMarkedAlt className="mr-2" />
              {graveyard.location || "Ukjent"}
            </p>
            <p className="flex items-center text-gray-700 mb-2">
              <FaInfoCircle className="mr-2" />
              {graveyard.gravestone_count} graver
            </p>
            <p className="flex items-center text-gray-700 mb-2">
              <FaPhone className="mr-2" />
              {graveyard.contact_phone || "Ingen telefonnummer"}
            </p>
            <p className="flex items-center text-gray-700 mb-2">
              <FaEnvelope className="mr-2" />
              {graveyard.contact_email || "Ingen e-postadresse"}
            </p>
            <Link
              to={graveyard.eclesia_url}
              className="flex items-center underline text-green-700 mb-2"
            >
              <FaGlobe className="mr-2" />
              {graveyard.eclesia_url}
            </Link>
          </div>
        </div>
      </div>
      <ImageModal
        isOpen={isModalOpen}
        onClose={closeModal}
        imageSrc={selectedImageSrc}
      />
    </div>
  );
};

export default GraveyardProfileMobile;
