import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useNotifications } from '../../context/NotificationContext';

const CreateDepartment = () => {
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [active, setActive] = useState(1);
  const navigate = useNavigate();
  const { addNotification } = useNotifications();
  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = { id, name, description, active };

    try {
      await axios.post(`${apiURL}/api/departments`, data);
    addNotification({ message: 'Avdeling opprettet vellykket', type: 'success' });
      navigate('/avdelinger');
    } catch (error) {
      console.error(error);
      addNotification({ message: error.message || 'An error occurred', type: 'error' });
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-8 bg-white rounded-lg shadow-lg">
      <h1 className="text-3xl font-bold text-gray-800 mb-6">Ny Avdeling</h1>
      <form className="space-y-6" onSubmit={handleSubmit}>
        <div>
          <label htmlFor="id" className="block text-lg font-medium text-gray-700">ID:</label>
          <input
            type="text"
            id="id"
            value={id}
            onChange={(e) => setId(e.target.value)}
            className="mt-2 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 transition duration-150 ease-in-out"
            placeholder="Legg til ID"
          />
        </div>
        <div>
          <label htmlFor="name" className="block text-lg font-medium text-gray-700">Navn:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="mt-2 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 transition duration-150 ease-in-out"
            placeholder="Legg til navn"
          />
        </div>
        <div>
          <label htmlFor="description" className="block text-lg font-medium text-gray-700">Beskrivelse:</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="mt-2 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 transition duration-150 ease-in-out"
            placeholder="Legg til beskrivelse"
          ></textarea>
        </div>
        <div>
          <label htmlFor="active" className="block text-lg font-medium text-gray-700">Aktiv?</label>
          <select
            id="active"
            value={active}
            onChange={(e) => setActive(e.target.value)}
            className="mt-2 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 transition duration-150 ease-in-out"
          >
            <option value={1}>Ja</option>
            <option value={0}>Nei</option>
          </select>
        </div>
        <button
          type="submit"
          className="w-full bg-green-grave text-white py-3 px-4 rounded-lg shadow-lg hover:bg-orange-grave focus:outline-none focus:ring-2 focus:ring-green-grave transition duration-150 ease-in-out"
        >
          Lagre
        </button>
      </form>
    </div>
  );
};

export default CreateDepartment;