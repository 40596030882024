import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaSave } from 'react-icons/fa';
import { useNotifications } from '../../context/NotificationContext';

const MapsSettings = () => {
  const [settings, setSettings] = useState({
    mapType: 'satellite',
    defaultZoom: 10,
    enableClustering: true,
    markerIcon: '',
    markerScale: 1,
    userTracking: true,
    realTimeTracking: true,
    trackingFrequency: 5000,
    defaultCenter: { lat: 59.95, lng: 30.33 },
    geolocationAccuracy: 10000
  });
  const { addNotification } = useNotifications();
  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(`${apiURL}/api/site_settings/map_settings`);
        setSettings(response.data.setting_value);
      } catch (error) {
        console.error('Error fetching settings:', error);
      }
    };
    fetchSettings();
  }, [apiURL]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSettings(prevSettings => ({
      ...prevSettings,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSaveSettings = async () => {
    try {
      await axios.put(`${apiURL}/api/site_settings/map_settings`, { setting_value: settings });
      addNotification({ type: 'success', message: 'Innstillinger oppdatert.' });
    } catch (error) {
      console.error('Error saving settings:', error);
      addNotification({ type: 'error', message: 'Kunne ikke oppdatere innstillinger' });
    }
  };

  return (
    <div>
      <h2 className="text-3xl font-bold mb-6">Kartinnstillinger</h2>
      <SettingItem
        label="Karttype"
        input={
          <select
            name="mapType"
            value={settings?.mapType}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md focus:ring focus:ring-green-300"
          >
            <option value="roadmap">Roadmap</option>
            <option value="satellite">Satellite</option>
            <option value="hybrid">Hybrid</option>
            <option value="terrain">Terrain</option>
          </select>
        }
      />
      <SettingItem
        label="Standard zoomnivå"
        input={
          <input
            type="number"
            name="defaultZoom"
            value={settings?.defaultZoom}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md focus:ring focus:ring-green-300"
          />
        }
      />
      <SettingItem
        label="Aktiver clustering"
        input={
          <div className="flex items-center">
            <input
              type="checkbox"
              name="enableClustering"
              checked={settings?.enableClustering}
              onChange={handleChange}
              className="mr-2 leading-tight"
            />
            <span className="text-sm">Ja</span>
          </div>
        }
      />
      <SettingItem
        label="Markørikon"
        input={
          <input
            type="text"
            name="markerIcon"
            value={settings?.markerIcon}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md focus:ring focus:ring-green-300"
          />
        }
      />
      <SettingItem
        label="Markørskala"
        input={
          <input
            type="number"
            name="markerScale"
            value={settings?.markerScale}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md focus:ring focus:ring-green-300"
          />
        }
      />
      <SettingItem
        label="Brukersporing"
        input={
          <div className="flex items-center">
            <input
              type="checkbox"
              name="userTracking"
              checked={settings?.userTracking}
              onChange={handleChange}
              className="mr-2 leading-tight"
            />
            <span className="text-sm">Ja</span>
          </div>
        }
      />
      {settings?.userTracking && (
        <>
          <SettingItem
            label="Sanntidssporing"
            input={
              <div className="flex items-center">
                <input
                  type="checkbox"
                  name="realTimeTracking"
                  checked={settings?.realTimeTracking}
                  onChange={handleChange}
                  className="mr-2 leading-tight"
                />
                <span className="text-sm">Ja</span>
              </div>
            }
          />
          {settings?.realTimeTracking && (
            <SettingItem
              label="Sporingsfrekvens (ms)"
              input={
                <input
                  type="number"
                  name="trackingFrequency"
                  value={settings?.trackingFrequency}
                  onChange={handleChange}
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring focus:ring-green-300"
                />
              }
            />
          )}
        </>
      )}
      <SettingItem
        label="Standard senter"
        input={
          <div className="flex space-x-2">
            <input
              type="number"
              name="lat"
              value={settings?.defaultCenter.lat}
              onChange={(e) => setSettings(prevSettings => ({
                ...prevSettings,
                defaultCenter: { ...prevSettings.defaultCenter, lat: e.target.value }
              }))}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring focus:ring-green-300"
              placeholder="Breddegrad"
            />
            <input
              type="number"
              name="lng"
              value={settings?.defaultCenter.lng}
              onChange={(e) => setSettings(prevSettings => ({
                ...prevSettings,
                defaultCenter: { ...prevSettings.defaultCenter, lng: e.target.value }
              }))}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring focus:ring-green-300"
              placeholder="Lengdegrad"
            />
          </div>
        }
      />
      <SettingItem
        label="Geolokasjonsnøyaktighet"
        input={
          <input
            type="number"
            name="geolocationAccuracy"
            value={settings?.geolocationAccuracy}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md focus:ring focus:ring-green-300"
          />
        }
      />
      <button
        onClick={handleSaveSettings}
        className="bg-green-500 text-white p-3 rounded-lg shadow-md hover:bg-green-600 transition-all duration-300 flex items-center space-x-2 mt-4"
      >
        <FaSave />
        <span>Lagre</span>
      </button>
    </div>
  );
};

const SettingItem = ({ label, input }) => (
  <div className="mb-6">
    <label className="block text-lg font-semibold mb-2">{label}</label>
    {input}
  </div>
);

export default MapsSettings;