import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { AiOutlineGlobal } from "react-icons/ai";
import Pagination from "../../components/Pagination";
import Spinner from "../../components/Spinner";
import ReusableSearch from "../../components/ReusableSearch";
import ImageModal from "../../components/ImageModal";
import BackButton from "../../components/BackButton";

function GravestonesMobile() {
  const { customerId, graveyardId } = useParams();
  const [gravestones, setGravestones] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("id");
  const [order, setOrder] = useState("ASC");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [active, setActive] = useState("all");
  const [totalPages, setTotalPages] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageSrc, setSelectedImageSrc] = useState("");

  const apiURL = process.env.REACT_APP_API_BASE_URL || "";

  // Event handlers remain within the parent component
  const handleSearchChange = (e) => setSearch(e.target.value);
  const handleLimitChange = (e) => setLimit(e.target.value);
  const handleActiveChange = (e) => setActive(e.target.value);

  useEffect(() => {
    const queryParams = new URLSearchParams({
      search,
      sort,
      order,
      page,
      limit,
      active,
    });

    if (customerId) {
      queryParams.append("customer_id", customerId);
    } else if (graveyardId) {
      queryParams.append("graveyard_id", graveyardId);
    }

    const queryString = queryParams.toString();
    const fetchGravestones = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `${apiURL}/api/gravestones?${queryString}`
        );
        if (!response.ok) {
          throw new Error("Something went wrong!");
        }
        const data = await response.json();
        setGravestones(data.gravestones);
        setTotalPages(data.totalPages);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchGravestones();
  }, [search, sort, order, page, limit, active, customerId, graveyardId]);

  const handleThumbnailClick = (thumbnail) => {
    setSelectedImageSrc(`${apiURL}${thumbnail}`);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const daysSinceLastVisit = (latestVisit) => {
    if (!latestVisit) return Infinity; // Return a large number if no visit date
    const today = new Date();
    const lastVisitDate = new Date(latestVisit);
    const difference = today - lastVisitDate;
    return Math.floor(difference / (1000 * 60 * 60 * 24)); // Convert ms to days
  };

  if (error)
    return <div className="text-red-500 text-center">Error: {error}</div>;

  return (
    <div className="p-2">
      <header className="fixed top-0 left-0 right-0 bg-white z-10 shadow-md p-2 flex justify-between items-center">
        <h1 className="text-lg font-bold">Gravsteiner</h1>
        <div className="flex items-center space-x-2">
          <ReusableSearch value={search} onChange={handleSearchChange} className="flex-grow" />
          {!customerId && !graveyardId && (
            <Link to={`/mobil/gravsteiner/kart`} className="text-black hover:text-green-500">
              <AiOutlineGlobal size="1.5em" />
            </Link>
          )}
        </div>
      </header>
      <div className="pt-16">
        {isLoading && <Spinner />}
        <div className="space-y-4">
          {gravestones.map((gravestone) => {
            const days = daysSinceLastVisit(gravestone.latest_visit_date);
            const cardBackgroundColor = days <= 5 ? 'bg-green-200' : 'bg-white';
  
            return (
              <div
                key={gravestone.id}
                className={`${cardBackgroundColor} shadow rounded-lg overflow-hidden my-4`}
                style={{ border: `2px solid ${gravestone.productColor || '#ccc'}` }}
              >
                <div className="flex items-center">
                  <div className="w-1/4">
                    <img
                      src={gravestone.thumbnail ? `${apiURL}${gravestone.thumbnail}` : "https://via.placeholder.com/100"}
                      alt={gravestone.gravepersons_names || 'Unknown'}
                      className="object-cover h-full w-full cursor-pointer"
                      onClick={() => handleThumbnailClick(gravestone.picture)}
                    />
                  </div>
                  <div className="p-4 w-3/4">
                    <Link to={`/gravsteiner/${gravestone.id}`} className="text-lg font-bold block">
                      {gravestone.gravepersons_names}
                      <p className="text-sm">{gravestone.location}</p>
                      <div className="text-sm">
                        <span className="text-gray-600">Kirkegård:</span> {gravestone.graveyard_name}
                      </div>
                      <div className="text-sm">
                        <span className="text-gray-600">Kunde:</span> {gravestone.customer_name}
                      </div>
                      <div className="text-sm bg-gray-200 text-black px-2 py-1 rounded-md mt-2">
                        {gravestone.productNames}
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <Pagination
          totalPages={totalPages}
          currentPage={page}
          setPage={setPage}
        />
        <ImageModal
          isOpen={isModalOpen}
          onClose={closeModal}
          imageSrc={selectedImageSrc}
        />
      </div>
    </div>
  );  
}

export default GravestonesMobile;
