import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { FaBirthdayCake, FaCross, FaMountain } from 'react-icons/fa';
import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';
import LoadingScreen from '../../components/LoadingScreen';
import BackButton from '../../components/BackButton';

const GravepersonDetail = () => {
  const { id } = useParams(); // Assuming you're using react-router
  const [graveperson, setGraveperson] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  useEffect(() => {
    const fetchGraveperson = async () => {
      try {
        const response = await axios.get(`${apiURL}/api/gravepersons/${id}`);
        setGraveperson(response.data);
      } catch (err) {
        setError('Failed to fetch graveperson details.');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchGraveperson();
  }, [id]);

  if (loading) return <LoadingScreen />;
  if (error) return <div className="text-center text-red-500 p-10">{error}</div>;

  return (
    <div className="container mx-auto p-4 mt-8 sm:mt-0">
      <BackButton />
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {graveperson?.first_name} {graveperson?.last_name}
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Detaljer om gravperson og relatert informasjon.
          </p>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            {/* Birthdate */}
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Født</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <FaBirthdayCake className="inline mr-2 text-green-500" />
                {new Date(graveperson?.birthdate).toLocaleDateString('nb-NO') || 'Ukjent'}
              </dd>
            </div>
            {/* Deathdate */}
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Døde</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <FaCross className="inline mr-2 text-red-500" />
                {new Date(graveperson?.deathdate).toLocaleDateString('nb-NO') || 'Ukjent'}
              </dd>
            </div>
            {/* Graveyard Link */}
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Kirkegård</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <Link to={`/kirkegarder/${graveperson?.graveyard_id}`} className="flex items-center text-blue-600 hover:underline">
                  <FaMountain className="mr-2" />
                  {graveperson?.graveyard_name || 'Ukjent'}
                </Link>
              </dd>
            </div>
            {/* Gravestone Link */}
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Gravsten</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <Link to={`/gravsteiner/${graveperson?.gravestone_id}`} className="flex items-center text-blue-600 hover:underline">
                  <span>{graveperson?.gravestone_id || 'Ukjent plassering'}</span>
                </Link>
              </dd>
            </div>
            {/* Customer Link */}
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Kunde</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <Link to={`/kunder/${graveperson?.customer_id}`} className="flex items-center text-blue-600 hover:underline">
                  <span>{`${graveperson?.customer_first_name} ${graveperson?.customer_last_name}`}</span>
                </Link>
              </dd>
            </div>
            {/* Email and Phone */}
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Kundes E-post</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <AiOutlineMail className="inline mr-2" />
                {graveperson?.customer_email || 'N/A'}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Kundes Telefonnummer</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <AiOutlinePhone className="inline mr-2" />
                {graveperson?.customer_phone || 'N/A'}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default GravepersonDetail;