import React from 'react';

const UserGuide = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center text-gray-800 mb-10">Web Application User Guide</h1>

      {/* Introduction */}
      <section className="mb-10">
        <h2 className="text-2xl font-semibold text-gray-700 mb-3">Introduksjon</h2>
        <p className="text-gray-600 text-lg mb-6">Her vil du få en oversikt over åssen du bruker denne appen. Lær deg åssen du kan få fram arbeidslister, foreta besøk osv.</p>
        <img src="https://via.placeholder.com/600x300?text=Web+App+Overview" alt="Web App Overview" className="rounded-lg shadow-lg"/>
      </section>

      {/* Getting Started */}
      <section className="mb-10">
        <h2 className="text-2xl font-semibold text-gray-700 mb-3">Få en Oversikt</h2>
        <div className="space-y-6">
          <div>
            <h3 className="font-medium text-xl text-gray-700">Navigering</h3>
            <p className="text-gray-600 mb-4">Test</p>
            <img src="https://via.placeholder.com/600x300?text=Registration+Page" alt="Registration Page" className="rounded-lg shadow-lg"/>
          </div>
        </div>
      </section>

      {/* Main Features */}
      <section className="mb-10">
        <h2 className="text-2xl font-semibold text-gray-700 mb-3">Main Features</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <h3 className="font-medium text-xl text-gray-700">Managing Projects</h3>
            <p className="text-gray-600">Comprehensive tools for project management allow you to create, edit, and delete projects, streamlining your workflow and enhancing efficiency.</p>
            <img src="https://via.placeholder.com/600x300?text=Managing+Projects" alt="Managing Projects" className="rounded-lg shadow-lg"/>
          </div>
          <div>
            <h3 className="font-medium text-xl text-gray-700">Tracking Time</h3>
            <p className="text-gray-600">Accurate time tracking features enable you to log hours spent on tasks, providing insights into your productivity and work habits.</p>
            <img src="https://via.placeholder.com/600x300?text=Tracking+Time" alt="Tracking Time" className="rounded-lg shadow-lg"/>
          </div>
        </div>
      </section>

      {/* Advanced Features */}
      <section className="mb-10">
        <h2 className="text-2xl font-semibold text-gray-700 mb-3">Advanced Features</h2>
        <h3 className="font-medium text-xl text-gray-700">Integrations</h3>
        <p className="text-gray-600 mb-4">Enhance the functionality of the Web Application by integrating with other tools, expanding its capabilities and usability.</p>
        <img src="https://via.placeholder.com/600x300?text=Integrations" alt="Integrations" className="rounded-lg shadow-lg"/>
      </section>

      {/* Troubleshooting */}
      <section className="mb-10">
        <h2 className="text-2xl font-semibold text-gray-700 mb-3">Troubleshooting</h2>
        <p className="text-gray-600">If you encounter issues, refer to this section for common problems and their solutions. Contact support for unresolved issues.</p>
        <img src="https://via.placeholder.com/600x300?text=Troubleshooting" alt="Troubleshooting" className="rounded-lg shadow-lg"/>
      </section>

      {/* FAQs */}
      <section className="mb-10">
        <h2 className="text-2xl font-semibold text-gray-700 mb-3">FAQs</h2>
        <p className="text-gray-600">Find answers to frequently asked questions about the application. This section helps address common concerns and inquiries.</p>
        <img src="https://via.placeholder.com/600x300?text=FAQs" alt="FAQs" className="rounded-lg shadow-lg"/>
      </section>

      {/* Contact Us */}
      <section className="mb-10">
        <h2 className="text-2xl font-semibold text-gray-700 mb-3">Contact Us</h2>
        <p className="text-gray-600">For additional support, please contact our helpdesk. We are available during business hours and eager to assist you.</p>
        <img src="https://via.placeholder.com/600x300?text=Contact+Us" alt="Contact Us" className="rounded-lg shadow-lg"/>
      </section>
    </div>
  );
};

export default UserGuide;