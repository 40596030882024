import React, { useState, useEffect } from 'react';
import { MdOutlineContactPage } from 'react-icons/md';
import { FaPlus, FaEdit, FaTrash, FaTimes } from 'react-icons/fa';
import axiosInstance from '../../utils/axiosInstance';

const GravestoneContactsManager = ({ gravestoneId }) => {
  const [contacts, setContacts] = useState([]);
  const [currentContact, setCurrentContact] = useState({ first_name: '', last_name: '', phone_number: '+47', email: '' });
  const [isEditing, setIsEditing] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  useEffect(() => {
    fetchContacts();
  }, [gravestoneId]);

  const fetchContacts = async () => {
    try {
      const response = await axiosInstance.get(`/api/gravestone_contacts/${gravestoneId}`);
      setContacts(response.data);
    } catch (error) {
      console.error('Failed to fetch contacts', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentContact({ ...currentContact, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      first_name: currentContact.first_name,
      last_name: currentContact.last_name,
      phone_number: currentContact.phone_number,
      email: currentContact.email,
      gravestone_id: gravestoneId
    };

    try {
      if (isEditing) {
        await axiosInstance.put(`/api/gravestone_contacts/${currentContact.id}`, data);
      } else {
        await axiosInstance.post(`/api/gravestone_contacts`, data);
      }
      setCurrentContact({ first_name: '', last_name: '', phone_number: '+47', email: '' });
      setIsEditing(false);
      setModalIsOpen(false); // Close modal on successful submission
      fetchContacts();
    } catch (error) {
      console.error('Failed to submit contact', error);
    }
  };

  const startEditing = (contact) => {
    setCurrentContact(contact);
    setIsEditing(true);
    setModalIsOpen(true);
  };

  const cancelEditing = () => {
    setCurrentContact({ first_name: '', last_name: '', phone_number: '+47', email: '' });
    setIsEditing(false);
    setModalIsOpen(false);
  };

  const handleDelete = async (id) => {
    try {
      await axiosInstance.delete(`/api/gravestone_contacts/${id}`);
      fetchContacts();
    } catch (error) {
      console.error('Failed to delete contact', error);
    }
  };

  return (
    <div className="space-y-6">
      <div className="bg-white rounded-lg shadow p-6">
        <button
          onClick={() => setModalIsOpen(true)}
          className="w-full bg-green-grave text-white px-4 py-2 rounded hover:bg-green-600 transition"
        >
          <FaPlus className="mr-2 inline-block" /> Legg til Kontakt
        </button>
      </div>

      <div className="bg-white rounded-lg shadow p-6">
        {contacts.length > 0 ? (
          <ul className="space-y-4">
            {contacts.map((contact) => (
              <li key={contact.id} className="border p-4 rounded hover:bg-gray-50 transition">
                <div className="flex justify-between items-center">
                  <div>
                    <div className="font-medium">{contact.first_name} {contact.last_name}</div>
                    <div className="text-sm text-gray-600">{contact.phone_number || 'No phone number'}</div>
                    <div className="text-sm text-gray-600">{contact.email}</div>
                  </div>
                  <div className="flex space-x-2">
                    <button onClick={() => startEditing(contact)} className="btn btn-sm btn-warning">
                      <FaEdit className="mr-1" />
                    </button>
                    <button onClick={() => handleDelete(contact.id)} className="btn btn-sm btn-error">
                      <FaTrash className="mr-1" />
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <div className="text-center">
            <MdOutlineContactPage className="mx-auto text-4xl text-gray-400" />
            <p className="mt-2 text-gray-700">Ingen Kontakter</p>
          </div>
        )}
      </div>

      {modalIsOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-bold">{isEditing ? 'Endre Kontakt' : 'Legg til Kontakt'}</h2>
              <button onClick={cancelEditing} className="text-red-500 hover:text-red-600 transition">
                <FaTimes size={20} />
              </button>
            </div>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="first_name">
                  Fornavn
                </label>
                <input
                  id="first_name"
                  name="first_name"
                  type="text"
                  value={currentContact.first_name}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="last_name">
                  Etternavn
                </label>
                <input
                  id="last_name"
                  name="last_name"
                  type="text"
                  value={currentContact.last_name}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone_number">
                  Telefonnummer
                </label>
                <input
                  id="phone_number"
                  name="phone_number"
                  type="text"
                  value={currentContact.phone_number}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                  E-post
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={currentContact.email}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <button
                type="submit"
                className="w-full bg-green-grave text-white px-4 py-2 rounded hover:bg-green-600 transition"
              >
                {isEditing ? 'Oppdater' : 'Legg Til'}
              </button>
              <button
                type="button"
                onClick={cancelEditing}
                className="w-full bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition mt-2"
              >
                Avbryt
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default GravestoneContactsManager;