import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';

const CustomDatePickerWrapper = styled.div`
  .react-datepicker__year-dropdown {
    display: none;
  }
`;

const CustomDatePicker = ({ selectedDate, onChange }) => {
  const [date, setDate] = useState(selectedDate);

  const handleDateChange = (date) => {
    // Set the year to a constant value (e.g., 1970) to ignore the year
    const adjustedDate = new Date(date);
    adjustedDate.setFullYear(1970);
    setDate(adjustedDate);
    onChange(adjustedDate);
  };

  return (
    <CustomDatePickerWrapper>
      <DatePicker
        selected={date}
        onChange={handleDateChange}
        dateFormat="MMMM d"
        showMonthDropdown
        showDayDropdown
      />
    </CustomDatePickerWrapper>
  );
};

export default CustomDatePicker;