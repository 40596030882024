import { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../utils/axiosInstance';
import { useNavigate } from 'react-router-dom';
import { useNotifications } from '../context/NotificationContext';

export const useGraveyards = () => {
  const [graveyards, setGraveyards] = useState([]);
  const [graveyard, setGraveyard] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(0);

  const navigate = useNavigate();
  const { addNotification } = useNotifications();

  // Fetch all graveyards with query parameters
  const fetchGraveyards = useCallback(async ({ search, sort, order, page, limit, active }) => {
    setIsLoading(true);
    try {
      const queryParams = new URLSearchParams({ search, sort, order, page, limit, active }).toString();
      const response = await axiosInstance.get(`/api/graveyards?${queryParams}`);
      setGraveyards(response.data.graveyards);
      setTotalPages(response.data.totalPages);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Fetch a single graveyard by ID
  const fetchGraveyardById = useCallback(async (id) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/api/graveyards/${id}`);
      setGraveyard(response.data);
    } catch (err) {
      setError('Kunne ikke hente kirkegårdsdetaljer');
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Add a new graveyard
  const addGraveyard = async (formData) => {
    setIsLoading(true);
    try {
      const formDataToSend = new FormData();
      Object.keys(formData).forEach(key => {
        formDataToSend.append(key, formData[key]);
      });

      const response = await axiosInstance.post('/api/graveyards', formDataToSend, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      if (response.status === 201) {
        const result = response.data;
        addNotification({ message: 'Kirkegård opprettet', type: 'success' });
        navigate(`/kirkegarder/${result.graveyard.id}`);
      } else {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (err) {
      console.error('Failed to create graveyard:', err);
      setError('Kunne ikke opprette kirkegård');
      addNotification({ message: 'Kunne ikke opprette kirkegård', type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const getGraveyardOptions = useCallback(() => {
    return graveyards.map((graveyard) => ({
      value: graveyard.id.toString(),
      label: graveyard.name,
    }));
  }, [graveyards]);

  return {
    graveyards,
    graveyard,
    isLoading,
    error,
    totalPages,
    getGraveyardOptions, // Expose selectOptions for react-select
    fetchGraveyards,
    fetchGraveyardById,
    addGraveyard, // Expose the addGraveyard function
  };
};