import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useUser } from '../context/UserContext'; // Adjust import path as necessary

const ProtectedRoute = ({ element: Element }) => {
  const { user } = useUser();
  const location = useLocation();

  if (!user) {
    // Redirect them to the /logginn page, but save the current location they were trying to go to
    return <Navigate to="/logginn" state={{ from: location }} replace />;
  }

  return Element;
};

export default ProtectedRoute;