import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useNotifications } from "../../context/NotificationContext";
import { IoSave, IoCalendarNumberOutline } from "react-icons/io5";
import { useActivities } from "../../hooks";

const EditActivity = () => {
  const { id } = useParams();

  const { addNotification } = useNotifications();
  const navigate = useNavigate();

  const { fetchActivityById, activity, setActivity, updateActivity } =
    useActivities();

  useEffect(() => {
    fetchActivityById(id);
  }, [id]);

  const handleInputChange = (e) => {
    setActivity({ ...activity, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    updateActivity(id, activity, navigate, addNotification);
  };

  return (
    <div className="container mx-auto px-4">
      <h1 className="text-3xl font-semibold text-center text-gray-800 my-6">
        Rediger Aktivitet
      </h1>
      <form
        onSubmit={handleSubmit}
        className="max-w-lg mx-auto bg-white p-6 shadow-lg rounded-lg"
      >
        <div className="mb-4">
          <label
            htmlFor="name"
            className="block font-semibold mb-1 text-gray-700"
          >
            Navn
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={activity.name || ""}
            onChange={handleInputChange}
            className="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="code"
            className="block font-semibold mb-1 text-gray-700"
          >
            Kode
          </label>
          <input
            type="text"
            id="code"
            name="code"
            value={activity.code || ""}
            onChange={handleInputChange}
            className="form-input mt-1 block w-full border-gray-300 rounded-md shadow-sm"
          />
        </div>
        <div className="mb-4">
          <p className="flex items-center text-gray-600">
            <IoCalendarNumberOutline className="mr-2" />
            Opprettet:{" "}
            <span className="ml-1 text-gray-800">
              {new Date(activity.created_at).toLocaleString("nb-NO")}
            </span>
          </p>
          <p className="flex items-center text-gray-600">
            <IoCalendarNumberOutline className="mr-2" />
            Sist Oppdatert:{" "}
            <span className="ml-1 text-gray-800">
              {new Date(activity.updated_at).toLocaleString("nb-NO")}
            </span>
          </p>
        </div>
        <button
          type="submit"
          className="flex items-center justify-center w-full bg-green-grave hover:bg-orange-grave text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
        >
          <IoSave className="mr-2" />
          Lagre
        </button>
        <Link
          to={`/aktiviteter/${id}`}
          className="block text-center mt-4 text-orange-grave hover:underline"
        >
          Avbryt
        </Link>
      </form>
    </div>
  );
};

export default EditActivity;
