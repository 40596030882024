import { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../utils/axiosInstance';

export const useLogs = () => {
  const [logs, setLogs] = useState([]);
  const [log, setLog] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch all logs
  const fetchLogs = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get('/api/logs');
      setLogs(response.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Fetch a log by ID
  const fetchLogById = useCallback(async (id) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/api/logs/${id}`);
      setLog(response.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Fetch logs by user ID
  const fetchLogsByUserId = useCallback(async (userId) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/api/logs/user/${userId}`);
      setLogs(response.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Create a new log
  const createLog = useCallback(async (newLog) => {
    try {
      const response = await axiosInstance.post('/api/logs', newLog);
      setLogs((prevLogs) => [...prevLogs, { ...newLog, id: response.data.logId }]);
    } catch (err) {
      setError(err.message);
    }
  }, []);

  // Delete a log by ID
  const deleteLog = useCallback(async (id) => {
    try {
      await axiosInstance.delete(`/api/logs/${id}`);
      setLogs((prevLogs) => prevLogs.filter((log) => log.id !== id));
    } catch (err) {
      setError(err.message);
    }
  }, []);

  return {
    logs,
    log,
    isLoading,
    error,
    fetchLogs,
    fetchLogById,
    fetchLogsByUserId,
    createLog,
    deleteLog,
  };
};