// components/ReusableSearch.js
import React from 'react';

const ReusableSearch = ({ value, onChange }) => {
  return (
    <input
      type="text"
      placeholder="Søk..."
      value={value}
      onChange={onChange}
      className="p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
    />
  );
};

export default ReusableSearch;