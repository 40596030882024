import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import Select from "react-select";
import DatePicker from "react-datepicker";
import ReusableSearch from '../../components/ReusableSearch';
import LoadingScreen from "../../components/LoadingScreen";
import "react-datepicker/dist/react-datepicker.css";
import { nb } from "date-fns/locale";
import { useNotifications } from "../../context/NotificationContext";
import { useProducts, useActivities, useGraveyards, useUsers } from "../../hooks";

const WorkOrderForm = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedGravestones, setSelectedGravestones] = useState([]);
  const [availableGravestones, setAvailableGravestones] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedGraveyards, setSelectedGraveyards] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [name, setName] = useState("");
  const [comment, setComment] = useState("");
  const [search, setSearch] = useState("");

  const { fetchProducts, getSelectOptions } = useProducts();
  const { fetchGraveyards, getGraveyardOptions } = useGraveyards();
  const { fetchToggleActivities, activities, setActivities } = useActivities();
  const { fetchUsers, getUserOptions } = useUsers();

  const [loading, setLoading] = useState(true);
  const { addNotification } = useNotifications();
  const navigate = useNavigate();

  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  useEffect(() => {
    const queryParams = new URLSearchParams({
      productIds: selectedProducts.map((product) => product.value).join(","),
      graveyardIds: selectedGraveyards
        .map((graveyard) => graveyard.value)
        .join(","),
      search: search,
    }).toString();

    fetch(`${apiURL}/api/gravestonesWorkorder?${queryParams}`)
      .then((res) => res.json())
      .then((data) => {
        if (!Array.isArray(data)) {
          console.error("Expected an array of gravestones, received:", data);
          throw new TypeError("Received data is not an array");
        }
        const deduplicatedData = Array.from(
          new Map(data.map((item) => [item.id, item])).values()
        );

        // Filter out selected gravestones
        const filteredAvailableGravestones = deduplicatedData
          .filter(
            (gravestone) =>
              !selectedGravestones.some(
                (selected) => selected.value === gravestone.id.toString()
              )
          )
          .map((gravestone) => ({
            value: gravestone.id.toString(),
            label: gravestone.gravepersons_names,
          }));

        setAvailableGravestones(filteredAvailableGravestones);
        setLoading(false);
      })
      .catch((error) => console.error("Failed to fetch gravestones", error));
  }, [selectedProducts, selectedGravestones, selectedGraveyards, search]); // Add selectedGravestones to the dependency array

  useEffect(() => {
    // Fetch products from the backend
    fetchProducts();
  }, []);

  useEffect(() => {
    // Fetch activities from the backend
    fetchToggleActivities();
  }, []);

  useEffect(() => {
    // Fetch users from the backend
    fetchUsers({ search: '', sort: 'name', order: 'ASC', page: 1, limit: 999, active: 1 });
  }, []);

  useEffect(() => {
    // Fetch graveyards when the component mounts
    fetchGraveyards({ search: '', sort: 'name', order: 'ASC', page: 1, limit: 999, active: 1 });
}, [fetchGraveyards]);

  // Function to handle product selection changes
  const handleProductChange = (selectedOptions) => {
    setSelectedProducts(selectedOptions);
  };

  const handleGraveyardChange = (selectedOptions) => {
    setSelectedGraveyards(selectedOptions);
  };

  const handleSearchChange = (e) => setSearch(e.target.value);

  const handleSelectGravestone = (gravestone) => {
    setAvailableGravestones(
      availableGravestones.filter((g) => g.value !== gravestone.value)
    );
    setSelectedGravestones([...selectedGravestones, gravestone]);
  };

  const handleDeselectGravestone = (gravestone) => {
    setSelectedGravestones(
      selectedGravestones.filter((g) => g.value !== gravestone.value)
    );
    setAvailableGravestones([...availableGravestones, gravestone]);
  };

  const moveAllToSelected = () => {
    setSelectedGravestones([...selectedGravestones, ...availableGravestones]);
    setAvailableGravestones([]);
  };

  const moveAllToAvailable = () => {
    setAvailableGravestones([...availableGravestones, ...selectedGravestones]);
    setSelectedGravestones([]);
  };

  const toggleActivitySelection = (selectedActivity) => {
    const updatedActivities = activities.map((activity) => {
      if (activity.value === selectedActivity.value) {
        return { ...activity, isSelected: !activity.isSelected };
      }
      return activity;
    });
    setActivities(updatedActivities);

    // Update selected activities list
    if (selectedActivity.isSelected) {
      setSelectedActivities(
        selectedActivities.filter(
          (activity) => activity.value !== selectedActivity.value
        )
      );
    } else {
      setSelectedActivities([...selectedActivities, selectedActivity]);
    }
  };

  const saveWorkOrder = () => {
    const workorderData = {
        name, // Assuming 'name' is already defined in your component's state
        workDate: startDate.toISOString().substring(0, 10), // Convert Date to YYYY-MM-DD format
        dueDate: endDate.toISOString().substring(0, 10), // Convert Date to YYYY-MM-DD format
        description: comment, // Assuming 'comment' is already defined in your component's state
        activities: selectedActivities.map(activity => activity.value), // Extract activity IDs
        gravestones: selectedGravestones.map(gravestone => gravestone.value), // Extract gravestone IDs
        users: selectedUsers.map(user => user.value) // Extract user IDs
    };

    fetch(`${apiURL}/api/workorders`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(workorderData),
    })
    .then(response => response.json())
    .then(data => {
        addNotification({message: 'Arbeidsordre lagret', type: 'success'});
        navigate('/arbeidslister');
        // Here you can handle redirection or UI updates upon successful save
    })
    .catch((error) => {
        console.error('Error saving workorder:', error);
        // Here you can handle error feedback
    });
};

  if (loading) return <LoadingScreen />;

  return (
    <div className="p-4 space-y-4">
      <div className="mb-4">
        <label
          htmlFor="name"
          className="block text-sm font-medium text-gray-700"
        >
          Navn
        </label>
        <input
          type="text"
          id="name"
          value={name} // Set value from state
          onChange={(e) => setName(e.target.value)} // Update state on change
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
        />
      </div>

      <div className="grid grid-cols-1 gap-4">
        <Select
          isMulti
          options={getSelectOptions()} // Use state for product options
          value={selectedProducts} // Control the selected values
          onChange={handleProductChange} // Handle changes
          className="w-full mb-4"
          placeholder="Filtrer etter produkter"
        />
        <Select
          isMulti
          options={getGraveyardOptions()}
          value={selectedGraveyards}
          onChange={handleGraveyardChange}
          className="w-full"
          placeholder="Filtrer etter gravplasser"
        />
        <ReusableSearch value={search} onChange={handleSearchChange} />
      </div>

      <div className="flex gap-4 items-center">
        <div className="flex-1 min-h-60 max-h-60 overflow-y-auto border border-gray-300 rounded">
          {availableGravestones.map((gravestone) => (
            <div
              key={gravestone.value}
              className="p-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => handleSelectGravestone(gravestone)}
            >
              {gravestone.label}
            </div>
          ))}
        </div>
        <div className="flex flex-col items-center justify-center">
          <AiOutlineArrowRight
            className="mb-2 cursor-pointer text-blue-500 hover:text-blue-700"
            onClick={moveAllToSelected}
          />
          <AiOutlineArrowLeft
            className="cursor-pointer text-blue-500 hover:text-blue-700"
            onClick={moveAllToAvailable}
          />
        </div>
        <div className="flex-1 min-h-60 max-h-60 overflow-y-auto border border-gray-300 rounded">
          {selectedGravestones.map((gravestone) => (
            <div
              key={gravestone.value}
              className="p-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => handleDeselectGravestone(gravestone)}
            >
              {gravestone.label}
            </div>
          ))}
        </div>
      </div>

      <div className="flex gap-4">
        <div className="w-1/2">
          <label
            htmlFor="startDate"
            className="block text-sm font-medium text-gray-700"
          >
            Startdato
          </label>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            locale={nb} // Set Norwegian locale here
            dateFormat="dd.MM.yyyy" // Example of Norwegian date format
          />
        </div>
        <div className="w-1/2">
          <label
            htmlFor="endDate"
            className="block text-sm font-medium text-gray-700"
          >
            Sluttdato
          </label>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            locale={nb} // Set Norwegian locale here
            dateFormat="dd.MM.yyyy" // Example of Norwegian date format
          />
        </div>
      </div>

      <Select
        isMulti
        options={getUserOptions()} // Use state for user options
        value={selectedUsers}
        onChange={setSelectedUsers}
        className="w-full"
        placeholder="Legg til brukere"
      />

      <div className="flex flex-wrap gap-2">
        {activities.map((activity) => (
          <span
            key={activity.value}
            className={`rounded-lg px-2 py-1 text-sm font-medium cursor-pointer ${
              activity.isSelected
                ? "bg-green-grave text-white"
                : "bg-gray-200 text-gray-700"
            }`}
            onClick={() => toggleActivitySelection(activity)}
          >
            {activity.label}
          </span>
        ))}
      </div>

      <div className="mb-4">
        <label
          htmlFor="comment"
          className="block text-sm font-medium text-gray-700"
        >
          Kommentar
        </label>
        <textarea
          id="comment"
          rows="3"
          value={comment} // Set value from state
          onChange={(e) => setComment(e.target.value)} // Update state on change
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
        ></textarea>
      </div>
      <button onClick={saveWorkOrder} className="px-4 py-2 bg-green-grave text-white rounded-md hover:bg-green-700">Lagre Arbeidsordre</button>
    </div>
  );
};

export default WorkOrderForm;
