import React, { useState, useEffect } from 'react';
import { FaEnvelope, FaSms, FaTrash, FaEye } from 'react-icons/fa';  // Import FaEye for the view icon
import Topbar from '../../components/Topbar';
import Toolbar from '../../components/Toolbar';
import ReusableTable from '../../components/ReusableTable';
import Pagination from '../../components/Pagination';
import Spinner from '../../components/Spinner';
import ReusableSearch from '../../components/ReusableSearch';
import ReusableLimit from '../../components/ReusableLimit';
import ReusableHeader from '../../components/ReusableHeader';
import ConfirmationModal from '../../components/ConfirmationModal';
import PayloadModal from '../../components/PayloadModal';  // Import the PayloadModal component
import useScheduler from '../../hooks/useScheduler';

function ScheduledJobs() {
  const { jobs, totalItems, totalPages, loading, error, fetchJobs, deleteJob } = useScheduler();
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('');
  const [type, setType] = useState('');
  const [sort, setSort] = useState('id');
  const [order, setOrder] = useState('ASC');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPayloadModalOpen, setIsPayloadModalOpen] = useState(false);  // State for payload modal
  const [jobToDelete, setJobToDelete] = useState(null);
  const [selectedPayload, setSelectedPayload] = useState(null);  // State for selected payload

  useEffect(() => {
    fetchJobs({ search, status, type, page, limit, sort, order });
  }, [search, status, type, page, limit, sort, order]);

  const handleSearchChange = (e) => setSearch(e.target.value);
  const handleStatusChange = (e) => setStatus(e.target.value);
  const handleTypeChange = (e) => setType(e.target.value);
  const handleLimitChange = (e) => setLimit(e.target.value);

  const handleSort = (field) => {
    if (sort === field) {
      setOrder(order === 'ASC' ? 'DESC' : 'ASC');
    } else {
      setSort(field);
      setOrder('ASC');
    }
  };

  const handleRowRightClick = (row) => {
    return [
      {
        label: 'Send på nytt',
        icon: row.job_name.startsWith('email') ? FaEnvelope : FaSms,
        onClick: () => alert(`Resend ${row.job_name}`),
      },
      {
        label: 'Vis Payload',
        icon: FaEye,  // Use FaEye as the icon
        onClick: () => openPayloadModal(row.payload),  // Open the payload modal with the payload
      },
      {
        label: 'Vis Besøk (WIP)',
        icon: FaEye,
        onClick: () => alert(`Vis besøk for ${JSON.parse(row.payload).related_id}`),
      },
      {
        label: 'Slett',
        icon: FaTrash,
        onClick: () => openModal(row.id),
      },
    ];
  };

  const openModal = (jobId) => {
    setJobToDelete(jobId);
    setIsModalOpen(true);
  };

  const openPayloadModal = (payload) => {
    setSelectedPayload(JSON.parse(payload));
    setIsPayloadModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setJobToDelete(null);
  };

  const closePayloadModal = () => {
    setIsPayloadModalOpen(false);
    setSelectedPayload(null);
  };

  const handleDelete = async () => {
    try {
      await deleteJob(jobToDelete);
      closeModal();
    } catch (error) {
      console.error('Failed to delete job:', error);
    }
  };

  const columns = [
    { label: 'ID', accessor: 'id', width: '100px' },
    { label: 'Jobbnavn', accessor: 'job_name', width: '200px' },
    { label: 'Planlagt tid', accessor: 'scheduled_at', type: 'date-time', width: '200px' },
    { label: 'Status', accessor: 'status', type: 'status', width: '150px' },
    { label: 'Cron-uttrykk', accessor: 'cron_expression', width: '200px' },
    { label: 'Til', accessor: 'payload', type: 'payload-to', width: '100px' },
    { label: 'Opprettet', accessor: 'created_at', type: 'date-time', width: '200px' },
    { label: 'Oppdatert', accessor: 'updated_at', type: 'date-time', width: '200px' },
  ];

  if (error) return <div className="text-red-500 text-center">Error: {error}</div>;

  return (
    <div>
      <Topbar>
        <ReusableHeader title="Planlagte jobber" />
        <Toolbar>
          <div className="flex items-center space-x-2">
            <select
              value={status}
              onChange={handleStatusChange}
              className="border rounded-lg px-4 py-2 text-gray-600 bg-white focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="">Alle status</option>
              <option value="pending">Ventende</option>
              <option value="completed">Fullført</option>
              <option value="failed">Mislyktes</option>
            </select>
            <select
              value={type}
              onChange={handleTypeChange}
              className="border rounded-lg px-4 py-2 text-gray-600 bg-white focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="">Alle typer</option>
              <option value="email">E-post</option>
              <option value="sms">SMS</option>
            </select>
          </div>
          <ReusableLimit value={limit} onChange={handleLimitChange} />
          <ReusableSearch value={search} onChange={handleSearchChange} />
        </Toolbar>
      </Topbar>
      {loading && <Spinner />}
      <ReusableTable
        columns={columns}
        data={jobs}
        sort={{ field: sort, order: order }}
        onSort={handleSort}
        onRowRightClick={handleRowRightClick}
      />
      <Pagination totalPages={totalPages} currentPage={page} setPage={setPage} />
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onConfirm={handleDelete}
        message="Er du sikker på at du vil slette denne jobben?"
      />
      <PayloadModal
        isOpen={isPayloadModalOpen}
        onClose={closePayloadModal}
        payload={selectedPayload}
      />
    </div>
  );
}

export default ScheduledJobs;