import React from 'react';
import { FaCalendarAlt, FaCheck, FaTrash } from 'react-icons/fa';

const SpecialDaysList = ({ specialDays, specialDayIcons, handleSetActiveSpecialDay, handleDeleteSpecialDay }) => {
  return (
    <div className="space-y-6">
      {specialDays.map((day) => {
        const Icon = specialDayIcons[day.description] || FaCalendarAlt;
        return (
          <div key={day.id} className="p-4 bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-200 border border-gray-200">
            <div className="flex justify-between items-center mb-4">
              <div className="flex items-center space-x-2">
                <Icon className="text-blue-500 text-2xl" />
                <h4 className="text-lg font-semibold text-gray-900">{new Date(day.date).toLocaleDateString('nb-NO')}</h4>
              </div>
              <div className="flex space-x-3">
                <button
                  onClick={() => handleSetActiveSpecialDay(day.id)}
                  className={`px-3 py-1 rounded-full flex items-center text-sm font-medium transition ${day.active ? 'bg-green-grave text-white' : 'bg-gray-300 text-gray-800 hover:bg-gray-400'}`}
                >
                  <FaCheck className="mr-1" /> {day.active ? 'Aktiv' : 'Sett Aktiv'}
                </button>
                <button
                  onClick={() => handleDeleteSpecialDay(day.id)}
                  className="px-3 py-1 rounded-full flex items-center text-sm font-medium text-red-500 hover:text-red-600 transition"
                >
                  <FaTrash className="mr-1" /> Slett
                </button>
              </div>
            </div>
            <p className="text-gray-600">{day.description}</p>
          </div>
        );
      })}
    </div>
  );
};

export default SpecialDaysList;