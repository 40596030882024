let googleMapsPromise;

export const loadGoogleMaps = (apiKey) => {
    if (!googleMapsPromise) {
        googleMapsPromise = new Promise((resolve, reject) => {
            if (window.google && window.google.maps) {
                resolve(window.google);
                return;
            }
            // Include the 'libraries' parameter in the URL query string
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places,visualization`; // Add other libraries as needed, e.g., 'visualization', 'drawing'
            script.async = true;
            script.defer = true;
            script.onload = () => {
                if (window.google && window.google.maps) {
                    resolve(window.google);
                } else {
                    reject(new Error("Google maps script loaded but window.google.maps is undefined"));
                }
            };
            script.onerror = (error) => {
                reject(new Error("Failed to load the Google Maps script: " + error.message));
            };
            document.head.appendChild(script);
        });
    }
    return googleMapsPromise;
};