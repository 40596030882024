import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useCustomer } from '../context/CustomerContext'; // Adjust import path as necessary
import { useAdminView } from '../context/AdminViewContext';

const ProtectedRouteCustomer = ({ element }) => {
  const { customer } = useCustomer();
  const { isAdminView } = useAdminView();
  const location = useLocation();

  if (!customer && !isAdminView) {
    // Redirect them to the customer login page, but save the current location they were trying to go to
    return <Navigate to="/kunde/logginn" state={{ from: location }} replace />;
  }

  return element;
};

export default ProtectedRouteCustomer;