// components/ReusableHeader.js
import React from 'react';
import { Link } from 'react-router-dom';

const ReusableHeader = ({ title, linkTo, linkText, alignment = 'left' }) => {
  const alignmentClasses = {
    left: 'text-left',
    center: 'text-center',
    right: 'text-right'
  };

  return (
    <h2 className={`text-2xl font-semibold ${alignmentClasses[alignment]}`}>
      {title}{' '}
      {linkTo && (
        <Link to={linkTo}>
          <span className="bg-gray-800 text-white px-2 hover:bg-green-grave cursor-pointer rounded-full">
            {linkText || '+'}
          </span>
        </Link>
      )}
    </h2>
  );
};

export default ReusableHeader;