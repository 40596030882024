// components/CustomerLogOut.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCustomer } from '../../context/CustomerContext';
import { FaSignOutAlt } from 'react-icons/fa';

const CustomerLogOut = () => {
  const { logout } = useCustomer();
  const navigate = useNavigate();

  useEffect(() => {
    logout();
    const timer = setTimeout(() => {
      navigate('/kunde/logginn');
    }, 3000); // 3 seconds

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, [logout, navigate]);

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-lg">
        <FaSignOutAlt className="text-red-500 text-5xl mb-4" />
        <h1 className="text-3xl font-bold mb-2 text-gray-800">Du har logget ut</h1>
        <p className="text-lg text-gray-600">Omdirigerer til innloggingssiden...</p>
      </div>
    </div>
  );
};

export default CustomerLogOut;