import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import CustomerComments from '../../components/customers/CustomerComments';
import GravestonesListCustomer from '../../components/customers/GravestonesListCustomer';
import LogViewer from '../../components/customers/LogViewer';
import ContactsManager from '../../components/customers/ContactsManager';
import LoadingScreen from '../../components/LoadingScreen';
import CustomerPasswordUpdate from '../../components/modals/CustomerPasswordUpdate';
import ViewAsCustomerButton from '../../components/admin-components/ViewAsCustomerButton';
import VisitComment from '../../components/VisitComment'; // Import VisitComment component
import { AiOutlineMail, AiFillPhone, AiFillHome } from 'react-icons/ai';

function CustomerDetails() {
  const { id } = useParams();
  const [customer, setCustomer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const apiURL = useMemo(() => process.env.REACT_APP_API_BASE_URL || '', []);

  const fetchCustomerDetails = useCallback(async () => {
    setIsLoading(true);
    setError('');

    try {
      const response = await fetch(`${apiURL}/api/customers/${id}`);
      if (!response.ok) throw new Error('Failed to load customer details.');
      const data = await response.json();

      setCustomer(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, [apiURL, id]);

  useEffect(() => {
    fetchCustomerDetails();
  }, [fetchCustomerDetails]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  if (isLoading) return <LoadingScreen />;
  if (error) return <div className="text-red-500 text-center">Error: {error}</div>;
  if (!customer) return <div className="text-center">No customer data found.</div>;

  return (
    <div className="max-w-7xl mx-auto mt-10 p-6 grid gap-6 grid-cols-1 lg:grid-cols-12">
      {/* Customer Info */}
      <div className="lg:col-span-4 bg-white shadow rounded-lg p-6 flex flex-col items-center md:items-start">
        <div className="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
          <h2 className="font-bold text-xl">{customer.first_name} {customer.last_name}</h2>
          <div className="flex items-center space-x-2">
            <p className="text-gray-600">#{customer.customer_number || 'No description available.'}</p>
            <ViewAsCustomerButton customerId={customer.id} />
          </div>
          <div className="mt-4 flex flex-col space-y-2">
            <div className="flex items-center">
              <AiOutlineMail className="inline h-6 w-6 text-gray-500" />
              <span className="ml-2">{customer.email}</span>
            </div>
            {customer.phone_number && (
              <div className="flex items-center">
                <AiFillPhone className="inline h-6 w-6 text-gray-500" />
                <span className="ml-2">{customer.phone_number}</span>
              </div>
            )}
            {customer.address && (
              <div className="flex items-center">
                <AiFillHome className="inline h-6 w-6 text-gray-500" />
                <span className="ml-2">{customer.address}</span>
              </div>
            )}
          </div>
          <button
            onClick={openModal}
            className="mt-4 bg-green-grave text-white px-4 py-2 rounded-full text-sm shadow-md hover:bg-green-600 transition"
          >
            Change Password
          </button>
        </div>
      </div>

      {/* Gravestones */}
      <div className="lg:col-span-8 bg-white shadow rounded-lg p-6">
        <h3 className="text-2xl font-semibold text-green-grave mb-4">Gravsteiner</h3>
        <GravestonesListCustomer fetchId={customer.id} queryParam="customer_id" showAddNewGravestone={true} />
      </div>

      {/* Comments and Contacts */}
      <div className="lg:col-span-6 bg-white shadow rounded-lg p-6">
        <h3 className="text-2xl font-semibold text-green-grave mb-4">Tilbakemeldinger</h3>
        {/* Replace or supplement CustomerComments with VisitComment */}
        <VisitComment customer_id={customer.id} showAllFromCustomer={true} showVisitLink={true} readOnly={true} />
      </div>
      <div className="lg:col-span-6 bg-white shadow rounded-lg p-6">
        <h3 className="text-2xl font-semibold text-green-grave mb-4">Kontakter</h3>
        <ContactsManager customerId={customer.id} />
      </div>

      <div className="lg:col-span-6 bg-white shadow rounded-lg p-6">
        <h3 className="text-2xl font-semibold text-green-grave mb-4">Interne Kommentarer</h3>
        <CustomerComments customerId={customer.id} />
      </div>

      {/* Logs */}
      <div className="lg:col-span-6 bg-white shadow rounded-lg p-6">
        <h3 className="text-2xl font-semibold text-green-grave mb-4">Logger</h3>
        <div className="max-h-96 overflow-y-auto mb-6">
          <h4 className="text-lg leading-6 font-medium text-green-800">SMS Logger</h4>
          {customer.phone_number && (
            <LogViewer type="sms" identifier={customer.phone_number} />
          )}
        </div>
        <div className="max-h-96 overflow-y-auto">
          <h4 className="text-lg leading-6 font-medium text-green-800">E-postlogger</h4>
          {customer.email && (
            <LogViewer type="email" identifier={customer.email} />
          )}
        </div>
      </div>

      {isModalOpen && (
        <CustomerPasswordUpdate customerId={customer.id} onClose={closeModal} />
      )}
    </div>
  );
}

export default CustomerDetails;