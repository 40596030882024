import React from 'react';

const GeneralSettings = () => {
  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Generelt</h2>
      <p>Det er ingen generelle Innstillinger enda. Sjekk de andre fanene over for innstillinger som eksisterer.</p>
      {/* Add your general settings form here */}
    </div>
  );
};

export default GeneralSettings;