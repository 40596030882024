import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import { FaMap } from "react-icons/fa";
import ReusableSearch from "../ReusableSearch";
import Spinner from "../Spinner";
import Pagination from "../Pagination"; // Assuming Pagination component is in the same directory

const GravestoneList = ({
  fetchId,
  queryParam,
  showAddNewGravestone = true,
  showSearch = false,
  showMapButton = false,
  customLink = "/gravsteiner/",
  showLimit = true,
}) => {
  const [gravestones, setGravestones] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [error, setError] = useState("");
  const [limit, setLimit] = useState(50);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const apiURL = process.env.REACT_APP_API_BASE_URL || "";
  const mainURL = process.env.REACT_APP_MAIN_URL || "";

  const newGravestonePath = `/gravsteiner/ny/${fetchId}`;

  const handleSearchChange = (e) => {
    setPage(1);
    setSearch(e.target.value);
  };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `${apiURL}/api/gravestones?${queryParam}=${fetchId}&limit=${limit}&search=${search}&page=${page}`
        );
        const data = await response.json();
        setIsLoading(false);
        if (data.gravestones) {
          setGravestones(data.gravestones);
          setTotalPages(data.totalPages);
        } else {
          setError("No gravestones found");
        }
      } catch (err) {
        setIsLoading(false);
        setError("Failed to fetch gravestones");
      }
    };

    fetchData();
  }, [fetchId, queryParam, search, limit, page]);

  if (error) return <div>{error}</div>;

  return (
    <div>
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-2xl font-semibold text-green-grave">Gravsteiner</h3>
        <div className="flex items-center space-x-4">
          {showSearch && (
            <ReusableSearch search={search} onChange={handleSearchChange} />
          )}
          {showMapButton && (
            <Link
              to={`/mobil/gravsteiner/kirkegard/${fetchId}/kart`}
              className="flex items-center justify-center px-4 py-2 text-sm bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
            >
              <FaMap className="mr-2" /> <span>Vis kart</span>
            </Link>
          )}
          {showLimit && (
            <select
              value={limit}
              onChange={handleLimitChange}
              className="px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-green-grave"
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          )}
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {isLoading && <Spinner />}
        {gravestones.map((gravestone) => (
  <Link
    to={customLink + gravestone.id}
    key={gravestone.id}
    className="block"
  >
    <div className="bg-white rounded-xl shadow-lg overflow-hidden transform hover:scale-105 transition duration-500">
      <div className="p-4">
        <div className="text-center mb-4 relative group">
          {gravestone.gravepersons_names ? (
            <div className="inline-block w-full text-lg font-semibold text-gray-800 bg-gray-100 py-1 px-2 rounded mb-1 shadow-sm relative">
              {gravestone.gravepersons_names.split(", ")[0]}
              {gravestone.gravepersons_names.split(", ").length > 1 && (
                <span className="text-sm text-gray-500 ml-2 cursor-pointer">...</span>
              )}
              {gravestone.gravepersons_names.split(", ").length > 1 && (
                <div className="absolute left-0 right-0 mx-auto w-full bg-gray-100 text-gray-800 rounded-lg shadow-lg p-2 hidden group-hover:block z-10">
                  {gravestone.gravepersons_names
                    .split(", ")
                    .slice(1)
                    .map((name, index) => (
                      <div key={index} className="border-b border-gray-300 last:border-none py-1">{name}</div>
                    ))}
                </div>
              )}
            </div>
          ) : (
            <div className="text-gray-700 bg-gray-100 py-1 px-2 rounded mb-1 shadow-sm">
              <span className="text-green-500">•</span> Ingen gravpersoner
            </div>
          )}
        </div>
        <div className="relative h-64 w-full">
          {gravestone.thumbnail ? (
            <img
              src={
                mainURL + gravestone.thumbnail ||
                "https://via.placeholder.com/150"
              }
              alt="Gravestone Thumbnail"
              className="absolute inset-0 w-full h-full object-cover"
            />
          ) : (
            <img
              src={
                mainURL + gravestone.picture ||
                "https://via.placeholder.com/150"
              }
              alt="Gravestone Thumbnail"
              className="absolute inset-0 w-full h-full object-cover"
            />
          )}
        </div>
        <p className="mt-4 text-sm text-gray-500 text-center">
          Siste besøk:{" "}
          {new Date(gravestone.latest_visit_date).toLocaleDateString("nb-NO")}
        </p>
      </div>
    </div>
  </Link>
))}
        {showAddNewGravestone && (
          <Link to={newGravestonePath} className="block">
            <div className="flex flex-col items-center justify-center bg-white rounded-xl shadow-lg transform hover:scale-105 transition duration-500 p-6 hover:bg-green-50">
              <AiOutlinePlus size="4em" className="text-green-500" />
              <span className="mt-3 text-xl font-semibold text-green-700 text-center">
                Legg til ny gravsten
              </span>
              <p className="text-sm text-gray-500 mt-2 text-center">
                Klikk her for å legge til en ny gravsten i systemet.
              </p>
            </div>
          </Link>
        )}
      </div>
      <Pagination
        totalPages={totalPages}
        currentPage={page}
        setPage={setPage}
      />
    </div>
  );
};

export default GravestoneList;