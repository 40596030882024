// src/components/PayloadModal.js
import React from 'react';

const PayloadModal = ({ isOpen, onClose, payload }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-96 max-w-full">
        <h2 className="text-xl font-bold mb-4">Vis Payload</h2>
        <pre className="text-gray-700 bg-gray-100 p-4 rounded overflow-auto max-h-96">
          {JSON.stringify(payload, null, 2)}
        </pre>
        <div className="flex justify-end mt-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
          >
            Lukk
          </button>
        </div>
      </div>
    </div>
  );
};

export default PayloadModal;