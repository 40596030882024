import React, { createContext, useContext, useState } from 'react';
import { useCustomer } from './CustomerContext';

const AdminViewContext = createContext();

export const useAdminView = () => {
  return useContext(AdminViewContext);
};

export const AdminViewProvider = ({ children }) => {
  const [isAdminView, setIsAdminView] = useState(false);
  const { logout } = useCustomer();

  const enterAdminView = () => setIsAdminView(true);

  const exitAdminView = () => {
    setIsAdminView(false);
    setTimeout(() => {
      logout();
    }, 500);
  };

  return (
    <AdminViewContext.Provider value={{ isAdminView, enterAdminView, exitAdminView }}>
      {children}
    </AdminViewContext.Provider>
  );
};