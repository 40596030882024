import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useNotifications } from '../../context/NotificationContext';
import CharacterCounter from '../../components/CharacterCounter'; // Import the CharacterCounter component

const CreateSMSTemplate = () => {
  const [name, setName] = useState('');
  const [content, setContent] = useState('');
  const [isDefault, setIsDefault] = useState('0');
  const navigate = useNavigate();
  const { addNotification } = useNotifications();
  const textareaRef = useRef(null);
  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      name,
      content,
      is_default: isDefault,
    };

    try {
      await axios.post(`${apiURL}/api/templates/sms`, data);
      addNotification({ message: 'Opprettet ny mal', type: 'success' });
      navigate('/maler');
    } catch (error) {
      console.error(error);
      addNotification({ message: error.message || 'An error occurred', type: 'error' });
    }
  };

  const insertCode = (code) => {
    const textarea = textareaRef.current;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const text = textarea.value;
    
    // Insert code at the cursor position
    const newText = text.substring(0, start) + code + text.substring(end);
    setContent(newText);

    // Move the cursor to the end of the inserted code
    setTimeout(() => {
      textarea.setSelectionRange(start + code.length, start + code.length);
      textarea.focus();
    }, 0);
  };

  return (
    <div className="flex max-w-5xl mx-auto p-8 bg-white rounded-lg shadow-lg">
      <div className="w-4/6 pr-8">
        <h1 className="text-3xl font-bold text-gray-800 mb-6">Ny SMS Mal</h1>
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <label htmlFor="name" className="block text-lg font-medium text-gray-700">Navn:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="mt-2 block w-full border-gray-300 rounded-md shadow-sm focus:border-green-grave focus:ring-green-grave transition duration-150 ease-in-out"
              placeholder="Navn på mal.."
            />
          </div>
          <div>
            <label htmlFor="content" className="block text-lg font-medium text-gray-700">Innhold:</label>
            <textarea
              id="content"
              name="content"
              ref={textareaRef}
              value={content}
              onChange={(e) => setContent(e.target.value)}
              className="mt-2 block w-full border-gray-300 rounded-md shadow-sm focus:border-green-grave focus:ring-green-grave transition duration-150 ease-in-out"
              placeholder="Malinnhold.."
              rows="10"
            ></textarea>
            <CharacterCounter text={content} /> {/* Integrate the CharacterCounter here */}
          </div>
          <div>
            <label htmlFor="isDefault" className="block text-lg font-medium text-gray-700">Standard?</label>
            <select
              id="isDefault"
              name="isDefault"
              value={isDefault}
              onChange={(e) => setIsDefault(e.target.value)}
              className="mt-2 block w-full border-gray-300 rounded-md shadow-sm focus:border-green-grave focus:ring-green-grave transition duration-150 ease-in-out"
            >
              <option value="0">Nei</option>
              <option value="1">Ja</option>
            </select>
          </div>
          <button
            type="submit"
            className="w-full bg-green-grave text-white py-3 px-4 rounded-lg shadow-lg hover:bg-orange-grave focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-150 ease-in-out"
          >
            Lag Mal
          </button>
        </form>
      </div>
      <div className="w-2/6 pl-8 bg-gray-50 p-4 rounded-lg shadow-inner">
        <h2 className="text-xl font-bold text-gray-800 mb-4">Tilgjengelige koder</h2>
        <ul className="space-y-4">
          <li>
            <label className="block text-sm font-medium text-gray-700 mb-1">Kunde ID:</label>
            <code
              className="bg-gray-200 px-2 py-1 rounded cursor-pointer"
              onClick={() => insertCode('{{customer_id}}')}
            >
              {'{{customer_id}}'}
            </code>
          </li>
          <li>
            <label className="block text-sm font-medium text-gray-700 mb-1">Kundens fornavn:</label>
            <code
              className="bg-gray-200 px-2 py-1 rounded cursor-pointer"
              onClick={() => insertCode('{{customer_firstname}}')}
            >
              {'{{customer_firstname}}'}
            </code>
          </li>
          <li>
            <label className="block text-sm font-medium text-gray-700 mb-1">Kundens etternavn:</label>
            <code
              className="bg-gray-200 px-2 py-1 rounded cursor-pointer"
              onClick={() => insertCode('{{customer_lastname}}')}
            >
              {'{{customer_lastname}}'}
            </code>
          </li>
          <li>
            <label className="block text-sm font-medium text-gray-700 mb-1">Kundens e-post:</label>
            <code
              className="bg-gray-200 px-2 py-1 rounded cursor-pointer"
              onClick={() => insertCode('{{customer_email}}')}
            >
              {'{{customer_email}}'}
            </code>
          </li>
          <li>
            <label className="block text-sm font-medium text-gray-700 mb-1">Kundens telefon:</label>
            <code
              className="bg-gray-200 px-2 py-1 rounded cursor-pointer"
              onClick={() => insertCode('{{customer_phone}}')}
            >
              {'{{customer_phone}}'}
            </code>
          </li>
          <li>
            <label className="block text-sm font-medium text-gray-700 mb-1">Besøks-URL:</label>
            <code
              className="bg-gray-200 px-2 py-1 rounded cursor-pointer"
              onClick={() => insertCode('{{visit_url}}')}
            >
              {'{{visit_url}}'}
            </code>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CreateSMSTemplate;