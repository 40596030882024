import axiosInstance from "../utils/axiosInstance";

const markAllAsSeenByUser = async () => {
    try {
        const response = await axiosInstance.patch(`/api/visit_comments/mark_all_seen_by_user`);
        alert(response.data.message);
    } catch (error) {
        console.error('Error marking all comments as seen by user:', error);
    }
};

// Example button usage in a React component
const MarkAllSeenButton = () => (
    <button
        onClick={markAllAsSeenByUser}
        className="px-4 py-2 text-sm font-bold bg-green-grave text-white rounded-full shadow hover:bg-green-800 transition duration-300"
    >
        Marker Alle Som Sett
    </button>
);

export default MarkAllSeenButton;