import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import LoadingScreen from '../../components/LoadingScreen';
import GravestoneList from '../../components/ReusableGravestoneDisplay';
import ImageModal from '../../components/ImageModal';
import { FaMapMarkedAlt, FaInfoCircle, FaPhone, FaEnvelope, FaGlobe, FaRoute, FaMap, FaMonument } from 'react-icons/fa';
import { useGraveyards } from '../../hooks';

function useWindowSize() {
  const [size, setSize] = useState([window.innerWidth]);
  useEffect(() => {
      const handleResize = () => {
          setSize([window.innerWidth]);
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);
  return size;
}

const GraveyardProfile = () => {
  const [width] = useWindowSize();  // Get the current window width
  const isMobile = width < 768;  // Define mobile view threshold

  const { id } = useParams();
  const { fetchGraveyardById, graveyard, isLoading } = useGraveyards();
  const [error, setError] = useState('');

  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageSrc, setSelectedImageSrc] = useState('');

  const baseURL = 'https://cms.gravstellerne.no';

  useEffect(() => {
    fetchGraveyardById(id);
  }, [id]);

  const openModal = (imageSrc) => {
    setSelectedImageSrc(imageSrc);
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Example function that could be triggered when clicking an image
  const handleImageClick = (imageSrc) => {
    openModal(imageSrc);
  };

  if (isLoading) return <LoadingScreen />; // Centered loading message
  if (error) return <div className="text-red-600 text-center">{error}</div>; // Styled error message
  if (!graveyard) return <div className="text-center">Kirkegård ikke funnet</div>; // Centered fallback message

  return (
    <div className="p-2 mt-2">
        <div className="bg-white shadow rounded-lg overflow-hidden">
            {isMobile ? (
                <>
                    {/* Mobile layout */}
                    <div className="flex flex-col">
                        {/* Image Section */}
                        <div className="w-full">
                            <img
                                src={graveyard.picture ? `${baseURL}${graveyard.picture}` : "https://via.placeholder.com/400"}
                                alt={`${graveyard.name}`}
                                className="w-full h-64 object-cover rounded-t-lg"
                                onClick={() => handleImageClick(`${baseURL}${graveyard.picture}`)}
                            />
                        </div>

                        {/* Button Bar with Icons */}
                        <div className="grid grid-cols-3 gap-2 p-4 bg-gray-50">
                            <a
                                href={`https://www.google.com/maps?q=${graveyard.latitude},${graveyard.longitude}`}
                                target="_blank"
                                rel="noreferrer"
                                className="flex flex-col items-center justify-center bg-green-500 text-white rounded-lg p-2 shadow-md hover:bg-green-600 text-sm"
                            >
                                <FaRoute className="mb-1" />
                                <span>Veibeskrivelse</span>
                            </a>
                            <Link
                                to={`/mobil/gravsteiner/kirkegard/${graveyard.id}/kart`}
                                className="flex flex-col items-center justify-center bg-blue-500 text-white rounded-lg p-2 shadow-md hover:bg-blue-600 text-sm"
                            >
                                <FaMap className="mb-1" />
                                <span>Kart</span>
                            </Link>
                            <Link
                                to={`/gravsteiner?kirkegard=${graveyard.id}`}
                                className="flex flex-col items-center justify-center bg-purple-500 text-white rounded-lg p-2 shadow-md hover:bg-purple-600 text-sm"
                            >
                                <FaMonument className="mb-1" />
                                <span>Gravsteiner</span>
                                <span>({graveyard.gravestone_count})</span>
                            </Link>
                        </div>

                        {/* Graveyard Details */}
                        <div className="p-4">
                            <h2 className="text-2xl font-bold text-gray-800 mb-3">{graveyard.name}</h2>
                            <div className="space-y-2 text-gray-700">
                                <p className="flex items-center">
                                    <FaMapMarkedAlt className="mr-2 text-gray-500" />
                                    {graveyard.location || "Ukjent"}
                                </p>
                                <p className="flex items-center">
                                    <FaInfoCircle className="mr-2 text-gray-500" />
                                    {graveyard.gravestone_count} graver
                                </p>
                                <p className="flex items-center">
                                    <FaPhone className="mr-2 text-gray-500" />
                                    {graveyard.contact_phone || "Ingen telefonnummer"}
                                </p>
                                <p className="flex items-center">
                                    <FaEnvelope className="mr-2 text-gray-500" />
                                    {graveyard.contact_email || "Ingen e-postadresse"}
                                </p>
                                {graveyard.eclesia_url && (
                                    <a
                                        href={graveyard.eclesia_url}
                                        className="flex items-center underline text-green-700"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <FaGlobe className="mr-2 text-gray-500" />
                                        {graveyard.eclesia_url}
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    {/* Desktop layout */}
                    <div className="max-w-7xl mx-auto mt-10">
                        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                            <div className="lg:flex">
                                <div className="lg:flex-1">
                                    <div className="bg-green-grave p-5">
                                        <h2 className="text-3xl text-white font-bold mb-2">{graveyard.name}</h2>
                                        <p className="text-white">Antall gravsteder: <strong>{graveyard.gravestone_count}</strong></p>
                                    </div>
                                    <div className="p-6">
                                        <p className="mb-4"><strong>Beskrivelse:</strong> {graveyard.description || 'Ingen beskrivelse tilgjengelig.'}</p>
                                        <p className="mb-4"><strong>Sted:</strong> {graveyard.location || 'Ukjent'}</p>
                                        <p className="mb-4"><strong>Kontakt:</strong> {graveyard.contact || 'Ingen kontaktperson'}</p>
                                        <p className="mb-4"><strong>Telefon:</strong> {graveyard.contact_phone || 'Ingen telefonnummer'}</p>
                                        <p><strong>E-post:</strong> {graveyard.contact_email || 'Ingen e-postadresse'}</p>
                                    </div>
                                </div>
                                <div className="lg:w-1/2">
                                    <img src={graveyard.picture ? `${apiURL}${graveyard.picture}` : 'https://via.placeholder.com/400'} alt="Graveyard" className="w-full object-cover rounded-bl-lg lg:rounded-bl-none lg:rounded-r-lg" />
                                </div>
                            </div>
                        </div>

                        {/* Gravestone Cards Section */}
                        <div className="mt-10 mb-10">
                            <GravestoneList fetchId={id} queryParam="graveyard_id" showAddNewGravestone={false} showSearch={true} showMapButton={true} />
                        </div>
                    </div>
                </>
            )}
        </div>
        <ImageModal
            isOpen={isModalOpen}
            onClose={closeModal}
            imageSrc={graveyard.picture ? `${baseURL}${graveyard.picture}` : 'https://via.placeholder.com/400'}
        />
    </div>
);
};

export default GraveyardProfile;