import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useNotifications } from '../../context/NotificationContext';
import axios from 'axios';
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from 'react-icons/ai';

const GravepersonForm = ({ formData, setFormData, index, onRemove }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedForm = [...formData];
    updatedForm[index] = { ...updatedForm[index], [name]: value };
    setFormData(updatedForm);
  };

  const formatDateForInput = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  return (
    <div className="mb-8 p-4 border border-gray-200 rounded">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <div className="mb-4">
          <label htmlFor={`first_name-${index}`} className="block mb-2 text-sm font-medium text-gray-900">Fornavn</label>
          <input type="text" id={`first_name-${index}`} name="first_name" value={formData[index].first_name || ''} onChange={handleChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
        </div>
        <div className="mb-4">
          <label htmlFor={`last_name-${index}`} className="block mb-2 text-sm font-medium text-gray-900">Etternavn</label>
          <input type="text" id={`last_name-${index}`} name="last_name" value={formData[index].last_name || ''} onChange={handleChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
        </div>
        <div className="mb-4">
          <label htmlFor={`birthdate-${index}`} className="block mb-2 text-sm font-medium text-gray-900">Fødselsdato</label>
          <input 
            type="date" 
            id={`birthdate-${index}`} 
            name="birthdate" 
            value={formatDateForInput(formData[index].birthdate)} 
            onChange={handleChange} 
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
          />
        </div>
        <div className="mb-4">
          <label htmlFor={`deathdate-${index}`} className="block mb-2 text-sm font-medium text-gray-900">Dødsdato</label>
          <input 
            type="date" 
            id={`deathdate-${index}`} 
            name="deathdate" 
            value={formatDateForInput(formData[index].deathdate)} 
            onChange={handleChange} 
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
          />
        </div>
      </div>
      <div className="mb-4">
        <label htmlFor={`description-${index}`} className="block mb-2 text-sm font-medium text-gray-900">Beskrivelse</label>
        <textarea id={`description-${index}`} name="description" value={formData[index].description || ''} onChange={handleChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"></textarea>
      </div>
      <div className="flex justify-end mt-4">
        <button type="button" onClick={() => onRemove(index)} className="flex items-center justify-center text-red-500 hover:text-red-600">
          <AiOutlineMinusCircle className="mr-2" /> Fjern
        </button>
      </div>
    </div>
  );
};

const AddGraveperson = () => {
  const { gravestoneId } = useParams();
  const navigate = useNavigate();
  const { addNotification } = useNotifications();
  const apiURL = process.env.REACT_APP_API_BASE_URL || '';
  const [formData, setFormData] = useState([{
    first_name: '',
    last_name: '',
    description: '',
    birthdate: '',
    deathdate: '',
  }]);
  const [gravepersonsToRemove, setGravepersonsToRemove] = useState([]); // Track gravepersons to remove

  useEffect(() => {
    const fetchGravepersons = async () => {
      try {
        const response = await axios.get(`${apiURL}/api/gravepersons/gravestone/${gravestoneId}`);
        setFormData(response.data.length ? response.data : [{}]);
      } catch (error) {
        console.error('Error fetching gravepersons:', error);
        addNotification({ message: 'Noe gikk galt med å hente data', type: 'error' });
      }
    };

    if (gravestoneId) fetchGravepersons();
  }, [gravestoneId]);

  const formatDateForDB = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];  // Returns YYYY-MM-DD
  };

  const handleAddForm = () => {
    setFormData([...formData, {
      first_name: '',
      last_name: '',
      description: '',
      birthdate: '',
      deathdate: '',
    }]);
  };

  const handleRemoveForm = (index) => {
    const updatedForm = [...formData];
    const removedItem = updatedForm.splice(index, 1)[0]; // Remove and keep track of the removed item
    setFormData(updatedForm);

    if (removedItem.id) {
      setGravepersonsToRemove([...gravepersonsToRemove, removedItem.id]); // Add to the list of IDs to be removed
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Remove gravepersons
      for (let id of gravepersonsToRemove) {
        await axios.delete(`${apiURL}/api/gravepersons/${id}`);
      }

      // Upsert gravepersons
      for (let data of formData) {
        const formattedData = {
          ...data,
          birthdate: formatDateForDB(data.birthdate),
          deathdate: formatDateForDB(data.deathdate),
        };
        await axios.post(`${apiURL}/api/gravepersons`, { ...formattedData, gravestone_id: gravestoneId });
      }

      addNotification({ message: 'Gravpersoner oppdatert', type: 'success' });
      navigate(`/gravsteiner/${gravestoneId}`);
    } catch (error) {
      console.error('Error adding graveperson:', error);
      addNotification({ message: 'Noe gikk galt', type: 'error' });
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-xl font-bold mb-4">Administrer Gravpersoner</h1>
      {formData.map((_, index) => (
        <GravepersonForm key={index} index={index} formData={formData} setFormData={setFormData} onRemove={handleRemoveForm} />
      ))}
      <div className="flex justify-center items-center my-4">
        <hr className="border-1 border-green-300 flex-grow mr-2" />
        <AiOutlinePlusCircle className="text-4xl text-green-500 cursor-pointer hover:text-green-600 hover:scale-110 transition-transform duration-200" onClick={handleAddForm} />
        <hr className="border-1 border-green-300 flex-grow ml-2" />
      </div>
      <div className="text-right">
        <button onClick={handleSubmit} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2">Lagre alle</button>
        <button onClick={() => setFormData([{}])} className="text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Tøm skjema</button>
      </div>
    </div>
  );
};

export default AddGraveperson;