import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReusableInput from '../../components/ReusableInput';
import { useNotifications } from '../../context/NotificationContext';
import { AiOutlineMail, AiOutlineUser, AiOutlinePhone, AiOutlineHome, AiOutlineIdcard, AiOutlineCalendar, AiOutlineSwitcher } from 'react-icons/ai';
import Switch from "react-switch";

function AddCustomer() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    customer_number: '',
    description: '',
    org_number: '',
    first_name: '',
    last_name: '',
    phone_number: '',
    address: '',
    department_id: '',
    is_company: false,
    company_name: '',
  });

  const { addNotification } = useNotifications();
  const navigate = useNavigate();

  const apiURL = process.env.REACT_APP_API_BASE_URL || '';

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleToggle = (checked) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      is_company: checked,
      // Reset fields based on the toggle
      first_name: '',
      last_name: '',
      company_name: '',
      org_number: '',
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare formData for submission
    let submitData = { ...formData };
    
    // If is_company is true, use company_name as first_name
    if (submitData.is_company) {
        submitData.first_name = submitData.company_name;
    }
  
    try {
      const response = await fetch(`${apiURL}/api/customers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Include other headers as needed
        },
        body: JSON.stringify(submitData), // Use the adjusted submitData
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const newCustomer = await response.json();
      console.log('New customer added:', newCustomer);
      addNotification({ message: 'Kunde lagt til', type: 'success' });
      navigate(`/kunder/${newCustomer.id}`);
      
      // Reset the form or handle success as needed
      setFormData({
        email: '',
        password: '',
        customer_number: '',
        description: '',
        org_number: '',
        first_name: '',
        last_name: '',
        phone_number: '',
        address: '',
        department_id: '',
        is_company: false,
        company_name: '',
        // Reset other fields as necessary
      });
      // Redirect or show a success message
    } catch (error) {
      console.error('Failed to submit the form:', error.message);
    }
};

  return (
    <div className="max-w-4xl mx-auto py-8">
      <h2 className="text-2xl font-semibold mb-6">Ny Kunde</h2>
      <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="col-span-2 flex items-center justify-between">
          <label htmlFor="is_company" className="flex items-center cursor-pointer">
            <span className="mr-2">Firmakunde?</span>
            <Switch 
              checked={formData.is_company} 
              onChange={handleToggle} 
              onColor="#86d3ff"
              onHandleColor="#2693e6"
              handleDiameter={30}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={20}
              width={48}
              className="react-switch"
              id="is_company"
            />
          </label>
        </div>

        {!formData.is_company ? (
          <>
            {/* Individual customer fields */}
            <ReusableInput
              Icon={AiOutlineUser}
              type="text"
              id="first_name"
              name="first_name"
              placeholder="Fornavn"
              value={formData.first_name}
              onChange={handleChange}
            />
            <ReusableInput
              Icon={AiOutlineUser}
              type="text"
              id="last_name"
              name="last_name"
              placeholder="Etternavn"
              value={formData.last_name}
              onChange={handleChange}
            />
          </>
        ) : (
          <>
            {/* Company fields */}
            <ReusableInput
              Icon={AiOutlineSwitcher}
              type="text"
              id="company_name"
              name="company_name"
              placeholder="Firmanavn"
              value={formData.company_name}
              onChange={handleChange}
            />
            <ReusableInput
              Icon={AiOutlineIdcard}
              type="text"
              id="org_number"
              name="org_number"
              placeholder="Org Nummer"
              value={formData.org_number}
              onChange={handleChange}
            />
          </>
        )}

        {/* Common fields */}
        <ReusableInput
          Icon={AiOutlineMail}
          type="email"
          id="email"
          name="email"
          placeholder="E-post"
          value={formData.email}
          onChange={handleChange}
        />
        <ReusableInput
          Icon={AiOutlinePhone}
          type="text"
          id="phone_number"
          name="phone_number"
          placeholder="Telefon"
          value={formData.phone_number}
          onChange={handleChange}
        />
        <ReusableInput
          Icon={AiOutlineHome}
          type="text"
          id="address"
          name="address"
          placeholder="Adresse"
          value={formData.address}
          onChange={handleChange}
        />
        <ReusableInput
          Icon={AiOutlineCalendar}
          type="text"
          id="customer_number"
          name="customer_number"
          placeholder="Kundenummer"
          value={formData.customer_number}
          onChange={handleChange}
          />
        <ReusableInput
          Icon={AiOutlineCalendar}
          type="text"
          id="department_id"
          name="department_id"
          placeholder="Avdeling"
          value={formData.department_id}
          onChange={handleChange}
          />
        {/* Repeat for other common fields */}
        {/* Submit button */}
        <div className="mt-4 col-span-2">
          <button type="submit" className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
            Legg til kunde
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddCustomer;