import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import ReusableSearch from "./ReusableSearch";
import Spinner from "./Spinner";
import { FaMap } from "react-icons/fa";

const GravestoneList = ({
  fetchId,
  queryParam,
  showAddNewGravestone = true,
  showSearch = false,
  showMapButton = false,
  customLink = "/gravsteiner/",
}) => {
  const [gravestones, setGravestones] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState(""); // Add a new state for search [1/2]
  const [error, setError] = useState("");

  const apiURL = process.env.REACT_APP_API_BASE_URL || "";
  const mainURL = process.env.REACT_APP_MAIN_URL || "";

  // Adjust the path for adding a new gravestone to be more universal
  const newGravestonePath = `/gravsteiner/ny/${fetchId}`;

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
};

  useEffect(() => {
    setIsLoading(true);
    fetch(
      `${apiURL}/api/gravestones?${queryParam}=${fetchId}&limit=1000&search=${search}`
    )
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        if (data.gravestones) {
          setGravestones(data.gravestones);
        } else {
          setError("No gravestones found");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError("Failed to fetch gravestones");
      });
  }, [fetchId, queryParam, search]);

  if (error) return <div>{error}</div>;

  return (
    <div>
      <div className="flex items-center justify-between mb-6">
    <h3 className="text-2xl font-semibold text-green-grave">
        Gravsteiner
    </h3>
    <div className="flex items-center space-x-4">
        {showSearch && (
            <ReusableSearch
                search={search}
                onChange={handleSearchChange}
            />
        )}
        {showMapButton && (
            <Link
                to={`/mobil/gravsteiner/kirkegard/${fetchId}/kart`}
                className="flex items-center justify-center px-4 py-2 text-sm bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
            >
                <FaMap className="mr-2" /> <span>Vis kart</span>
            </Link>
        )}
    </div>
</div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {isLoading && <Spinner />}
        {gravestones.map((gravestone) => (
          <Link
            to={customLink + gravestone.id}
            key={gravestone.id}
            className="block"
          >
            <div className="bg-white rounded-xl shadow-lg overflow-hidden transform hover:scale-105 transition duration-500">
              {/* Thumbnail */}
              <div className="relative h-48 w-full">
                {gravestone.thumbnail ? (
                  <img
                  src={
                    mainURL + gravestone.thumbnail ||
                    "https://via.placeholder.com/150"
                  }
                  alt="Gravestone Thumbnail"
                  className="absolute inset-0 w-full h-full object-cover"
                />
                ) : (
                  <img
                  src={
                    mainURL + gravestone.picture ||
                    "https://via.placeholder.com/150"
                  }
                  alt="Gravestone Thumbnail"
                  className="absolute inset-0 w-full h-full object-cover"
                />)}
              </div>

              {/* Gravestone details */}
              <div className="p-4">
                <div>
                  {gravestone.gravepersons_names ? (
                    gravestone.gravepersons_names
                      .split(", ")
                      .map((name, index) => (
                        <div
                          key={index}
                          className="text-gray-700 flex items-center"
                        >
                          <span className="text-green-500 mr-2">•</span> {name}
                        </div>
                      ))
                  ) : (
                    <div className="text-gray-700 flex items-center">
                      <span className="text-green-500 mr-2">•</span> Ingen
                      gravpersoner
                    </div>
                  )}
                </div>
                <p className="mt-4 text-sm text-gray-500 text-center">
                  Siste besøk:{" "}
                  {new Date(gravestone.latest_visit_date).toLocaleDateString(
                    "nb-NO"
                  )}
                </p>
              </div>
            </div>
          </Link>
        ))}
        {showAddNewGravestone && (
          <Link to={newGravestonePath} className="block">
            <div className="flex flex-col items-center justify-center bg-white rounded-xl shadow-lg transform hover:scale-105 transition duration-500 p-6 hover:bg-green-50">
              <AiOutlinePlus size="4em" className="text-green-500" />
              <span className="mt-3 text-xl font-semibold text-green-700 text-center">
                Legg til ny gravsten
              </span>
              <p className="text-sm text-gray-500 mt-2 text-center">
                Klikk her for å legge til en ny gravsten i systemet.
              </p>
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};

export default GravestoneList;